$path-font: "../fonts";

$color-black: #000;
$color-white: #fff;
$color-grey:  #999;

$color-primary: #ffd100;
$color-secondary: #999999;
$color-secondary-light: #e0e0e0;

$color-bg: #000000;
$color-hover: #797777;
$color-red: #ff5445;

$color-base: #000;
$color-link: $color-primary;
$color-link-hover: $color-primary;

$color-border: #e0e0e0;
$color-inactive: #f0f0f0;

$font-size: 16px;

$font-base: Arial, Helvetica, sans-serif;

$transition-function:			ease;
$transition-duration: 			250ms;

$btn-bg:						$color-white;
$btn-border: 				$color-black;
$btn-color: 				$color-black;

$btn-bg-hover: 			darken($btn-bg,15%);
$btn-border-hover: 	darken($btn-border,10%);

$btn-height: 				40px;
$btn-border-width: 	1px;

$input-light: #f2f2f2;
$input-dark: #c7c7c7;
$input-darken: #7a7a7a;


$color-primary: #043a63;
$color-pink: #b85e82;
$color-green: #87d07b;
$color-blue-light: #87d0c5;
$color-yellow: #ffd100;
$color-black: #000;
$color-white: #fff;
$color-blue-bg: #7f9bb0;
$color-blue-light-bg: #e8edf1;
$color-red: #e20b33;


//arrays
$share-icon-names: (
		google: "\e915",
		tumblr: "\e916",
		facebook: "\e914",
		mail: "\e911",
		twitter: "\e912",
		linkedin: "\e910",
		vine: "\e913",
		youtube: "\e906",
		reddit: "\e919"
);

$card-theme: (
		yellow: $color-yellow,
		green: $color-green,
		blue: $color-blue-light,
		pink: $color-pink,
		Trusses: #d6426f,
		Ballast-systems: #bf9632,
		LED-Screen-support: #5e88c2,
		Roofs: #90b947,
		Crowd-Barriers: #d64546,
		Circles: #51599e,
		Clamps: #b93b6b,
		Towers: #b63264,
		Cablecross: #8d65a1,
		Rigging-Accessories: #de7130,
		LitecCad: #73beca,
		Ramps: #eeca44,
		Xstage: #dfb3d0
);

$password-strength: (
		weak: #B22222,
		acceptable: #F4A460,
		average: #4169E1,
		strong: #3CB371,
		excellent: #228B22
);
