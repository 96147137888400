
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.main-slider{
    width:100%;
    position:relative;
    &-item{
        @include flex-block;
        @include justify-content(center);
        @include align-items(center);
        width:100%;
        background-position:top center !important;
        background-repeat:no-repeat !important;
        @include background-size(cover);
        &-title{
						@include font-size(35px);
						line-height: 40px;
            font-weight:300;
            text-align:center;
            color: $color-primary;
            @include media(sm){
								@include font-size(40px);
	            	line-height: 45px;
            }
            @include media(md){
								@include font-size(55px);
	            	line-height: 60px;
            }
            @include media(lg){
								@include font-size(65px);
	            	line-height: 70px;
            }
            @include media(xl){
								@include font-size(95px);
	            	line-height: 97px;
            }
        }
        &-inner{
            @include flex-block;
            @include justify-content(center);
            @include align-items(center);
            height:300px;
            @include media(sm){
                height:375px;
            }
            @include media(md){
                height:450px;
            }
            @include media(lg){
                height:535px;
            }
        }
    }
    .slick-dots{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        text-align: center;
        padding:0;
        margin:0;
        li{
            display: inline-block;
            vertical-align: middle;
            margin: 0 4px;
            width: 8px;
            height: 8px;
            cursor: pointer;
            background: $color-white;
            @include border-radius(25px);
            @include animate();
						border: 1px solid $color-primary;
						&:focus,
            &:hover{
                background: $color-primary;
            }
            button{
                display: none;
            }
            &.slick-active{
                background-color: $color-primary;
            }
        }
    }
}

.hp-category-links {
		display: none;
		border: 1px solid $color-primary;
		width: 100%;
		@include media(lg) {
				display: block;
		}
		* {
				margin: 0;
				padding: 0;
		}
    &.version-2 {
        //border-bottom: 0;
        //border-right: 0;
        //border-left: 0;
    }
		&-item {
				@include flex-block;
				@include align-items(center);
				@include justify-content(center);
				height: 100%;
				width: 100%;
				background-color: $color-white;
				color: $color-primary;
				@include animate("background, color");
				text-decoration: none;
				text-align: center;
				padding: 5px;
				&:hover,
				&:focus {
						color: $color-white;
						background-color: $color-primary;
						text-decoration: none !important;
				}
				&:before {
						content: "";
						display: block;
						padding-top: 75%;
						@include media(md) {
								padding-top: 100%;
						}
				}
				&-wrap {
						&:not(:nth-child(11)) {
								border-right: 1px solid $color-primary;
						}
            /*&:first-child{
              border-left: 1px solid $color-primary;
            }*/
				}
		}

}

.hp-news-card {
    display: flex;
    flex: 0 1 100%;
    max-width: 100%;
    text-decoration: none;
    height: 100%;
    width: 100%;
    &-image,
    &-content {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    &-image {
        position: relative;
        overflow: hidden;
        img {
            @include cover-image();
            @include transition;
            height: auto;
            width: 100%;
        }
    }
    &-content {
        order: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 20px 10px 10px;
        @include media(md) {
            padding: 12px 20px 12px 20px;
        }
        @include media(xl) {
            padding: 15px 20px 15px 30px;
        }
        h3 {
            font-size: 16px;
            padding-right: 0;
            margin-bottom: 5px;
            margin: 0px !important;
            font-weight: 300;
            @include media(xs) {
              font-size: 16px;
              line-height: 25px;
              padding-right: 30px;
            }
            @include media(md) {
              font-size:18px;
              padding-right: 25px;
              @include eliminate-lines(24,2);
            }
            @include media(lg) {
              @include font-size(26px);
              line-height: 31px;
              max-height: 60px;
              height: auto;
              margin-bottom: 5px;
              @include eliminate-lines(31,2);
            }
        }
    }
    &:first-of-type {
      min-height: 125px;
      @include media(md) {
          min-height: 130px;
      }
      @include media(xl) {
          min-height: 140px;
      }
      .hp-news-card-arrow {
          @include media(xl) {
              width: 29px;
              height: 29px;
          }
          &:before {
              @include media(xl) {
                  @include font-size(36px);
              }
          }
      }
    }
    &:last-of-type {
      min-height: 100px;
      @include media(md) {
          min-height: 110px;
      }
      @include media(xl) {
          min-height: 120px;
      }
      .hp-news-card-content {
          @include media(xl) {
              padding-left: 20px !important;
          }
          h3 {
              @include media(xl) {
                  @include font-size(22px);
              }
          }
      }
    }
    &:hover,
    &:focus {
        .hp-news-card-arrow {
            border-color: $color-primary;
        }
        .hp-news-card-image img {
            transform: scale(1.08);
        }
    }
}

.hp-news-box {
    width: 100%;
    height: 100%;
    .slick-list,
    .slick-track {
        height: 100%;
    }
    .hp-news-card-arrow {
        display: none !important;
    }
}

.hp-tv-card {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #171717;
    width: 100%;
    height: 100%;
    text-decoration: none;
    @include transition;
    &-text {
        color: $color-white;
        @include font-size(26px);
        font-weight: 300;
        @include media(md) {
            @include font-size(32px);
            margin-top: 5px;
        }
        @include media(xl) {
            @include font-size(40px);
        }
    }
    &-image {
        background-color: $color-black;
        padding: 20px 10px;
        margin-top: 15px;
        @include media(md) {
            margin-top: 0;
        }
        img {
            max-width: 200px;
            @include media(md) {
                max-width: 240px;
            }
            @include media(lg) {
                max-width: 280px;
            }
        }
    }
    &:hover,
    &:focus {
        transform: scale(1.08);
    }
}


.devider-title{
    background: $color-blue-light;
    padding:15px 0;
    h2{
        margin:0;
        font-size: 23px;
        line-height: 25px;
				color: $color-white;
				font-weight: 300;
				display: inline-block;
    		border-bottom: 1px solid $color-white;
    }
    &-link{
        text-decoration: none;
        color: $color-white;
        display:inline-block;
        @include flex-block;
        font-size: 16px;
        @include align-items(center);
				text-decoration: none !important;
        &:after{
            content:"\e91f";
            background:transparent;
            @include border-radius(50%);
            border:1px solid $color-white;
            margin:0 0 0 10px;
            @include transition;
            width: 24px;
            height: 24px;
            @include font-size(24px);
            line-height:23px;
						padding-left: 2px;
        }
        &:focus,
        &:hover{
            color: $color-black;
            &:after{
                background: $color-white;
                color: $color-blue-light;
                border:1px solid $color-white;
            }
        }
    }
    span{
        display:none;
    }
    @include media(xs){
        span{
            display: inline-block;
            margin:0 0 0 5px;
        }
    }
    @include media(md){
        h2{
            font-size: 27px;
            line-height: 30px;
						margin-left: 50%;
						@include translate(-50%, 0);
						border-bottom: 1px solid $color-white;
						padding-bottom: 5px;
        }
        &-link{
            font-size: 20px;
            &:after{
                width: 30px;
                height: 30px;
                @include font-size(30px);
                line-height: 29px;
								padding-left: 3px;
            }
        }
    }
    @include media(lg){
        padding: 25px 0;
        h2{
            font-size: 30px;
            line-height: 34px;
        }
    }
    @include media(xl){
        h2{
            font-size: 35px;
        }
        &-link{
            &:after{
                width:34px;
                height:34px;
                @include font-size(34px);
                line-height:33px;
								padding-left: 4px;
            }
        }
    }
    @include media(xxl){
        padding: 30px 0;
        h2{
            font-size: 41px;
            line-height: 1;
        }
        &-link{
            font-size: 22px;
            line-height: 1;
            color: $color-white;
						padding-left: 4px;
        }
    }
    &.devider-pink{
        background: $color-pink;
        h2{
            color:$color-white
        }
        .devider-title-link {
						text-decoration: none !important;
            color:$color-white !important;
            &:after{
                background: $color-pink;
                border:1px solid $color-white;
            }
						&:focus,
            &:hover{
								text-decoration: none !important;
                &:after{
										text-decoration: none !important;
										background: $color-white;
	                	color: $color-pink;
	                	border:1px solid $color-white;
                }
            }
        }
    }
    &.devider-green {
        background: $color-green;
				.devider-title-link {
						text-decoration: none !important;
						color:$color-white !important;
						&:after{
								background: $color-green;
								border:1px solid $color-white;
						}
						&:focus,
						&:hover{
								text-decoration: none !important;
								&:after{
										text-decoration: none !important;
										background: $color-white;
										color: $color-green;
										border:1px solid $color-white;
								}
						}
				}
    }
}

.background-light-blue {
		background-color: #e8edf1;
}

.black-circle-arrow{
    width:26px;
    height:26px;
    background:transparent;
    color:#000;
    text-align:center;
		border: 1px solid transparent;
    @include border-radius(50%);
    @include transition;
    &:before{
        content:"\e91f";
        line-height:26px;
        @include font-size(24px);
        margin-left: 1px;
    }
		&:focus,
    &:hover{
        @include media(lg) {
					border:1px solid $color-primary !important;
					text-decoration: none !important;
        }
    }
}

.division-box{
.division-item,
.division-item-news,
.division-item-product {
      position:relative;
      text-decoration:none;
      color: $color-primary;
      font-size: 13px;
      line-height: 16px;
      min-height: 1px;
      padding: 10px;
      width: 50%;
      max-width: 50%;
      &-image{
          padding-bottom: 50%;
          position: relative;
          width: auto;
          height: auto;
          margin: 10px 0;
          img{
              @include align();
              max-height: 100%;
							max-width: 100%;
          }
      }
      &-content{
          & h3{
              font-size: 16px;
              padding-right: 0;
              margin-bottom: 5px;
              @include eliminate-lines(24,1);
              margin: 0px !important;
							font-weight: 300;
							background-image: linear-gradient(#000 1px, transparent 1px);
							background-size: 100% 24px;
							background-position: 0 23px;
          }
          &-description{
							margin-top: 10px;
              @include eliminate-lines(16,4);
          }
          &-link{
              display:none;
          }
      }
      @include media(xxs,xxs){
          img{
               @include cover-image();
          }
      }
      @include media(xs){
          &-image{
              padding-bottom: 55%;
          }
          &-content{
              & h3{
                  font-size: 16px;
                  line-height: 25px;
                  max-height: 48px;
                  background-size: 100% 24px;
                  background-position: 0 21px;
                  padding-right: 30px;
                  @include eliminate-lines(25,1);
									background-image: linear-gradient(#000 1px, transparent 1px);
									background-size: 100% 25px;
									background-position: 0 24px;
              }
              &-link{
                  position:absolute;
                  display:block;
                  left: auto;
                  right: 10px;
                  top: 8px;
                  bottom: auto;
              }
          }
      }
      @include media(md){
          @include flex-block();
          @include flex-flow();
          @include justify-content(space-between);
          height: 120px;
          &-image {
              position: relative;
              width: 50%;
              @include calc("height","100% + 20px");
              padding-bottom: 0;
              margin: -10px;
              overflow: hidden;

              img {
                 @include cover-image();
              }
          }
          &-content{
              width: 50%;
              order: 2;
							position: relative;
							padding: 5px 10px;
              & h3{
                  font-size:18px;
                  padding-right: 25px;
                  @include eliminate-lines(24,2);
									background-image: none;
									background-size: 0;
									background-position: 0;
              }
              &-description{
                  @include eliminate-lines(18,4);
                  @include font-size(14px);
                  line-height: 18px;
									margin-top: 20px;
              }
              &-link{
                  position: absolute;
									left: -2px;
									bottom: 10px;
									right: auto;
									top: auto;
									left: 5px;
                  &:before {
                    @include font-size(30px);
                  }
              }
          }
      }
      @include media(lg){
          height: 150px;
          &-content{
              & h3{
                @include font-size(26px);
                line-height: 31px;
                max-height: 60px;
                height: auto;
                margin-bottom: 5px;
              }
							&-description{
									@include eliminate-lines(18,4);
									@include font-size(14px);
									line-height: 18px;
							}
              &-link{
                bottom: 10px;
              }
          }
      }
      @include media(xl){
          &-image{
              width: 51.5%;
          }
          &-content{
              width: 48%;
          }
      }

      @include media(xxl){
          padding:13px 25px;
          &-image{
              @include calc("height","100% + 26px");
              margin: -13px -25px;
              width: 54%;
          }
          &-content{
              width: 46%;
          }
      }
      &.item-grey{
          background: $color-secondary;
      }
      &.item-yellow{
          background: $color-primary;
      }
			&.item-product {
					background: $color-blue-light-bg;
					border-bottom: 1px solid $color-primary;
			}
			&:focus,
    	&:hover{
        .black-circle-arrow {
            @include media(lg) {
              color: $color-primary;
							border-color: $color-primary;
            }
          }
    }

  }

    .item-stuff {
        .division-item-news-content {
            &-description {
                @include eliminate-lines(16,3);
            }
        }

        @include media(md) {
            .division-item-news-content {
               position: relative;
                h3 {
                    @include eliminate-lines(24,2);
                }
                &-description {
                    @include eliminate-lines(20,4);
                }
                &-link {
                    position: absolute;
                    top: 140px;
                    left: -6px;
                }
            }
        }
        @include media(lg) {
          .division-item-news-content {
            h3 {
              @include eliminate-lines(30,2);
            }
            &-description {
              @include eliminate-lines(24,6);
            }
            &-link {
                position: absolute;
                top: 190px;
                left: 1px;
            }
          }
      }
    }
}

.hp-category-links-small-devices {
		width: 100%;
		display: block;
		@include media(lg) {
			 display: none;
		}
		.selectric-wrapper {
				margin: 0!important;
		}
}

.litec-hp-project {
		width: 50%;
		min-height: 250px;
		text-decoration: none;
		@include media(md) {
				min-height: 300px;
		}
		@include media(lg) {
				min-height: 350px;
		}
		&:first-of-type {
				.litec-hp-project-content {
						border-right: 1px solid $color-primary;
						border-left: 0;
				}
		}
		&-image {
				position: relative;
				height: 200px;
				overflow: hidden;
				@include media(md) {
						height: 250px;
				}
				@include media(lg) {
						height: 300px;
				}
				img {
						width: 100%;
						@include cover-image();
						object-fit: cover;
				}
		}
		&-content {
				padding: 10px;
				border-left: 1px solid $color-primary;
				@include media(md) {
						padding: 15px 20px;
				}
				@include media(lg) {
						padding: 20px 25px;
				}
				h2 {
						height: 50px;
						overflow: hidden;
						margin: 0;
						@include font-size(20px);
						line-height: 26px;
						@include media(md) {
								height: 56px;
								@include font-size(22px);
								line-height: 28px;
						}
						@include media(lg) {
								height: 66px;
								@include font-size(26px);
								line-height: 34px;
						}
				}
		}
		&:hover,
		&:focus {
			 h2 {
				 	text-decoration: underline;
			 }
		}
}

/*.hp-content,
.hp-banner  {
    padding: 0 20px;
    @include media(xxl) {
        padding: 0 40px;
    }
}*/

@each $name, $color in $card-theme {
		.theme-#{$name} {
        .hp-category-links-item {
            &:hover,
            &:focus {
                background-color: $color !important;
            }
        }
		}
}

.hp-banner {
    img {
        width: 100%;
    }
}
