@media print {

  header,
  footer,
  .box-header-content,
  .content-mobile-menu,
  .products-menu,
  .products-menu-generated,
  .newsletter-box,
  .square-button,
  .square-button-bw,
  .big-button-bw,
  .social-list,
  .big-button-bw-small,
  .content-button-level-up,
  .slick-arrow {
    display: none !important;
  }

  .main-content {
    width: 100%;
  }

  .slick-list {
    overflow: visible !important;
  }

  .slick-track {
    width: 100% !important;
    transform: none !important;
  }

  .slick-slide {
   display: inline-block !important;
   float: none !important;
  }

  .content-gallery-list {
    width: 100% !important;
    max-height: none !important;
  }

  .content-tabs-list,
	.contact-card-content {
    display: block !important;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: "Times New Roman", Times, serif;
    color: #423f3f !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Arial, Helvetica, sans-serif;
  }

  h1 {
    font-size: 16pt;
  }

  h2 {
    font-size: 14pt;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 12pt;
  }

  p {
    line-height: 1.4;
    font-size: 12pt;
  }

  a:link,
  a:visited {
    text-decoration: underline;
    font-weight: bold;
    color: #000;
  }


  img {
    border: 1px solid #000;
  }

  ul * {
    display: block;
  }
}
