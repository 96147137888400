@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.modal,
.product-registration-box {
  background-color: rgba($color-black, .4);
  color: $color-primary;
  padding-right: 0 !important;

  .modal-dialog {
    position: relative;
    min-height: 95%;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 100%;

    .modal-content {
      background-color: $color-blue-light-bg;
      padding: 0;
      width: 100%;
      margin: auto;
      @include border-radius(0);
      text-align: left;

      @include media(md) {
        width: 95%;
				margin-top: 10%;
      }

      @include media(lg) {
        width: 920px;
        max-width: 95%;
      }

      @include media(xl) {
        width: 1100px;
      }

      label {
        @include font-size(15px);
        color: $color-primary;
        margin-bottom: 7px;
        display: block;
        letter-spacing: 0;
        line-height: 22px;

        @include media(md) {
          @include font-size(18px);
          line-height: 26px;
        }
      }

      input[type="text"],
      input[type="password"],
      select,
      textarea {
        background-color: $color-blue-light-bg;
				border: 1px solid $color-primary !important;
        width: 100%;
        padding: 8px 10px;
        height: 38px;
        color: $color-primary !important;
        margin-bottom: 15px;
        border-radius: 0 !important;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-weight: 200;
        @include transition();
				&::-webkit-input-placeholder {
						color: $color-primary;
				}
				&:-moz-placeholder {
						color: $color-primary;
				}
				&::-moz-placeholder {
						color: $color-primary;
				}
				&:-ms-input-placeholder {
						color: $color-primary;
				}

        &:focus {
          outline: 0;
        }
      }

      select::-ms-expand {
        display: none;
      }

      textarea {
        height: 100px;
        margin-bottom: 0px;

        &:-webkit-resizer {
          display: none;
        }
      }

      .modal-header {
				position: relative;
        padding: 10px;
				margin-top: 20px;

        @include media(md) {
          padding: 15px 20px;
        }

        @include media(lg) {
          padding: 15px 30px;
        }

        @include media(xxl) {
          padding: 15px 50px;
        }

        .modal-title {
					margin-top: 20px;
          margin: 0px;
          padding: 0px;
          border-bottom: 1px solid $color-primary !important;
        }

        h2 {
          color: $color-primary;
          font-weight: 300;
					display: block;
					width: 100%;
          @include font-size(25px);

          @include media(md) {
            @include font-size(30px);
          }

          @include media(xl) {
            @include font-size(35px);
          }

          @include media(xxl) {
            @include font-size(41px);
          }
        }

      }

      .modal-body,
      .modal-footer {
        margin-top: 0px;
        padding: 0 10px 20px 10px;

        @include media(md) {
          padding: 0 20px 30px 20px;
        }

        @include media(lg) {
          padding: 0 30px 30px 30px;
        }

        @include media(xxl) {
          padding: 0 50px 50px 50px;
        }
      }

      .modal-body {
        text-align: left;

        .modal-perex {
          margin: 10px 0 30px;
          text-align: left;
          @include font-size(14px);
          line-height: 18px;
          font-weight: 300;

          @include media(sm) {
            margin-bottom: 30px;
          }
        }
      }

        .contact-submit-cont {
          width: 100%;
          margin: 0;
        }
        .contact-submit-text {
          @include font-size(12px);
          color: $color-primary;
          text-align: left;
          margin-bottom: 5px;
					padding: 10px 0;

          @include media(sm) {
            margin-bottom: 10px;
          }

          @include media(md) {
            @include font-size(13px);
						padding: 0;
          }

          @include media(lg) {
            margin-bottom: 18px;
          }
        }

        input[type="submit"] {
          width: 100%;
          padding: 0 15px;
          min-height: 40px;
          background-color: $color-primary;
					border: 1px solid $color-primary !important;
					color: $color-white;
          @include animate("background-color, color");
          @include font-size(16px);
          font-weight: 300;
          line-height: 20px;
          text-decoration: none;
          text-align: center;
          border: 0px;
          cursor: pointer;
          @include border-radius(0);

          &:focus,
          &:hover {
            color: $color-primary !important;
            background: transparent;
            text-decoration: none !important;
          }

          @include media(md) {
             @include font-size(18px);
          }
        }
    }
		.password-strength-text {
				margin-bottom: 10px;
		}
		.passw-strength-indicator{
				display: none;
		}
		.selectric {
				@include media(sm) {
						margin-top: 30px;
				}
		}
  }

  .ErrorLabel,
  .EditingFormErrorLabel {
    position: relative;
    display: block;
    width: 100%;
    margin: 20px 0px;
    padding-left: 5px;
		color: $color-red;
		font-weight: 600;
  }
}

.fix-error-message {
		height: 120px;
}


#modal-contact {
  .modal-content {
    padding: 0;

    .modal-body {
      padding-bottom: 0px;

      textarea {
        height: 88px;
        margin-bottom: 20px;

        @include media(sm) {
          margin-bottom: 7px;
        }

        @include media(md) {
          height: 138px;
        }
      }

      .recaptcha-cont {
        display: block;
        width: 100%;
        position: relative;
        text-align: center;


        @include media(md) {
          text-align: left;
          min-height: 74px;
        }

        &  div {
         display: inline-block !important;
         padding: 0px !important;
         margin: 0px auto !important;
       }

        & > div {
		        @include media(xxs, xxs) {
			          @include transform-origin("left top");
			            transform-origin: left top;
			          }
		        }
      	}
    }

    .modal-footer {
      @include flex-block();
      @include flex-flow(column, wrap);

      @include media(sm) {
        @include flex-block();
        @include align-items(flex-start);
        @include flex-flow(row, nowrap);
      }

      .contact-upload-cont {
        @include flex-block;
        @include align-items(center);
        width: 100%;
        margin: 0px;
        margin: 20px 0;

        @include media(sm) {
          display: block;
          width: 35%;
          margin: 0;
          padding-right: 13px;
        }

        @include media(md) {
          padding-right: 44px;
        }

        @include media(lg) {
          width: 25%;
          padding-right: 20px;
        }

        input[type="file"] {
          display: none;
        }

        .contact-upload-text {
          @include font-size(14px);
          text-align: left;
          order: 2;
          padding-left: 5px;
          line-height: 19px;

          @include media(sm) {
            @include font-size(14px);
            margin-bottom: 10px;
            padding-left: 0;
          }

          @include media(lg) {
            @include font-size(18px);
            margin-bottom: 18px;
          }
        }

        .contact-upload-button {
          min-width: 125px;
          @include font-size(16px);
          order: 1;

          @include media(sm) {
            width: 100%;
          }
        }
      }

      .contact-submit-cont {

        @include media(sm) {
          width: 68%;
        }

        @include media(lg) {
          width: 75%;
        }

        .contact-submit-text {
          line-height: 19px;

          @include media(sm) {
            text-align: right;
          }
        }
      }
    }
  }
}

#modal-sign-up {

  .modal-content {
    padding: 0;

    .modal-body {
       & > div {
				 		.EditingFormErrorLabel {

						}
			 }
    }
  }
	.EditingFormErrorLabel {
			margin-top: 0px;
			margin-top: -15px;
	}
	.selectric {
			@include media(sm) {
					margin-top: 0px;
			}
	}
}

#modal-login {
    .form-control-error{
        padding:5px 10px;
        color: #000;
        background: #ffd100;
        @include font-size(14px);
        margin:-10px 0 15px 0;
    }
    .form-control-error[style*="display: inline"]{
        display:block !important;
    }
  .modal-content {
    padding: 0;
    max-width: 300px;

    .modal-body {
      padding-bottom: 10px;
      table{
          width:100%;
      }
    }


    .contact-submit-text {
      margin-top: 7px;
      margin-bottom: 0px;

      @include media(sm) {
        margin-top: 10px;
        margin-bottom: 0px;
      }

      @include media(lg) {
        margin-bottom: 0px;
      }
    }
  }
}


.field-required {
  &:after {
    content: "*";
    color: #ff5445;
  }
}

.close,
.close-yellow,
.close-black {
  $size: 30px;

  position: absolute;
	right: 10px;
	top: 5px;
  font-size: 0;
  width: $size;
  height: $size;
  border-radius: $size;
  opacity: 1 !important;
  border: 1px solid $color-primary !important;
  outline: 0;
  text-shadow: none !important;

	@include media(md) {
		right: 20px;
		top: 15px;
	}

	@include media(lg) {
		right: 30px;
	}

	@include media(xxl) {
		right: 50px;
		top: 25px;
	}

  &:after {
    content: "\e921";
    @include icomoon();
    @include font-size($size - 17);
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
    color: $color-primary;
    @include transition(100ms);
    cursor: pointer;
  }

  &:focus,
  &:hover {
    background-color: $color-primary !important;
    outline:0;

    &:after {
      color: $color-white;
    }
  }
}


.close-yellow {
  border: 1px solid $color-black !important;

  &:after {
    color: $color-black !important;
  }

  &:focus,
  &:hover {
    background-color: $color-black !important;

    &:after {
      color: $color-primary !important;
    }
  }
}

.padding-footer {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.modal{
  &.pux-modal{
    background-color:transparent !important;
    .modal-dialog{
      min-height: 1px;
      margin: 10px auto;
      text-align: center;
      width: auto;
      max-width: 100%;
      position: absolute;
      bottom: 0;
      right: 10px;
      .close{
        @include align-self(flex-end);
        border:none !important;
        color:#000 !important;
        top:0 !important;
        left:0 !important;
        position: static !important;
        &:after{
          color:#000 !important;
        }
        &:focus,
        &:hover{
          background-color: transparent !important;
        }
      }
      .modal-content{
        background-color: #dadada;
        width:auto;
        max-width: 100%;
      }
    }
  }
}

.modal-backdrop{
  background-color: transparent !important;
}

.modal-open.pux-modal-open{
  overflow: auto;
  header{
    margin-left: -11px;
  }
}
