@import '_variables';

@mixin font($type, $size, $weight:normal) {
  font-family: $type;
  font-size: $size;
  font-weight: $weight;
}

@mixin icomoon() {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin unselectable() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}


@mixin resize-by-ratio($ratio: 0.75) {
  @include calc('width', $menu-logo-width ' * ' $ratio);
  @include calc('height', $menu-logo-height ' * ' $ratio);
}

@mixin list-reset() {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin cover-image(){
  display: block;
  position: absolute;
  @include align(both, -200%);

  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@mixin count-of-lines($height-line, $count-line) {
  display: block;
  height: $height-line * $count-line +px;
  line-height: $height-line +px;
  overflow: hidden;
}

@mixin pseudo-elements($content: "", $position: relative, $top: 0, $left: 0) {
  content: $content;
  position: $position;
  top: $top;
  left: $left;
}
