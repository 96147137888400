
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";


.booking-form {
		padding: 10px;
		background-color: $color-blue-light-bg;
		@include media(sm) {
				padding: 15px;
		}
		h2 {
				padding-top: 0;
		}
		label {
				display: none;
		}
		input[type="text"],
		textarea {
				border: 1px solid $color-primary !important;
				color: $color-primary !important;
				background-color: inherit !important;
				border-radius: 0;
				margin: 10px 0;
				resize: none;
				@include font-size(14px);
				font-weight: 300;
		}
		input[type="submit"]
		{
				background-color: inherit;
		}
		input::-webkit-input-placeholder {
	   		text-align: center;
				color: $color-primary;
		}
		input:-moz-placeholder {
	   		text-align: center;
				color: $color-primary;
		}
		input::-moz-placeholder {
	   		text-align: center;
				color: $color-primary;
		}
		input:-ms-input-placeholder {
	   		text-align: left;
				color: $color-primary;
		}
		textarea::-webkit-input-placeholder {
				text-align: left;
				color: $color-primary;
		}
		textarea:-moz-placeholder {
				text-align: left;
				color: $color-primary;
		}
		textarea::-moz-placeholder {
				text-align: left;
				color: $color-primary;
		}
		textarea:-ms-input-placeholder {
				text-align: left;
				color: $color-primary;
		}
		textarea {
				min-height: 150px;
				line-height: 18px;
		}
		.show-more-wraper {
				margin-top: 10px;
		}
		.ErrorLabel,
		.EditingFormErrorLabel {
				font-weight: bolder;
				color: $color-red;
		}
}

.side-newsletter {
		padding: 10px;
		background-color: $color-blue-light-bg;
		color: $color-primary;
		margin: 30px 0 40px;
		@include media(sm) {
				padding: 15px;
				margin: 50px 0;
		}
		&-text {
				@include font-size(14px);
				line-height: 18px;
				padding-bottom: 5px;
		}
		&-small-text {
				@include font-size(10px);
				line-height: 14px;
				a	{
						text-decoration: none;
						&:focus,
						&:hover {
								text-decoration: underline;
						}
				}
		}
		input[type="text"] {
				border: 1px solid $color-primary !important;
				color: $color-primary !important;
				background-color: inherit !important;
				border-radius: 0;
				margin: 10px 0;
				resize: none;
				@include font-size(15px);
				font-weight: 300;
				text-align: center;
		}
		input[type="submit"]
		{
				background-color: inherit;
				margin-bottom: 15px;
				width: 100%;
				padding: 7px 0;
				@include font-size(15px);
				border-color: $color-blue-bg;
				font-weight: 300;
		}
		.ErrorLabel,
		.EditingFormErrorLabel {
				font-weight: bolder;
				display: block;
				padding-bottom: 10px;
				color: $color-red;
		}
}

.newsletter-mt {
		@include media(md) {
				margin-top: 100px;
		}
}

.contact-tabs {
		margin-top: 50px;
		@include media(md) {
				margin-top: 70px;
		}
}

.contact-menu-item {
		display: block;
		width: 100%;
		@include font-size(18px);
		padding: 10px 5px;
		cursor: pointer;
	 	@include animate("background-color, color");
		@include media(md) {
				@include font-size(26px);
				padding: 9px 10px;
				line-height: 30px;
		}
		&.active {
				background-color: $color-primary;
				color: $color-white;
		}
}


.contact-tab-list {
		@include flex-block;
		@include flex-flow(row, wrap);
		margin-left: -15px;
		margin-right: -15px;
}

.contact-card {
		@include flex(0 0 100%);
		max-width: 100%;
		font-weight: 300;
		margin-bottom: 20px;
		padding-left: 15px;
		padding-right: 15px;
		@include media(md) {
				@include flex(0 0 50%);
				max-width: 50%;
		}
		@include media(lg) {
				@include flex(0 0 33.3333333%);
				max-width: 33.333333%;
		}
		&-title {
				position: relative;
				@include flex-block;
				@include justify-content(center);
				@include align-items(center);
				padding: 10px 25px 10px 10px;
				background-color: $color-blue-light-bg;
				color: $color-primary;
				text-align: center;
				text-transform: uppercase;
				@include font-size(18px);
				cursor: pointer;
				@include transition(200ms);
				@include media(md) {
						padding: 15px 25px 15px 10px;
				}
				.ico-holder {
						position: absolute;
						right: 0px;
						top: 50%;
						width: 15px;
						height: 25px;
						@include translate(-50%, -50%);
						&:after {
								position: absolute;
								right: 0;
								top: 0;
								@include icomoon;
								content: "\e91f";
								@include font-size(25px);
								color: $color-primary;
								@include transition(200ms);
								@include transform-origin(50% 50%);
						}
				}
		}
		&:hover,
		&:focus {
				.contact-card-title {
						background-color: $color-primary;
						color: $color-white;
						.ico-holder {
								&:after {
										color: $color-white;
								}
						}
				}
		}
		&.active {
				.contact-card-title {
						background-color: $color-primary;
						color: $color-white;
						.ico-holder {
								&:after {
										@include rotate(90deg);
										color: $color-white;
								}
						}
				}
		}
		&-content {
				display: none;
				padding: 15px 0;
				word-break: break-all;
		}
		&-name {
				@include font-size(18px);
				padding-bottom: 20px;
		}
		&-space {
				padding-top: 10px;
		}
}

.contact-map {
		width: 100%;
		padding-bottom: 60%;
		@include media(sm) {
				margin-top: 20px;
				padding-bottom: 71%;
		}
}

#Africa {
	margin-top: 30px;
	@include media(md) {
			margin-top: 0;
	}
}

.contact-text {
		@include font-size(16px);
		line-height: 20px;
		padding-bottom: 20px;
		@include media(md) {
				@include font-size(20px);
				line-height: 26px;
		}
		@include media(lg) {
				padding-top: 20px;
				padding-bottom: 40px;
		}
		
}

.contact-form {
		margin-top: 40px;
		@include media(md) {
				margin-top: 20px;
		}
		.contact-form-therms-cont {
				width: 100%;
				text-align: center;
				padding: 10px 10px 20px 10px;
				@include media(md) {
						text-align: left;
				}
		}
}
