
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.litec-card {
		display: inline-block;
		width: 100%;
		padding-bottom: 30px;
		text-decoration: none !important;
		&-item {
				width: 100%;
				display: block;
				text-decoration: none;
				border: 1px solid #e8edf1;
				&-title {
						padding: 7px 45px 7px 30px;
						text-align: center;
						@include font-size(14px);
						color: $color-primary;
						background-color: #e8edf1;
						position: relative;
						line-height: 1.25;
						@include media(md) {
								padding: 10px 45px 10px 30px;
								@include font-size(18px);
						}
						&:before {
								content: "";
								position: absolute;
								display: inline-block;
								top: 50%;
								right: 15px;
								width: 20px;
								height: 20px;
								border: 1px solid transparent;
								@include border-radius(50%);
								@include translate(2px,-50%);
								@include transition;
								@include media(md) {
										width: 25px;
										height: 25px;
										@include font-size(25px);
										@include translate(3px,-50%);
								}
						}
						&:after {
								content: "\e91f";
								@include icomoon;
								@include font-size(20px);
								color: $color-primary;
								display: inline-block;
								position: absolute;
								top: 50%;
								right: 15px;
								@include translate(0,-50%);
								@include media(md) {
									  @include font-size(25px);
								}
						}
				}
				&-image {
						position: relative;
						width: 100%;
						padding-bottom: 50%;
						overflow: hidden;
						img {
							 	@include cover-image();
				 		}
				}
		}
		&-text {
				color: $color-primary;
				padding-top: 10px;
				@include font-size(12px);
				line-height: 14px;
				@include media(md) {
						@include font-size(14px);
						line-height: 18px;
				}
		}
		&:focus,
		&:hover {
				.litec-card-item-title {
						&:before {
								@include transition;
								border: 1px solid $color-primary;
						}
				}
		}
		&.card-v3 {
				@include media(md) {
						height: 100%;
				}
				.litec-card-item {
						border-width: 2px;
						@include media(md) {
								height: 100%;
						}
						&-image {
						  	img {
										@include transition;
								}
						}
				}
				.litec-card-text {
						padding: 10px;
				}
		  	&:hover,
		  	&:focus {
		  			.litec-card-item-image {
								img {
										transform: scale(1.08);
								}
						}
		  	}
		}
}

.big-card-preview {
		margin-top: 60px;
		@include media(md) {
				margin-top: 80px;
		}
		@include media(xl) {
				margin-top: 140px;
		}
}

.small-card-preview {
		margin-top: 40px;
		@include media(md) {
				margin-top: 60px;
				padding-bottom: 30px;
		}
		@include media(xl) {
				margin-top: 80px;
		}
}

.litec-card-v2 {
		border: 2px solid $color-primary;
		color: $color-primary;
		position: relative;
		display: block;
		width: 100%;
		padding-top: 100%;
		margin-bottom: 30px;
		@include font-size(30px);
		@include media(md) {
				@include font-size(34px);
				border-width: 3px;
		}
		@include media(lg) {
				border-width: 4px;
				@include font-size(42px);
				margin-bottom: 40px;
		}
		&-title {
		  	position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				line-height: 1.1;
				text-align: center;
    		width: 80%;
		}
		&:hover,
		&:focus {
				background-color: $color-primary;
				color: $color-white;
		}
		&.small-size {
				margin-bottom: 20px;
				@include font-size(24px);
				@include media(md) {
						@include font-size(30px);
				}
				@include media(lg) {
						@include font-size(34px);
						margin-bottom: 30px;
				}
		}
}

@each $name, $color in $card-theme {
		.card-#{$name} {
				.litec-card-item {
						border-color: $color;
						color: $color-white;
						&-title {
								background-color: $color;
								color: $color-white;
								&:before {
										border: 1px solid transparent;
								}
								&:after {
										color: $color-white;
								}
						}
				}
				&:focus,
				&:hover {
						.litec-card-item-title {
								&:before {
										border: 1px solid $color-white;
								}
						}
				}
		}
}

@each $name, $color in $card-theme {
		.card-v2-#{$name} {
				color: $color;
				border-color: $color;
				@include transition;
				&:focus,
				&:hover {
						background-color: $color;
						color: $color-white;
				}
		}
}

.button-show-products {
  margin-top: 20px;
  margin-bottom: 30px;
}

.product-table{
    tr{
        &:first-child{
            td{
                background:$color-primary;
                &:first-child{
                    border: 1px solid #dedede;
                }
            }
        }
    }
}

.product-title {
	  margin-bottom: 10px !important;
}

@each $name, $color in $card-theme {
		.theme-#{$name} {
				h2:not(.title-line-slim),
				.title-line {
						color: $color;
						border-color: $color;
				}
				.content-tabs-title-link {
						color: $color;
						 * {
							 	color: $color;
						 }
				}
				.contact-panel-btn {
						background-color: $color;
				}
				.slick-arrow {
						&:before {
								color: $color;
								border-color: $color;
						}
						&:hover,
						&:focus {
								&:before {
										background-color: $color;
										color: $color-white;
								}
						}
				}
				.box-product-social-button,
				.product-social-list-icon{
						&:before {
								color: $color !important;
						}
						&:hover,
						&:focus,
						&.open {
								&:after {
										border-color: $color !important;
								}
						}
				}
				&.content-tabs-title,
				&.content-tabs-title a {
						color: $color;
						border-color: $color;
				}
		}
}

.dot-list {
		ul {
			 li {
				 padding-bottom: 4px;
				 	&:before {
				 			content: "";
							width: 2px;
							height: 2px;
							background-color: $color-primary;
							border-radius: 50%;
							left: 0;
    					top: 14px;
				 	}
			 }
		}
}

.underline-title {
		font-size: 20px;
		padding: 0 20px;
		display: inline-block;
		@include media(md) {
				font-size: 26px;
				padding: 0 30px;
		}
		@include media(xl) {
				font-size: 30px;
		}
		border-bottom: 1px solid $color-white;
}
