@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.insert-image{
    width:100%;
    position:relative;
    margin: 20px 0;
		text-align: center;
    img{
        max-width:100%;
    }
}

.insert-image-bottom {
    width:100%;
    position:relative;
    margin-bottom: 20px;
		text-align: center;
    img {
        max-width:100%;
    }
}

.products-list {
    .items-container {
        margin-left: -5px;
        margin-right: -5px;
        @include flex-block;
        @include flex-wrap(wrap);
    }
}

.small-space .items-container [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.big-space {
	$space-base: 15px;
	$space-medium: 13px;
	$space: 26px;

  & > .row,
  & > .items-container {
    margin-left: -$space-base;
    margin-right: -$space-base;

		@include media(lg) {
			margin-left: -$space-medium;
	    margin-right: -$space-medium;
		}

		@include media(xl) {
			margin-left: -$space;
			margin-right: -$space;
		}

    & > .col-md-3,
		& > .col-md-9 {
      padding-left: $space-base;
      padding-right: $space-base;

			@include media(lg) {
				padding-left: $space-medium;
		    padding-right: $space-medium;
			}

			@include media(xl) {
				padding-left: $space;
				padding-right: $space;

				&:last-of-type {
					padding-left: $space+7.5;
				}
			}
    }
  }
}

.mini-space {
	$space-base: 7px;

  .row,
  .items-container {
    margin-left: -$space-base;
    margin-right: -$space-base;

    [class*="col-"] {
      padding-left: $space-base;
      padding-right: $space-base;
    }
  }
}

.small-space {
	$space-base: 10px;

  .row,
  .items-container {
    margin-left: -$space-base;
    margin-right: -$space-base;

    [class*="col-"] {
      padding-left: $space-base;
      padding-right: $space-base;
    }
  }
}

.medium-space {
	$space-base: 10px;

  .row,
  .items-container {
    margin-left: -$space-base;
    margin-right: -$space-base;

    [class*="col-"] {
      padding-left: $space-base;
      padding-right: $space-base;
    }
  }
}

.box-header {
	position: relative;
	max-width: 1280px;
	min-height: 87px;
	margin: 0 auto;
	margin-bottom: 10px;

	color: $color-white;

	@include media(xs) {
		min-height: 112px;
	}

	@include media(sm) {
	  min-height: 117px;
 	}

	@include media(md) {
		min-height: 132px;
	}

	@include media(lg) {
	  min-height: 145px;
		margin-bottom: 35px;
	}



	.box-header-content {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 15px 0 10px;

		.pux-container {
			position: relative;
			padding-right: 100px;
		}

		@include media(xs) {
			padding: 25px 0 20px;
		}

		.breadcrumbs {
			color: inherit;
			line-height: 18px;
      padding-right: 80px;
			color: $color-primary;

			a,
			span {
				margin-top: -5px;
			}

			.CMSBreadCrumbsCurrentItem {
				color: inherit;
			}

			a {
				color: inherit;
				text-decoration: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}

		.box-header-title {
			padding-top: 10px;
			font-weight: bold;

			@include media(sm) {
				padding-top: 15px;
			}

			@include media(md) {
				padding-top: 25px;
			}

			@include media(lg) {
				padding-top: 30px;
			}
		}

		.box-header-social-print {
			position: absolute;
			top: 0px;
			right: 15px;

			.social-list {
				position: absolute;
				top: -5px;
				right: 75px;
				width: 0;
				height: 30px;
				background-color: transparent;
				overflow: hidden;
				@include transition;
				@include flex-block;

				@include media(sm) {
					height: 37px;
					top: -8px;
					right: 75px;
				}

				&.open {
					@include transition;
						width: auto;
				}

				&-icon {
					display: inline-block;
					float: left;
					width: 30px;
					height: 30px;
					@include font-size(0px);
					text-decoration: none;
					position: relative;

					@include media(sm) {
						width: 37px;
						height: 37px;
					}

					&:before {
						@include icomoon();
						@include font-size(23px);
						position: absolute;
						top: 50%;
						left: 50%;
						@include translate(-50%, -50%);
						color: $color-primary;
            cursor: pointer;

						@include media(sm) {
							@include font-size(27px);
						}
					}

					&:after {
							content: "";
							position: absolute;
							top: 50%;
							left: 50%;
							@include translate(-50%, -50%);
							color: $color-primary;
							@include transition;
							cursor: pointer;
							width: 30px;
							height: 30px;
							border-radius: 50%;
							border: 1px solid transparent;
							@include media(sm) {
									width: 35px;
									height: 35px;
							}
					}

					&:focus,
					&:hover {
						&:after {
							@include transition;
							border-color: $color-primary;
						}
					}
				}

				@each $name, $icon-number in $share-icon-names {
					.s-#{$name} {
							&:before {
								content: "#{$icon-number}";
							}
						}
				}
			}

			.box-header-social,
			.box-header-print {
					position: relative;
					display: inline-block;
					color: inherit;
					@include font-size(0px);
					width: 30px;
					height: 30px;
					color: $color-primary;
					cursor: pointer;

					&:before {
						@include icomoon();
	  			  @include font-size(22px);
						@include transition(100ms);
	        	cursor: pointer;
					}
					&:after {
							content: "";
							position: absolute;
							top: 38%;
							left: 42%;
							@include translate(-50%, -50%);
							color: $color-primary;
							cursor: pointer;
							width: 30px;
							height: 30px;
							border-radius: 50%;
							border: 1px solid transparent;
							@include media(sm) {
									width: 35px;
									height: 35px;
							}
					}
					&:focus,
					&:hover {
							&:after {
								@include transition;
								border-color: $color-primary;
							}
					}
					&.open {
							&:after {
								border-color: $color-primary;
							}
					}
				}

				.box-header-social {
						margin-right: 5px;
						&:before {
							content: "\e904";
						}
				}

				.box-header-print {
					&:before {
						content: "\e902";
					}
				}
		}
	}
}

.main-content,
.main-content-product {
	width: 100%;
	margin-top: 20px;
	color: $color-primary;
	@include font-size(14px);
	line-height: 18px;

	@include media (md) {
		margin-top: 0px;
	}

  h1,
  h2 {
		font-weight: 300;
		display: inline-block;
		border-bottom: 1px solid $color-primary;
		color: $color-primary;
		margin-bottom: 10px;
		@include media(md){
				margin-bottom: 20px;
		}
  }
  h1 {
      min-width: 200px;
      text-align: center;
  }
	h2 {
			@include font-size(20px);
			padding: 20px 0 4px;
			@include media(md){
					@include font-size(26px);
					line-height: 32px;
			}
	}

  img {
    max-width: 100%;
    //padding-bottom: 20px;
  }

  .insert-image {
		max-width: 100%;
	}

	.content-text {
		margin-bottom: 20px;
		@include font-size(14px);
		line-height: 18px;
		font-weight: 300;
		@include media(md) {
				margin-bottom: 40px;
		}
	}
	.biggest-font {
			@include font-size(18px);
			line-height: 24px;
	}

  a {
    text-decoration: none;
    //color: $color-black;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
	ul {
			@include list-reset;
			li {
					position: relative;
					padding-left: 20px;
					padding-bottom: 15px;
					&:before {
							position: absolute;
							content: ">";
							margin-right: 5px;
							left: 5px;
							top: 2px;
					}
			}
	}

}

.border-none {
		border: none !important;
		* {
				border: none !important;
		}
}

.main-content-product {
  h1,
  h2 {
		font-weight: 300;
		display: block;
		border-bottom: 1px solid $color-pink;
		color: $color-pink;
		width: 100%;
		@include media(md){
				margin-bottom: 35px;
		}
  }
}

.content-button-level-up,
.content-button-level-up-small {
  position: relative;
  display: none;
  padding-left: 35px;
  margin-top: 15px;
  margin-bottom: 25px;
  line-height: 20px;
  @include font-size(14px);
  text-decoration: none;
  color: $color-primary;
	@include media(md) {
			display: block;
	}

  @include media(lg) {
    padding-left: 45px;
    @include font-size(18px);
    font-weight: 300;
  }

  &:hover,
  &:focus {
    color:  $color-primary;
    text-decoration: none !important;

    &:before {
      color: $color-white;
      background-color: $color-primary;
    }
  }

  &:before {
    @include icomoon;
    @include pseudo-elements("\e920", absolute, 50%, 0px);
    display: inline-block;
    @include translate(0%, -50%);
    font-weight: 500;
    color: $color-primary;
    @include font-size(28px);
    width: 28px;
    height: 28px;
    border-radius: 28px;
    text-align: center;
    line-height: 26px;
    border: 1px solid $color-primary;
    @include transition(150ms);
		padding-right: 3px;

    @include media(lg) {
      @include font-size(35px);
      width: 35px;
      height: 35px;
      border-radius: 35px;
      text-align: center;
      line-height: 32px;
    }
  }
}

.content-button-level-up-small {
	display: block;
	@include media(md) {
			display: none;
	}
}

.content-mobile-menu {
	display: none !important;
	margin-top: 10px;
  @include font-size(16px);
	color: $color-primary !important;
	border-color: $color-primary !important;

	&:hover {
		color: $color-white !important;
		background: $color-primary !important;
	}

	&:after {
		display: inline-block;
		vertical-align: middle;
    @include icomoon();
		content: "\e903";
		margin-left: 5px;
		margin-top: -3px;
    @include font-size(35px);
		@include animate(transform);
	}

	&.active{
		&:after{
			@include rotate(180deg);
		}
	}

	@include media(xxs, sm){
		display: block !important;
	}
}

.content-menu,
.products-menu {
  width: 100.25%;
  @include list-reset();
  margin-top: 20px;
  color: $color-primary;

  @include media(md) {
    display: block !important;
  }

  @include media(md) {
    margin-top: 0px;
  }

  .content-menu-item,
  .products-menu-item {
    & > a {
      display: inline-block;
      text-decoration: none;
      @include font-size(16px);
      line-height: 17px;
      font-weight: 300;
      padding: 10px 0px 3px 0px;
      @include animate("background-color, color");
			color: $color-primary;
			border-bottom: 1px solid transparent;

      @include media(md) {
        @include font-size(14px);
				padding: 5px 0px;
      }

      @include media(lg) {
        @include font-size(18px);
        font-weight: 300;
        padding: 5px 0px;
      }

      @include media(xl) {
        line-height: 20px;
      }

      &:hover,
      &:focus {
        color: $color-primary;
				border-bottom: 1px solid $color-primary;
      }
    }

    &.active > a {
      color: $color-primary;
			border-bottom: 1px solid $color-primary;
    }

		&.active .content-submenu,
    &.active .products-submenu {
			display: block;
    }

    .content-submenu,
    .products-submenu {
      width: 100%;
      @include list-reset();
      padding: 0;
			margin-top: 5px;
      margin-bottom: 10px;
      text-decoration: none;
			display: none;
			@include media (md){
					columns: 2;
					margin-bottom: 15px;
			}

      .content-submenu-item,
      .products-submenu-item {
				-webkit-column-break-inside:avoid;
			 	-moz-column-break-inside:avoid;
			  -o-column-break-inside:avoid;
			 	-ms-column-break-inside:avoid;
				column-break-inside:avoid;
        & > a {
          position: relative;
          display: inline-block;
          padding: 7px 0 2px 0px;
          color: $color-primary;
          @include font-size(14px);
          line-height: 17px;
          text-decoration: none;
					font-weight: 300;
					border-bottom: 1px solid transparent;
					&:hover,
					&:focus {
						text-decoration: none !important;
						border-bottom: 1px solid $color-primary;
					}

          @include media(md) {
            padding: 5px 0px 2px;
            @include font-size(13px);
          }

          @include media(lg) {
            @include font-size(15px);
          }

          @include media(xl) {
            @include font-size(16px);
            line-height: 20px;
          }

          &:before {
    				display: block;
    				content: "";
    				width: 4px;
            height: 4px;
    				@include border-radius(10px);
    				position: absolute;
    				left: 0 ;
    				top: 11px;

            @include media(xl) {
              top: 14px;
            }

    			}

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        .active {
          font-weight: 300;
					border-color: $color-primary;
        }
      }
    }
  }
}

@each $name, $color in $card-theme {
		.menu-#{$name} {
				&.active > a {
						border-color: $color !important;
				}
				a {
						color: $color !important;
						&:hover,
						&:focus {
								border-color: $color !important;
						}
				}
				.content-submenu * {
						color: $color !important;
						&:hover,
						&:focus,
						&.active {
								border-color: $color !important;
						}
				}
		}
}

.content-menu-generated {
  width: 100.25%;
  margin-top: 20px;
  color: $color-primary;

  ul {
    @include list-reset();
  }

  @include media(md) {
    display: block !important;
  }

  @include media(md) {
    margin-top: 0px;
  }

   li {
    & > a {
      display: block;
      text-decoration: none;
      @include font-size(16px);
      line-height: 17px;
      color: inherit;
      font-weight: 300;
      padding: 5px 5px;
      @include animate("background-color, color");

      @include media(md) {
        @include font-size(14px);
      }

      @include media(lg) {
        @include font-size(16px);
        font-weight: 300;
        padding: 5px 10px;
      }

      @include media(xl) {
        @include font-size(18px);
        line-height: 20px;
      }

      &:hover,
      &:focus {
        background-color: $color-blue-light-bg;
        color: $color-primary
      }
    }

    &.Highlighted > a {
      background-color: $color-blue-light-bg;
      color: $color-primary;
    }

    &.Highlighted .content-submenu {
			display: block;
    }

    & > ul {
      width: 100%;
      @include list-reset();
      background-color: $color-blue-bg;
      padding: 0 10px;
      margin-bottom: 10px;
      text-decoration: none;
			display: none;

      & > li {
        & > a {
          position: relative;
          display: block;
          padding: 7px 0 7px 10px;
          color: $color-black;
          @include font-size(16px);
          line-height: 17px;
          text-decoration: none;

          @include media(md) {
            padding: 5px 0 5px 10px;
            @include font-size(13px);
          }

          @include media(lg) {
            @include font-size(16px);
          }

          @include media(xl) {
            @include font-size(18px);
            line-height: 20px;
          }

          &:before {
    				display: block;
    				content: "";
    				width: 4px;
            height: 4px;
    				background: $color-base;
    				@include border-radius(10px);
    				position: absolute;
    				left: 0 ;
    				top: 14px;
    			}

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        .Highlighted {
          font-weight: 300;
        }
      }
    }
  }
}

.content-list,
.products-list {
  .content-item-card {
    display: block;
    width: 100%;
    margin-top: 10px;
    background-color: $color-blue-bg;
    border: 1px solid $color-blue-bg;
    cursor: pointer;
    color: $color-black;
    text-decoration: none;

    @include media(sm) {
      @include flex-block();
      @include flex-flow();
      @include justify-content(space-between);
      height: 140px;
    }

    @include media(sm, sm) {
      border: 7px solid $color-blue-bg;
      height: 160px;
    }

    @include breakpointhelper(545px, 650px) {
      border: 1px solid $color-blue-bg;
      height: 140px;
    }

    @include media(lg) {
      border: 7px solid $color-blue-bg;
      height: 158px;
    }

     &:hover,
     &:focus {
       background-color: $color-primary;
       border-color: $color-primary;
       text-decoration: none !important;

       .content-item-card-text {
         &:before {
          background-color: $color-black;
         }

         &:after {
          color: $color-primary;
         }
       }
     }

    &-img {
      width: 100%;
      height: 0;
      padding-bottom: 80%;
      overflow: hidden;
      position: relative;

      @include media(sm) {
        width: 48.5%;
        height: 100%;
        padding-bottom: 0;
      }

      img {
        @include cover-image();
      }
    }

    &-text {
      width: 100%;
      position: relative;

      @include media(sm) {
        width: 48.5%;
        padding: 5px 10px 10px 0;
      }

      .content-item-title {
    		@include font-size(15px);
    		line-height: 26px;
    		max-height: 26px;
    		overflow: hidden;
    		font-weight: 300;
        border-bottom: 0 !important;

        @include media(xxs, xs) {
    		  text-decoration: underline;
          margin: 0;
          padding: 0 5px;
        }

        @include media(sm) {
    		  max-height: 52px;
          line-height: 26px;
          @include font-size(18px);
        	margin-bottom: 5px;
          background-image: linear-gradient(#000 2px, transparent 2px);
      		background-size: 100% 26px;
      		background-position: 0 23px;
        }

        @include media(md, md) {
          @include font-size(15px);
          line-height: 20px;
          max-height: 40px;
      		background-size: 100% 20px;
          background-position: 0 18px;
        }

        @include media(lg) {
      	  max-height: 52px;
          @include font-size(18px);
          line-height: 26px;
    		  font-weight: 300;
        }

    		a {
    			display: block;
    			color: $color-base;
    			text-decoration: none;
    		}
      }

      .content-item-perex {
        @include count-of-lines(18, 2);
        display: none;

        @include media(sm) {
          display: block;
        }
      }

      &:before {
        content: "";
        position: absolute;
        display: none;
        bottom: 13px;
        left: 2px;
        width: 27px;
        height: 27px;
        @include border-radius(27px);
        background-color: transparent;
        @include transition(100ms);

        @include media(lg) {
          display: block;
        }
      }

      &:after {
        content: "\e91f";
        position: absolute;
        display: none;
        @include font-size(30px);
        @include transition(100ms);

        @include media(sm) {
          display: block;
          bottom: 0px;
          left: -2px;
        }

        @include media(sm, md) {
          color: $color-black !important;
        }

        @include media(lg) {
          bottom: 12px;
          left: 0px;
        }
      }
    }
  }
}

.card-black {

  .content-item-card,
  .products-item-card {

    &:focus,
    &:hover {
      background-color: $color-blue-bg;
      border-color: $color-blue-bg;

      .content-item-card-text,
      .products-item-card-content {
        &:before {
         background-color: $color-black;
        }

        &:after {
         color: $color-white;
        }
      }
    }
  }
}

.card-yellow-hover {
  .content-item-card,
  .products-item-card {
    &:focus,
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;

      .content-item-card-text,
      .products-item-card-content {
        &:before {
         background-color: $color-black;
        }

        &:after {
         color: $color-primary;
        }
      }
    }
  }
}



.button-show-content,
.arrow-down-link {
  margin-top: 20px;
  margin-bottom: 30px;
}

.content-gallery {
  margin: 40px 0;
  @include flex-block();
  @include flex-flow();
  @include justify-content(space-between);

  &-list {
    max-height: 100px;
    @include calc("width", "100% - 100px");
    overflow: hidden;

    &-item {
      width: auto;
      height: 100px;

      img {
        width: auto;
        height: 100px;
        margin-left: 50%;
        @include translate(-50%, 0);
      }
    }
  }

  &-arrow-left,
  &-arrow-right {
    width: 40px;
    @include font-size(0px);
    cursor: pointer;
    margin-right: 10px;
    @include unselectable();

    &:before {
      @include icomoon;
      @include pseudo-elements("\e920", relative, 50%, 50%);
      display: block;
      @include translate(-50%, -50%);
      color: $color-primary;
      @include font-size(40px);
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid $color-primary;
      @include transition(150ms);
      @include unselectable();
			padding-left: 2px;
    }

    &:focus,
    &:hover {
      &:before {
        color: $color-white;
        background-color: $color-primary;
      }
    }
  }

  &-arrow-right {
      margin-right: 0px;
      margin-left: 10px;

      &:before {
        content: "\e91f";
				padding-left: 5px;
      }
  }
}


.content-tabs {
  margin-top: 25px;

  &-title {
    @include flex-block();
    @include flex-flow();
		border-bottom: 1px solid $color-pink;
		padding-bottom: 10px !important;
		@include media(sm) {
				padding-bottom: 15px !important;
				margin-top: 20px;
		}

    &-link {
      display: inline-block;
      margin-bottom: -5px;
      padding: 2px 10px;
      @include font-size(14px);
      color: $color-primary;
      text-decoration: none;
      font-weight: 300;

      @include media(sm) {
        padding: 2px 20px;
        @include font-size(16px);
      }

      @include media(md) {
        @include font-size(18px);
      }
			&:first-of-type {
					padding-left: 0;
			}
       &.active,
       &:focus,
       &:hover {
         color: $color-pink;
         text-decoration: none !important;
       }
    }
  }

  &-list {
    @include media(sm) {
      padding-top: 15px;
    }

    &-item {
      display: inline-block;
      width: 100%;
      height: 50px;
      padding-top: 10px;
      text-decoration: none;

      &:focus,
      &:hover {
				&-name {
						text-decoration: underline !important;
						color: $color-primary;
				}
      }

      &-image {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 65%;
        border: 1px solid $color-primary;
        overflow: hidden;

        img {
          @include cover-image;
        }
      }

      &-name {
        margin-top: 5px;
        @include font-size(11px);
        color: $color-primary;

        @include media(xs) {
          @include font-size(13px);
        }

        @include media(md) {
          margin-top: 10px;
        }

        @include media(xl) {
          @include font-size(14px);
        }
        &-overflow{
            display:inline-block;
            width:100%;
						height: 40px;
            overflow:hidden !important;
            text-overflow: ellipsis;
        }
      }
    }
  }
}

.load-more-products {
  display: none;
}

.contact-box {
  background-color: $color-blue-light-bg;
  padding: 10px;
  position: relative;

  @include media(md) {
    padding: 7px;
  }

  @include media(lg) {
    padding: 12px;
  }

	h2 {
			color: $color-primary;
			border-bottom: 1px solid $color-primary;
			padding: 0;
			margin-bottom: 10px;
	}

  &-text {
    @include font-size(12px);
		line-height: 18px;
    font-weight: 300;

    @include media(xs, sm) {
      @include font-size(14px);
    }

    @include media(md, md) {
      @include font-size(13px);
    }
		@include media(lg) {
			@include font-size(14px);
			line-height: 21px;
		}

    table {
      border-collapse: collapse;

      td {
        padding-right: 5px;

        a {
          color: $color-primary;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $color-primary;
            text-decoration: none;
          }
        }
      }
    }
  }

  .contact-box-button {
    @include calc("width", "100% - 2px");
		position: relative;
    bottom: 12px;
    right: 27px;
    width: auto;
    @include font-size (13px);
		color: $color-blue-light-bg !important;
		background-color: #819cb1 !important;
		@include animate("background-color, color, border-color");
		font-weight: 300 !important;
		border-color: #819cb1;

    @include media (xs) {
      right: 27px;
      min-width: 128px;
      @include font-size (14px);
    }

    @include media (md) {
      position: static;
    }

    @include media(lg) {
      min-width: 130px;
    }
		&:hover,
		&:focus {
				color: $color-white !important;
				background-color: $color-primary !important;
				border-color: $color-primary !important;
		}
  }
}

.events-tabs {
  .content-tabs-title-link {
    background-color: transparent;
    text-transform: none;

    @include media(xxs, xxs) {
      @include font-size(12px);
    }

    &:focus,
    &:hover {
      background-color: $color-primary;
    }

    &.active {
      background-color: $color-primary;
    }
  }

  .content-tabs-list-cont {
    padding-top: 10px;

    @include media(md) {
      padding-top: 25px;
    }
  }

  &-item {
    margin-bottom: 10px;
    @include flex-block;
    background-color: $color-primary;

    &-image {
      width: 40%;
      min-height: 95px;
      padding: 5px;
      text-align: center;

      @include media(xs) {
        min-height: 135px;
        padding: 15px 12.5px;
      }

      @include media(md) {
        padding: 25px 15.5px 25px 19.5px;
      }

      @include media(lg) {
        width: 27.5%;
        min-height: 168px;
      }

      img {
        @include vertical-pos(50%);
        max-width: 100%;
        max-height: 100%;
        margin: 0px;
      }
    }

    &-text {
      position: relative;
      width: 75%;
      padding: 5px 5px 35px 5px;
      @include font-size(13px);
      line-height: 15px;

      @include media(sm) {
        padding: 10px;
        padding-bottom: 50px;
        @include font-size(14px);
        line-height: 18px;
      }

      &-title {
        @include font-size(14px);
        font-weight: bold;
        line-height: 18px;

        @include media(xs) {
          @include font-size(16px);
          line-height: 25px;
        }

        @include media(lg) {
          @include font-size(18px);
          line-height: 35px;
        }
      }

      &-date {
        position: absolute;
        bottom: 5px;
        right: 5px;
        @include font-size(13px);
        font-weight: 300;

        @include media(xs) {
          bottom: 20px;
          right: 15px;
          @include font-size(14px);
        }

        @include media(lg) {
          @include font-size(18px);
        }
      }
    }
  }
}

.newsletter-box {
  background-color: $color-primary;
  padding: 9px 10px;
  margin-bottom: 25px;
  .ErrorLabel{
      display:none;
  }

  .EditingFormErrorLabel{
        padding:5px 10px;
        color: #000;
        background: #ffd100;
        @include font-size(14px);
        display:block;
        margin:0 0 15px 0;
  }

  @include media(lg) {
    padding: 9px 18px;
  }

  .form-horizontal {
    position: relative;
    @include calc('width', '100%');

    @include media(sm) {
        @include flex-inline;
    }
  }

  .form-group {
    margin: 0;
    width: 100%;

    @include media(md) {
      @include flex-inline;
      @include calc("width", "100% - 120px");
    }
  }

  .editing-form-label-cell {
    display: inline-block;
    width: 100%;
    @include font-size(14px);
    line-height: 24px;

    @include media(xs) {
      @include font-size(17px);
    }

    @include media(md) {
      width: 60%;
    }

    @include media(lg) {
      @include font-size(22px);
    }

    .control-label {
      margin: 0px;
      padding-bottom: 5px;

      @include media(sm) {
        padding-bottom: 0px;
        padding-top: 5px;
      }
    }
  }
  label{
      margin:3px 0;
      @include media(md) {
        margin-bottom: 0px;
      }
  }

  .editing-form-value-cell {
    display: inline-block;
    @include calc("width", "100% - 100px");

    @include media(xs) {
      @include calc("width", "100% - 120px");
    }

    @include media(sm) {
      width: 100%;
    }

    @include media(md) {
      width: 40%;
      padding-right: 5px;
    }

    .form-control {
      display: inline;
      @include border-radius(0);
      height: 32px;
      border: 1px solid $color-white;
      @include transition();
      outline: 0;

      &:focus {
        border-color: $color-black;
      }
    }
  }

  .form-group-submit {
    position: absolute;
    display: inline-block;
    width: 120px;
    text-align: right;
    bottom: 0;
    right: -100px;

    @include media(xs) {
      right: -120px;
    }


    @include media(sm) {
      position: static;
      width: 120px;
      padding-left: 10px;
      right: -120px;
    }

    input[type="submit"] {
      display: inline-block;
      width: 90px;
      border: 1px solid $color-black;
      color: $color-black;
      background-color: transparent;
      @include font-size(15px);
      line-height: 20px;
      font-weight: 300;
      padding: 5px 0;
      height: 32px;
      @include animate("background-color, color");
      cursor: pointer;
      outline: 0;
      @include border-radius(0);

      @include media(xs) {
        width: 110px;
      }

      @include media(md) {
        width: 100%;
      }

      @include media(md) {
        @include font-size(16px);
      }

      @include media(lg) {
        @include font-size(14px);
      }

      &:focus,
      &:hover {
        background-color: $color-black;
        color: $color-white;
      }
    }
  }

  .ErrorMessage {
    display: block;
    background-color: $color-primary;
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.locked-box {
  @include flex-block();
  @include align-items(center);
  @include justify-content(space-between);
  background-color: $color-blue-light-bg;
  padding: 5px 10px;
  margin-top: 45px;
  margin-bottom: 35px;

  @include media(lg) {
    padding: 10px 15px;
  }

  &-text {
    position: relative;
    padding-left: 30px;
    @include font-size(15px);
    line-height: 17px;

    @include media(xs) {
      @include font-size(17px);
      line-height: 19px;
    }

    @include media(sm) {
      @include font-size(19px);
      line-height: 21px;
    }

    @include media(md, md) {
      padding-left: 40px;
      @include font-size(17px);
      line-height: 19px;
    }

    @include media(xl) {
      @include font-size(22px);
      line-height: 25px;
    }

    &:before {
      @include icomoon;
      @include pseudo-elements("\e90a", absolute, 50%, -5px);
      @include font-size(20px);
      @include translate( 0, -50%);
      color:  $color-primary;

      @include media(sm) {
        @include font-size(22px);
      }

      @include media(xl) {
        @include font-size(25px);
      }
    }
  }

  &-submit {
    @include flex(0 0 90px);
    padding-left: 10px;

    @include media(sm) {
      @include flex-basis(110px);
    }

    @include media(lg) {
      @include flex-basis(160px);
    }

    .button-sign-up {
      width: 100%;
      min-height: 32px;
      @include font-size(14px);
      line-height: 16px;
      padding: 8px 5px;

    }
  }
}

.download-cont {
  padding-bottom: 30px;
}

.download-item {
  position: relative;
  @include flex-block();
  @include flex-direction(column);
  @include justify-content(center);
  width: 100%;
  min-height: 50px;
  background-color: $color-blue-light-bg;
  padding: 10px 10px 10px 45px;
  @include transition();
  color: $color-primary;
  text-decoration: none;
  margin-bottom: 10px;
  margin-top: 15px;

  @include media(xs, xs) {
    padding-left: 55px;
  }

  @include media(md) {
    padding-left: 55px;
  }

  &:hover,
  &:focus {
    background-color: $color-primary;
    color:  $color-white;
    text-decoration: none !important;
		&:before {
			color: $color-white;
		}
  }

  &:before {
    @include icomoon;
    @include pseudo-elements("\e90b", absolute, 25px, 7px);
    @include font-size(24px);
    @include translate( 0, -50%);
    color:  $color-primary;

    @include media(xs) {
      @include font-size(30px);
      left: 10px;
    }

    @include media(sm) {
      @include font-size(24px);
      left: 7px;
    }

    @include media(md) {
      @include font-size(30px);
      left: 10px;
    }
  }

  h3 {
    @include font-size(14px);
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 300;
    line-height: 18px;
		word-break: break-all;

    @include media(xs) {
      @include font-size(14px);
    }
  }
}

.download-small-cont {
  padding-bottom: 30px;
  margin-top: 20px;

  @include media(lg) {
    margin-top: 40px;
  }

  .product-card-title {
    margin-bottom: 5px;
  }

  .download-item {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

.subscribed {
  .locked-box {
    display: none;
  }
}

.unsubscribed {
  .download-item.locked {
    &:before {
      content: "\e90a";
    }
  }
}

.big-gallery-item {
  display: block;
  @include font-size(12px);
  line-height: 15px;
  margin-bottom: 10px;
  color: $color-black !important;

  @include media(sm) {
    @include font-size(14px);
    line-height: 18px;
  }

  &:hover,
  &:focus {
    color: $color-black !important;
    text-decoration: underline !important;
  }

  &-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0px;
    padding-bottom: 82%;
    margin-bottom: 5px;

    img {
      @include cover-image;
    }
  }
}

.logos-gallery-item {
  display: block;
  @include font-size(14px);
  line-height: 18px;
  margin-bottom: 20px;
  font-weight: 300;
  &-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;

    @include media(md) {
      margin-bottom: 25px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-links {
    font-weight: 400;

    a {
      color: $color-primary;
      text-decoration: underline;
      display: inline-block;
      margin-top: 10px;

      @include media(md) {
        margin-top: 0px;
      }

      &:focus,
      &:hover {
        color: $color-primary;
        text-decoration: none;
      }
    }
  }
}


.info-box {
  background-color: $color-primary;
  margin-top: 40px;
  @include font-size(16px);
  line-height: 20px;
  font-weight: 300;
  padding: 7px 15px;
  display: inline-block;

  @include media(md) {
    display: block;
  }

  @include media(lg) {
    @include font-size(18px);
    line-height: 23px;
  }
}

.hot-stuff-video {

 &-item {
    position: relative;
    display: block;
    padding: 10px 0;
    margin-top: 20px;
    @include animate("background-color, color");
    color: $color-black !important;
    text-decoration: none !important;

    @include media(xs) {
      @include flex-block;
      padding: 10px;
      margin: 0;
      border-bottom: 1px solid $color-black;
      min-height: 155px;
    }

    @include media(md) {
      min-height: 184px;
    }

    &:hover,
    &:focus {
      text-decoration: none !important;
      @include media(lg) {
        background-color: $color-primary;
      }

      .hot-stuff-video-item-content {
        &:before {
         background-color: $color-black;
        }

        &:after {
         color: $color-primary;
        }

        &-button {
          @include media(lg) {
            color: $color-primary !important;
            background: $color-black !important;
            text-decoration: none !important;
          }
        }
      }
    }

    &-img {
      width: 100%;
      position: relative;
      text-align: center;

      @include media(xs) {
        width: auto;
        @include flex(0 1 160px);
      }

      @include media(md) {
        @include flex(0 1 225px);
      }

      img {
        max-width: 100%;
        max-height: 100%;

        @include media(xs) {
          position: relative;
          top: 50%;
          @include translate(0, -50%);
        }
      }
    }

    &-content {
      position: relative;
      @include flex(1 1 auto);
      text-align: center;

      @include media(xs) {
        padding-left: 10px;
        text-align: left;
      }

      @include media(md) {
        padding-left: 20px;
      }

      &-title {
        @include font-size(15px);
        margin-top: 10px;
        border-bottom: 0px;
        text-decoration: none !important;

        @include media(xs) {
          margin-top: 0px;
          border-bottom: 2px solid $color-black;
        }

        @include media(sm) {
          @include font-size(18px);
          padding-bottom: 5px;
        }

        @include media(md) {
          @include font-size(20px);
        }

        @include media(xl) {
          @include font-size(22px);
        }
      }

      &-button {
        width: 130px;
        padding: 6px;
        margin-top: 5px;

        @include media(xs) {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &:before {
        content: "";
        position: absolute;
        display: none;
        bottom: 2px;
        left: 17px;
        width: 27px;
        height: 27px;
        @include border-radius(27px);
        background-color: transparent;
        @include transition(100ms);


        @include media(lg) {
          display: block;
        }
      }

      &:after {
        content: "\e91f";
        position: absolute;
        display: none;
        @include font-size(30px);
        @include transition(100ms);

        @include media(xs) {
          display: block;
          bottom: 0px;
          left: 5px;
        }

        @include media(md) {
          bottom: 0px;
          left: 15px;
        }

        @include media(sm, md) {
          color: $color-black !important;
        }
      }
    }
  }
}

.full-width {

 &-item {
    @include flex-block;
    margin: 0;
    border-bottom: 1px solid $color-primary;
    min-height: 91px;
    position: relative;
		color: $color-primary;
    @include animate("background-color, color");

    @include media(xs) {
      min-height: 155px;
    }

    @include media(md) {
      min-height: 184px;
    }
    &:hover,
    &:focus {
      .full-width-item-content {
				@include media(lg) {
					background-color: $color-blue-light-bg;
				}
        &:before {
         border-color: $color-primary;
        }

        &:after {
          @include media(lg) {
            color: $color-primary;
          }
        }
      }
    }

    &-img {
      width: auto;
			@include flex-block;
			@include justify-content(center);
			@include align-items(left);
      @include flex(0 0 100px);
      position: relative;

      @include media(xs) {
        @include flex(0 0 160px);
      }

      @include media(md) {
        @include flex(0 0 225px);
      }

      img {
        position: relative;
        max-width: 100%;
        max-height: 100%;

        @include media(xs) {
          top: 50%;
          @include translate(0, -50%);
        }
      }
    }

    &-content {
      position: relative;
      @include flex(1 1 auto);
			padding: 20px 10px 20px 10px;
      text-align: left;
			color: $color-primary;

      @include media(xs) {
        padding-right: 0px;
      }

      @include media(md) {
        padding-left: 20px;
				padding-right: 30px;
      }

      &-title {
        @include font-size(15px);
        border-bottom: 0;
				color: $color-primary;
				padding-right: 20px;
        @include media(xxs, xxs) {
          line-height: 19px;
          font-weight: 300;
          text-decoration: underline;
        }

        @include media(sm) {
          @include font-size(18px);
          padding-bottom: 5px;
        }

        @include media(md) {
          @include font-size(20px);
        }

        @include media(xl) {
          @include font-size(22px);
        }
      }

      &-perex {
        @include media(xs) {
          padding-bottom: 25px;
        }
      }

      &:before {
	        content: "";
	        position: absolute;
	        display: none;
	        bottom: 7px;
	        left: 13px;
	        width: 27px;
	        height: 27px;
	        @include border-radius(27px);
	        border: 1px solid transparent;
	        @include transition(100ms);

	        @include media(lg) {
	          display: block;
	        }
      }

      &:after {
					content: "\e91f";
	        position: absolute;
	        display: block;
	        top: 18px;
	        right: 0px;
	        @include font-size(25px);
	        @include transition(100ms);
	        @include media(xs) {
		          top: auto;
		          bottom: 5px;
		          left: 5px;
		          @include font-size(30px);
	        }
	        @include media(md) {
		          bottom: 5px;
		          left: 15px;
	        }
	        @include media(sm, md) {
		          color: $color-black !important;
	        }
      }
    }
  }
}

.embed-video.small {
    height: 420px;
}

.embed-video.small-16{
    height:320px;
}

.embed-video {
    height: 640px;
    position: relative;
}

.embed-video.large-16 {
    height: 540px;
}

.embed-video iframe {
    height: 100%;
    width: 100%;
    position:absolute;
    top:0;
    left:0;
}

.embed-video-overflow.autoplay-1,
.embed-video-overflow.played {
    opacity: 0;
    visibility: hidden;
    @include transition;
}

.embed-video-overflow {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #252525;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    &.in-popup{
        display:block;
    }
}

.embed-video-icon {
    display: block;
    height: 90px;
    width: 90px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    @include transition;
}

.embed-video-icon:before {
    content:"";
    display: inline-block;
    font-size: 32px;
    width: 0;
    height: 0;
    @include triangle-right(20px, 20px, 30px, #fff);
    @include transition;
    top:22px;
    left:35px;
    position:absolute;
}

.center-box {
		width: 100%;
		text-align: center;
}

.last-news {
		margin-top: 20px;
		color: $color-primary;
		@include media(md) {
				margin-top: 35px;
		}
		h2 {
				margin-bottom: 20px;
		}
		.litec-card {
				padding-bottom: 20px;
				&-item-title {
						text-align: left;
						padding-left: 15px;
						@include font-size(14px);
				}
		}
}

.apps-text {
			max-width: 320px;
	    margin-top: 50px;
	    @include font-size(16px);
}

.apps-cont {
		margin-top: 20px;
		&-text {
				@include font-size(14px);
		}
		.app-android,
		.app-apple {
				position: relative;
				display: inline-block;
				margin-right: 10px;
				font-size: 0;
				text-decoration: none !important;
				width: 60px;
				height: 60px;
				background-color: $color-secondary-light;
				border-radius: 50%;
				@include transition;
				&:before {
						display: inline-block;
						position: absolute;
						@include icomoon;
						@include font-size(30px);
						color: $color-primary;
						@include transition;
				}
				&:hover,
				&:focus {
						background-color: $color-primary;
						&:before {
								color: $color-secondary-light;
						}
				}
		}
		.app-android {
				&:before {
						content: "\e917";
						top: 25%;
						left: 31%;
			 }
		}
		.app-apple {
				&:before {
						content: "\e918";
						top: 23%;
						left: 25%;
			 }
		}
}

.page-404 {
		margin-top: 30px;
		@include media(md) {
				margin-top: 70px;
		}
		h2 {
				border: none !important;
				@include font-size(18px);
				margin: 0;
				padding: 10px 0;
				font-weight: 300 !important;
				@include media(md) {
						@include font-size(26px);
				}
		}
		@include font-size(16px);
		line-height: 20px;
		font-weight: 300;
		@include media(md) {
				@include font-size(18px);
				line-height: 24px;
		}

		.searchBox{
				position: relative;
				width:100%;
				max-width: 250px;
				height:auto;
				overflow:hidden;
				@include transition(300ms);
				margin: 30px 0;
				label{
						display:none;
				}
				input[type="text"]{
						@include border-radius(0px);
						width: 100%;
						height: 40px;
						padding: 0 35px 0 10px;
						border:none;
						outline:0;
						color: $color-primary;
						background: $color-blue-light-bg;
						font-weight: 300;
						@include box-shadow(none);
						&:focus{
								border:none;
								outline:0;
								@include box-shadow(none);
						}
				}
				.btn-search{
						position:absolute;
						right:5px;
						font-size:0;
						width:30px;
						text-align:center;
						top:5px;
						&:before{
								content:"\e909";
								@include font-size(25px);
								line-height:32px;
								color: $color-primary;
						}
				}

		}
}

.two-column {
		ul {
				@include media(lg){
						column-count: 2;
						column-gap: 50px;
						width: 400px !important;
				}
				li {
						@include media(lg) {
								display: block !important;
						}
				}
		}
}

.box-header-content {
		color: $color-primary;
		font-weight: 600;
		a {
				color: $color-primary;
				text-decoration: none;
				font-weight: 600;
		}
}


.filter {
    &-container {
        display: flex;
    }
    &-title {
        font-weight: 700;
        padding-right: 15px;
        display: inline-block;
        padding-top: 5px;
    }
    &-list {
        @include flex;
        @include flex-flow(row, wrap);
    }
    &-item {
        border: 1px solid $color-primary;
        padding: 5px 10px;
        @include transition;
        margin: 0 15px 15px 0;
        display: inline-block;
        font-weight: 700;
        cursor: pointer;
        &:hover,
        &:focus,
        &.active {
            background-color: $color-primary;
            color: $color-white;
        }
    }
}

.contact-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    @include media(md) {
        padding-top: 30px;
    }
    @include media(lg) {
        padding-top: 40px;
    }
    &-btn {
        display: inline-block;
        padding: 8px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary;
        text-align: center;
        @include media(md) {
            padding: 10px 50px;
        }
        @include media(lg) {
            padding: 12px 70px;
        }
        &-inner {
            position: relative;
            color: $color-white !important;
            font-size: 22px;
            line-height: 1;
            padding: 0 !important;
            font-weight: 300;
            margin: 0 0 10px !important;
            @include media(md) {
                font-size: 24px
            }
            @include media(lg) {
                font-size: 30px
            }
            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 2px;
                left: 50%;
                width: 150px;
                height: 1px;
                background-color: $color-white;
                transform: translate(-50%, 10px);
                @include transition;
                @include media(md) {
                    width: 200px;
                }
            }
            &:hover,
            &:focus {
                &:after {
                    width: 150px;
                }
            }
        }
    }
}

.afi-gallery {
    &-item {
        padding-left: 5px;
        padding-bottom: 5px;
        flex: 0 1 100%;
        max-width: 100%;
        @include media(xs) {
        	   flex: 0 1 50%;
             max-width: 50%;
        }
        @include media(lg) {
            flex: 0 1 33.333%;
            max-width: 33.333%;
        }
        &-inner {
            position: relative;
            overflow: hidden;
            display: block;
            &:before {
                content: "";
                display: block;
                padding-top: 80%;
            }
            img {
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translate(-50%, 0%);
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }
    &.large-space {
        .afi-gallery-item {
            padding-bottom: 40px;
        }
    }
}
