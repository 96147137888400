
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.pux-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @include transition;
        @include media(xxs) {
            max-width: 480px;
        }
        @include media(xs) {
            max-width: 576px;
        }
        @include media(sm) {
            max-width: 720px;
        }
        @include media(md) {
            max-width: 940px;
        }
        @include media(lg) {
            max-width: 1130px;
        }
        @include media(xl) {
            max-width: 1130px;
        }
    &:after{
        display: block;
        content: "";
        clear: both;
    }
}

.col-xs-five-grid {
  @include media(xs, xs) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-sm-five-grid {
  @include media(sm, sm) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-md-five-grid {
  @include media(md, md) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-lg-five-grid {
  @include media(lg, lg) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-xl-five-grid {
  @include media(xl, xl) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-lg-eleven-grid {
  @include media(lg) {
      @include flex(0 1 calc(100% / 11));
      //max-width: calc(100% / 11);
      flex-grow: 1
  }
  &:last-child{
    @include media(lg) {
        margin-right: -1px;
    }
  }
}

.flex-as-end{
    @include align-self(flex-end);
}

.flex-box{
    @include flex-block;
}

.jc-space{
    @include justify-content(space-between);
}

.ai-center{
    @include align-items(center);
}

.full-container{
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    background-color: inherit;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
}

.header-helper{
    height:50px;
    width:100%;
}

header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: $color-primary;
		color: $color-white;
    z-index: 9;
    width: 100%;
    max-width: 1280px;
    min-height:50px;
    left: 50%;
    @include translate(-50%, 0);
    .logo{
        padding:0 0px 10px 0;
				@include media(xs) {
						padding:0 13px 10px 0;
				}
        a{
            display:inline-block;
        }
        img{
						position: relative;
						height: 39px;
						top: -1px;
						margin-top: 0px;
						width: 98px;
						@include breakpointhelper(0, 350px) {
								height:29px;
								top: -5px;
								margin-top: 10px;
						}
						@include media(lg) {
								top: -5px;
								height:39px;
								margin: 0;
								width: 133px;
						}
        }
        span,
        h1{
            display:none;
        }
    }
    .main-menu{
        width:100%;
        position:absolute;
        top:50px;
        left:0;
        display:none;
        background:$color-primary;
        @include calc("max-height", "100vh - 50px");
        overflow:auto;
        & > ul{
            margin:0;
            padding:0;
            list-style:none;
            @include flex-block;
            @include flex-direction(column);
            & > li{
                border-bottom:1px solid $color-white;
                position:relative;
								@include media(lg) {
										border-bottom:1px solid $color-primary;
								}
                & > a{
                    cursor: pointer;
                    display: block;
                    @include font-size(17px);
										font-weight: 300;
                    line-height: 1;
                    position:relative;
                    padding: 18px 13px;
                    color: $color-white;
                    text-decoration: none;
                    & > span{
                        display:inline-block;
                        position:relative;
                        &:after{
                            display: block;
                            content: "";
                            height: 1px;
                            left:0;
                            top: 100%;
                            margin-top: 4px;
                            width: 100%;
                            background: $color-white;
                            @include opacity(0);
                            visibility: hidden;
                            @include animate("opacity, visibility");
                            position:absolute;
                        }
                    }
                    &:focus,
                    &:hover{
                        @include media(lg){
                            span{
                                &:after{
                                    @include opacity(1);
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    @include media(lg){
                        @include font-size(14px);
                    }
                    @include media(xl){
                        padding: 18px 22px;
                    }
                }
                &.Highlighted{
                    & > a{
                        span{
                            &:after{
                                @include opacity(1);
                                visibility: visible;
                            }
                        }
                    }
                }
                @include media(lg){
                    border:none;
                    position:static;
                }
                &:hover{
                    .submenu{
                        @include opacity(1);
                        visibility: visible;
                    }
                }
                .menu-mobile{
                    position:absolute;
                    right:0;
                    top:0;
                    width:65px;
                    height:53px;
                    text-align:center;
                    background:none;
                    &:before{
                        content:"";
                        width:26px;
                        height:2px;
                        background:$color-white;
                        display:block;
                        position:absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -1px;
                        margin-left: -13px;
                    }
                    &:after{
                        content:"";
                        width:2px;
                        height:26px;
                        background:$color-white;
                        display:block;
                        position:absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -13px;
                        margin-left: -1px;
                        @include opacity(1);
                        visibility:visible;
                        @include transition;
                    }
                    &.open{
                        &:after{
                            @include rotate(90deg);
                        }
                    }
                    @include media(lg){
                        display:none;
                    }
                }
             }
            @include media(lg){
               @include flex-direction(row);
               @include align-items(center);
            }
        }
        @include media(lg){
            position:relative;
            top:0;
            overflow:visible;
            display:block !important;
        }
    }
    .header{
        @include flex-block;
        @include align-items(center);
        @include justify-content(space-between);
        &-search{
            @include flex(0 0 auto);
            position:absolute;
            width:100%;
            background:$color-primary;
            padding:0 10px;
            left:0;
            top:50px;
            height:0;
            overflow:hidden;
            @include transition(300ms);
            @include media(lg){
                position:static;
                max-width:130px;
                overflow:visible;
                height:auto !important;
                padding:0;
            }
            label{
                display:none;
            }
            input[type="text"]{
                @include border-radius(0px);
                width:100%;
                height:32px;
                padding:0 35px 0 10px;
                border:none;
                outline:0;
								color: $color-primary;
								font-weight: 300;
                @include box-shadow(none);
                &:focus{
                    border:none;
                    outline:0;
                    @include box-shadow(none);
                }
            }
            .btn-search{
                position:absolute;
                top:10px;
                right:0;
                font-size:0;
                width:30px;
                text-align:center;
                &:before{
                    content:"\e909";
                    @include font-size(20px);
                    line-height:32px;
										color: $color-primary;
                }
								@include media(lg) {
										top:0px;
								}
            }
            /*.searchBox{
                position:relative;
                &:before{
                    content:"\e909";
                    @include font-size(20px);
                    line-height:32px;
                    position:absolute;
                    bottom:0;
                    right:5px;
                }
            }*/
            &-inner{
                position:relative;
                padding:10px 0 15px 0;
                @include media(lg){
                    padding:0;
                }
            }
        }
        &-login{
            position:absolute;
            background: $color-white;
            top:49px;
            right:0;
            width:100%;
            display:none;
            &-inner{
                position:relative;
                padding:10px;
								border: 1px solid $color-primary;
                input[type="password"],
                input[type="text"]{
                    width: 100%;
                    height: 40px;
                    border:none;
                    @include border-radius(0);
                    color: $color-primary;
                    @include transition;
										@include font-size(18px);
										border: 1px solid $color-primary;
										font-weight: 300;
                    &:focus{
                        color: $color-primary;
                        background: #f2f2f2;
                    }
										&::placeholder {
												color: $color-primary !important;
												font-weight: 300;
										}
										&::-webkit-input-placeholder {
												color: $color-primary !important;
												font-weight: 300;
										}
										&:-moz-placeholder {
												color: $color-primary !important;
												font-weight: 300;
										}
										&::-moz-placeholder {
												color: $color-primary !important;
												font-weight: 300;
										}
										&:-ms-input-placeholder {
												color: $color-primary !important;
												font-weight: 300;
										}
                }

            }
            @include media(lg){
                right:90px;
                width:360px;
            }
            .logon-remember-me-checkbox{
                display:none;
            }
            .logon-password-retrieval-link,
            .no-href{
                @include font-size(18px);
                color:$color-primary !important;
                @include transition;
                text-decoration:none !important;
								font-weight: 300;
								display: inline-block;
    						border-bottom: 1px solid $color-white;
                &:focus,
                &:hover{
                    color: $color-primary !important;
										border-bottom: 1px solid $color-primary;
                }
            }
            .login-btn{
                position:absolute;
                right:10px;
								top: 144px;
								input {
										background-color: $color-primary;
										color: $color-white;
										border-color: $color-primary;
										&:focus,
										&:hover {
												background-color: $color-white;
												color: $color-primary;
										}
								}
            }
            .logon-panel-password-retrieval{
                padding:10px 0 0 0;
            }
						.error-label,
						.form-control-error {
								color: $color-red;
								margin-bottom: 10px;
								display: block;
						}
						.logon-password-retrieval-result {
								color: $color-red;
								margin-top: 10px;
								display: block;
						}
        }
        &-nav{
            @include flex(0 0 auto);
            @include flex-block;
            @include align-items(center);
            a{
                width:32px;
                height:32px;
                text-align:center;
                display: block;
                color: $color-primary;
                text-decoration: none;
                font-weight:300;
                margin: 0 0 0 10px;
                @include media(lg){
                    width:auto;
                    height:auto;
                }
            }
            &-search{
                font-size:0;
                &:before{
                    content:"\e909";
                    @include font-size(20px);
                    line-height:32px;
										color: $color-white;
                    @include media(lg){
                        display:none;
                    }
                }
            }
            &-logout,
            &-login{
                margin: 0 2px;
                font-size:0;
                position: relative;
								color: $color-primary !important;
                @include animate("background-color, color");
								@include media(lg){
										color: $color-white !important;
										background: $color-primary !important;
										border: 1px solid $color-white;
								}
                &:focus,
                &:hover{
                    @include media(lg) {
                        color: $color-primary !important;
                        background: $color-white !important;
                    }
                }
                &:before{
                    content:"\e90f";
                    @include font-size(20px);
                    line-height:32px;
										color: $color-white !important;
                    @include media(lg){
                        display:none;
                    }
                }
                @include media(lg){
                    padding: 7px;
                    margin: 0 8px;
                    @include font-size(14px);
                    width: 84px !important;
                    text-align: center;
                    line-height:1;
                }
            }
            &-language{
                font-size:0;
                margin: 0 2px;
                &:after{
                    content:"\e905";
                    @include font-size(20px);
                    line-height:32px;
                    @include media(lg){
												display: none;
                    }
                }
                @include media(lg){
                    margin: 0 8px;
                    @include font-size(14px);
                    padding: 0px 0 0 20px;
                }
            }
						&-language-selector {
							display: inline-block;
							margin: 0 2px;
							position: relative;
							@include unselectable;
							@include media(lg) {
									margin: 0 0 0 30px;
							}
							.header-language-current {
									cursor: pointer;
									margin-left: 10px;
									@include media(lg) {
											margin-left: 0px;
									}
									&:after{
											content:"\e905";
											@include font-size(20px);
											line-height:32px;
											position: relative;
											top: 2px;
											@include media(lg){
													display: none;
											}
									}
							}
							span {
									display: inline-block;
									color: $color-white;
									@include font-size(14px);
									font-weight: 300;
									font-size: 0;
									@include media(lg) {
											@include font-size(14px);
									}
							}
							.header-language-inner {
									position: absolute;
									border: 1px solid $color-primary;
									display: none;
									min-width: 100px;
									background-color: $color-white;
									top: 40px;
									right: 0px;
									@include media(lg) {
											top: 34px;
									}
									ul, li {
											padding: 0;
											margin: 0;
											list-style: none;
											text-align: center;
									}
									a {
											display: inline-block;
											width: auto;
											height:auto;
											margin: 10px;
											border-bottom: 1px solid $color-white;
											&:focus,
											&:hover {
													border-bottom: 1px solid $color-primary;
											}

									}
							}

						}
            &-menu{
                position:relative;
                span{
                    width:26px;
                    position:absolute;
                    top:50%;
                    margin-top:-1px;
                    background:$color-white;
                    height:2px;
                    left:50%;
                    margin-left:-13px;
                    @include transition;
                    &:before,
                    &:after{
                        content:"";
                        width:26px;
                        height:2px;
                        position:absolute;
                        display:block;
                        background:$color-white;
                        @include transition;
                    }
                    &:before{
                        top:-8px;
                    }
                    &:after{
                        bottom:-8px;
                    }
                }
                @include media(lg){
                    display:none !important;
                }
                &.open{
                    span{
                        background:transparent;
                        &:before{
                            @include rotate(45deg);
                            top:0;
                        }
                        &:after{
                            @include rotate(-45deg);
                            top:0;
                        }

                    }
                }
            }
        }
        @include media(lg){
            @include justify-content(flex-start);
        }
    }
    .submenu {
        font-size: 0;
        white-space: nowrap;
        background: $color-white;
				border-top: none;
        z-index: 5;
        display:none;
        @include flex-direction(column);
        & > ul{
            display:block;
            width: 100%;
            min-width:187px;
            white-space: normal;
            @include font-size-with-line(14px);
            border-top:1px solid #000;
            margin: 0;
            padding: 0;
            li{
                display:block;
                border-bottom: 1px solid $color-primary;
                position: relative;
                padding: 0;
								@include media(lg){
										border-bottom: 1px solid $color-white;
                    padding: 0 22px;
								}
                a{
                    display: block;
                    padding: 9px 15px 9px 25px;
                    position: relative;
                    @include media(lg){
                        padding: 6px 0;
                    }
										span {
												display: inline-block;
												padding: 3px 0;
												font-weight:300;
												color: $color-primary;
												border-bottom: 1px solid $color-white;
												&:hover{
														color: $color-primary;
														border-bottom: 1px solid $color-primary;
												}
										}
                }
                &:hover,
                &:focus {
                      .megamenu ul {
                          opacity: 1;
                          visibility: visible;
                      }
                }
                .menu-mobile {
                    height: 43px;
                    border: 0;
                    &:before,
                    &:after {
                        background-color: $color-primary;
                    }
                }
                & > span{
                    display: block;
                    font-weight: 300;
                    padding: 9px 15px;
                    background:#000;
                    color:$color-primary;
                    border-bottom: 1px solid $color-base;
                    @include media(lg){
                        background:$color-blue-bg;
                        color:#000;
                        padding: 9px 0;
                    }
                }
                &:last-child{
                    border:none;
                    @include media(lg){
                        //border-bottom: 1px solid $color-base;
                    }
                }
                ul{
                    margin:0;
                    padding:0;
                    width:auto;
                    min-width:10px;
                }
            }
            @include media(lg){
                width: 187px;
                border:none;
                padding: 0;
            }
        }
        @include media(lg){
            position:absolute;
            @include flex-direction(row);
            padding: 10px 0px 15px;
            @include opacity(0);
            visibility: hidden;
            @include animate("opacity, visibility");
            @include flex-block;
						border: 1px solid $color-primary;
						border-top: 0px;
        }
    }

}


.EditMode,
DesginMode{
    header{
        position:static;
    }
}

.submenu-full{
    .submenu{
        left:0;
    }
}

main{
    @include calc("min-height", "100vh - 100px");
}

footer{
    max-width:1280px;
    margin:auto;
    background: $color-primary;
    color: $color-white;;
    margin-top: 75px;
    padding: 20px 0;
    @include font-size-with-line(14px);
    .nwl-title{
				margin-top: 20px;
        margin-bottom: 20px;
				@include media(md) {
						margin-top: 0;
				}
    }
    .nwl-box{
        position:relative;
        label{
            display:none;
        }
        .form-horizontal{
            padding:0 0 26px 0;
            @include flex-block;
            @include align-items(flex-start);
            @include flex-direction(column);
            @include media(md){
                @include flex-direction(row);
            }
            .form-group{
                width:100%;
                margin:0;
                @include media(md){
                    width:auto;
                }
            }
        }
        .ErrorLabel{
            display:none;
        }
        .EditingFormErrorLabel{
            left: 0;
						display: inline-block;
            margin-top: 0.3125rem;
            font-size: 0.75rem;
            line-height: 0.875rem;
            color: #ff5445;
						@include media(md) {
								position: absolute;
						}
        }
        input[type="text"]{
            width:100%;
            margin-bottom: 0;
            padding: 0 5px;
            height: 36px;
            line-height: 34px;
            font-size: 14px;
            color: $color-white;
            background: $color-primary;
            border: 1px solid $color-white;
            @include border-radius(0);
            @include animate(border-color);
						@include media(md){
								height: 26px;
								line-height: 26px;
						}

            &:focus{
                border-color: $color-blue-bg;
            }

            &:-moz-placeholder {
               color: #fff;
            }

            &::-moz-placeholder {
                color: #fff;
            }

            &::-webkit-input-placeholder {
                color: #fff;
            }

            &:-ms-input-placeholder {
               color: #fff;
            }
            @include media(md){
                width: 200px;
            }
        }
        input[type="submit"]{
            cursor:pointer;
            display: block;
            float: right;
            width: 100%;
            margin-left: 10px;
            font-size: 14px;
            line-height:1;
            padding:10px;
            height: 26px;
            outline: none;
            text-align: center;
            text-decoration: none;
						margin-top: 15px;

            color: $color-blue-bg;
            background: $color-primary;
            border: 1px solid $color-blue-bg;
            @include border-radius(0);
            font-weight:bold;
            @include animate("background, border-color, color");

            &:hover, &.active{
                border-color: $color-white;
                color: $color-white;
            }
            @include media(md){
								margin-top: 0;
                width: 85px;
								padding:5px;
            }
        }
    }
    .nwl-bottom{
        @include flex-block;
        @include flex-direction(column);
        .copyright{
            padding-bottom:10px;
            padding-top: 22px;
            @include font-size-with-line(10px);
            @include media(md){
                padding-right: 10px;
            }
            @include media(lg){
                padding-right: 85px;
                padding-bottom:0;
            }
        }
        @include media(md){
            @include align-items(center);
            @include flex-direction(row);
        }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li{
            display: inline-block;
            vertical-align: top;
            margin:0 5px 0 -5px;
            @include font-size-with-line(10px);
            a{
               display: block;
                padding:5px;
                color: $color-white;
                text-decoration: none;

                &:hover{
                    color: $color-white;
                    text-decoration: underline;
                }
            }
        }
    }
    &.footer-home{
        margin-top: 70px;
				@include media(md) {
						margin-top: 100px;
				}
    }
		&-logo {
				height: 39px;
		}
    .social{
        font-size:0;
        .ico-social{
            display:inline-block;
            //background:#fff;
            @include border-radius(50%);
            margin:5px;
            height:27px;
            width:27px;
            text-decoration:none !important;
            position:relative;
            &:before{
                color:#fff;
                @include animate(color);
                @include font-size(27px);
                position:absolute;
                top:0;
                left:0;
                color: $color-blue-bg;
            }
            &:focus,
            &:hover{
                &:before{
                    color:$color-white;
                }
            }
            &:first-child{
                margin:5px 5px 5px 0;
                @include media(md){
                    margin:5px;
                }
            }
            @each $name, $icon-number in $share-icon-names {
                &.s-#{$name} {
                    &:before {
                        content: "#{$icon-number}";
                    }
                }
            }
        }
    }
    .big-social {
        margin-bottom: 10px;
        & > div {
            text-align: center;
        }
        .ico-social {
            height: 35px;
            width: 35px;
            @include media(xxl) {
                height: 40px;
                width: 40px;
            }
            &:before {
                @include font-size(35px);
                @include media(xxl) {
                    @include font-size(40px);
                }
            }
        }
    }
}


.items-container {
  min-width: 100%;
  @include flex-block();
  @include flex-wrap(wrap);
}

.map-area-image{
    position:relative;
    .language-area-image{
        position:absolute;
        left:0;
        top:0;
        z-index:33;
    }
}

.modal-language-country{
    color:#fff !important;
    display:inline-block;
    &:focus,
    &:hover,
    &.hover{
        color:$color-primary !important;
    }
}

.quick-search{
		position: fixed;
		background: #e0e0e0;
		top: 106px;
		left: 0;
		width: 100%;
    background: $color-white;
    visibility:hidden;
    @include opacity(0);
    @include transition;
		border: 1px solid $color-primary;
		@include media(lg) {
			 position:absolute;
			 top:40px;
			 width:500px;
			 right:-170px;
			 left: auto;
	 	}
    &-inner{
        padding-bottom: 10px;
        min-height: 293px;
        @include opacity(1);
        @include transition;
    }
    .header-search-item{
        @include flex-block;
        @include align-items(center);
        color: $color-primary;
        text-decoration:none !important;
        border-bottom:1px solid $color-primary;
				@include transition;
        &-image{
            position:relative;
            width:130px;
            min-width:130px;
            height:90px;
            background:#fff;
            text-align:center;
						@include flex-block;
						@include justify-content(center);
						@include align-items(center);
						overflow: hidden;
        }
        &-name{
            font-weight:bold;
            padding:0 15px;
            width:100%;
            @include font-size(18px);
        }
        &-arrow{
            width:30px;
            height:30px;
            min-width:30px;
            background:transparent;
            color: $color-primary;
            margin-right:10px;
            text-align:center;
            @include border-radius(50%);
            @include transition;
            &:before{
                content:"\e91f";
                line-height:28px;
                @include font-size(24px);
                margin-left: -2px;
            }
        }
        &:focus,
        &:hover{
            background:$color-primary;
						color: $color-white;
            .header-search-item-arrow {
                color: $color-white;
            }
        }
    }
    &.open{
        visibility:visible;
        @include opacity(1);
    }
		.btn-search-more {
				color: $color-primary;
		}
}

.quick-search-btn{
    padding:10px 10px 20px 10px;
}

.loader {
	display: none;
  @include opacity(0);
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, $color-primary 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, $color-primary 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, $color-primary 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, $color-primary 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $color-primary 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
z-index: 33;
  left: 50%;
  margin-left: -60px;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: $color-primary;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: $color-white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.quick-search{
    &.opacity{
        .loader{
						display: inline-block;
            @include opacity(1);
        }
        .quick-search-inner{
            @include opacity(0);
						max-height: 293px;
						overflow: hidden;
						header {
							display: none;
						}
        }
    }
}


.load-more-products{
    width:100%;
    .inner-flex{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.order {
		&-top {
				@include order(1);
				@include media(md) {
						@include order(2);
				}
		}
		&-bottom {
				@include order(2);
				@include media(md) {
						@include order(1);
				}
		}
}

.card-toptext {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
      @include media(lg) {
          margin-bottom: 40px;
          height: calc(100% - 40px);
      }
      &-title {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary;
        flex: 1 0 auto;
        text-align: center;
        @include media(md) {
            padding: 15px 30px;
        }
        @include media(lg) {
            padding: 20px 40px;
        }
        &.static-title {
            flex: 0 0 auto;
        }
        h2 {
            position: relative;
            color: $color-white !important;
            font-size: 22px;
            line-height: 1.2;
            padding: 0 !important;
            margin: 0 0 13px !important;
            @include media(md) {
                font-size: 26px
            }
            @include media(lg) {
                font-size: 32px
            }
            a {
                color: $color-white !important;
                text-decoration: none;
            }
            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 200px;
                height: 1px;
                background-color: $color-white;
                transform: translate(-50%, 10px);
                @include transition;
            }
            &:hover:not(.static),
            &:focus:not(.static) {
                &:after {
                    width: 150px;
                }
            }
            &.bolder-line {
                &:after {
                    height: 2px;
                }
            }
        }
    }
    &-list {
        display: flex;
        overflow: hidden;
        &.grow-list {
            flex: 1 1 auto;
        }
    }
    &-content {
        position: relative;
        overflow: hidden;
        @include transition;
        display: block;
        width: 100%;
        &:before {
            content: "";
            display: block;
            padding-top: 60%;
        }
        img {
            @include cover-image();
        }
        &:hover,
        &:focus {
            &:not(.static) {
                transform: scale(1.075);
            }
        }
    }
    &.single-preview {
        &:hover,
        &:focus {
            .card-toptext-title h2:after {
                width: 150px;
            }
            .card-toptext-content {
                transform: scale(1.075);
            }
        }
    }
}

.hp-card-showcase {
    position: relative;
    overflow: hidden;
    @include transition;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-decoration: none;
    &-image {
        position: relative;
        overflow: hidden;
        padding-top: 60%;
        img {
            //@include cover-image();
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include transition;
        }
    }
    &-title {
      display: none;
        flex: 1 0 60px;
        @include font-size(20px);
        padding: 10px 20px;
        border-bottom: 1px solid $color-primary;
        @include media(md) {
            flex: 1 0 80px;
            padding: 15px 25px;
            @include font-size(22px);
        }
        @include media(xl) {
            flex: 1 0 100px;
            @include font-size(26px);
            padding: 20px 30px;
        }
    }
    &:hover,
    &:focus {
        .hp-card-showcase-image img {
            transform: scale(1.075);
        }
    }
}

.training-card {
    /*width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;*/
    position: relative;
    overflow: hidden;
    @include transition;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-decoration: none;
    &-image {
        /*position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;*/
        position: relative;
        padding-top: 60%;
        img {
            //@include cover-image();
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
            object-fit: cover;
            @include transition;
        }
    }
    &-content {
        position: relative;
        z-index: 8;
        padding: 30px;
        height: 100%;
        display: flex;
        width: 100%;
        display: none;
        &-inner {
            background-color: rgba($color-white, 0.7);
            display: flex;
            align-items: center;
            width: 100%;
            padding: 20px 50px;
            &-text {
                font-size: 26px;
                line-height: 1.2;
                color: $color-primary;
                @include media(md) {
                    font-size: 32px;
                }
                @include media(xl) {
                    font-size: 36px;
                }
            }
        }
    }
    &:hover,
    &:focus {
        .training-card-image img {
            transform: scale(1.075);
        }
    }
}

.show-more-center {
    .show-more-container {
        text-align: center;
    }
}

.space-20 {
    .row,
    .items-container {
        @include media(lg) {
            margin-left: -20px;
            margin-right: -20px;
        }
        div[class*=col-] {
            @include media(lg) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

.footer-social-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.megamenu {
    display: none;
    @include media(lg) {
        display: block;
    }
    ul {
        padding: 0;
        border-top: 1px solid $color-primary;
        @include media(lg) {
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(100%);
            border: 1px solid $color-primary;
            padding: 0 15px !important;
            background-color: $color-white;
            min-width: 100px;
            opacity: 0;
            visibility: hidden;
        }
        li {
            display:block;
            border-bottom: 1px solid $color-primary;
            @include media(lg){
                border-bottom: 1px solid $color-white;
                padding: 0 22px;
                position: relative;
            }
            a{
                display: block;
                padding: 9px 40px 9px 40px !important;
                position: relative;
                text-decoration: none;
                @include media(lg){
                    padding: 6px 0;
                }
                span {
                    display: inline-block;
                    padding: 3px 0;
                    font-weight:300;
                    color: $color-primary;
                    border-bottom: 1px solid $color-white;
                    &:hover{
                        color: $color-primary;
                        border-bottom: 1px solid $color-primary;
                    }
                }
            }
        }
    }
}

.training-container {
    margin-top: 50px;
    @include media(md) {
        margin-top: 90px;
    }
    @include media(xl) {
        margin-top: 100px;
    }
}


.training-card {
    &-text {
        padding: 20px 10px;
        font-size: 16px !important;
        line-height: 1.6 !important;
        margin-bottom: 20px !important;
    }
}
