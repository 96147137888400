
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss"; 
@import "_mixins/base.scss";

.product-preview {
  padding:0 0 30px 0;
  @include media(xs) {
    @include flex-block();
    @include flex-flow();
    @include justify-content(space-between);
  }

  &-image {
    width: 100%;

    @include media(xs) {
      width: 40%;
    }

    @include media(md) {
      width: 50%;
    }

    @include media(lg) {
      width: 50%;
    }

    .img-cont {
      position: relative;
      //border: 5px solid $color-blue-bg;
      height: 0;
      @include calc("padding-bottom", "100% - 10px");
      overflow: hidden;
			.box-product-social {
					position: absolute;
					top: 7px;
					left: 5px;
					.product-social-list {
						position: absolute;
						top: 40px;
						left: -5px;
						width: 60px;
						height: 200px;
						opacity: 0;
						background-color: transparent;
						overflow: hidden;
						@include transition;
						display: none;
    				@include flex-flow(column, wrap);
						@include media(xs) {
								top: 35px;
								width: 80px;
								height: 140px;
						}
						@include media(md) {
								width: 80px;
								height: 150px;
						}
						@include media(lg) {
								height: 200px;
						}
						@include media(xxl) {
								height: 300px;
						}
						&.open {
							@include transition;
							opacity: 1;
							@include flex-block;
						}
						&-icon {
							display: block;
							width: 30px;
							height: 30px;
							@include font-size(0px);
							text-decoration: none;
							position: relative;

							@include media(sm) {
								width: 37px;
								height: 37px;
							}

							&:before {
								@include icomoon();
								@include font-size(23px);
								position: absolute;
								top: 50%;
								left: 50%;
								@include translate(-50%, -50%);
								color: $color-primary;
								cursor: pointer;

								@include media(sm) {
									@include font-size(27px);
								}
							}

							&:after {
									content: "";
									position: absolute;
									top: 50%;
									left: 50%;
									@include translate(-50%, -50%);
									color: $color-primary;
									@include transition;
									cursor: pointer;
									width: 30px;
									height: 30px;
									border-radius: 50%;
									border: 1px solid transparent;
									@include media(sm) {
											width: 35px;
											height: 35px;
									}
							}

							&:focus,
							&:hover {
								&:after {
									@include transition;
									border-color: $color-primary;
								}
							}
						}

						@each $name, $icon-number in $share-icon-names {
							.s-#{$name} {
									&:before {
										content: "#{$icon-number}";
									}
								}
						}
					}

					.box-product-social-button {
							position: relative;
							display: inline-block;
							color: inherit;
							@include font-size(0px);
							width: 30px;
							height: 30px;
							color: $color-primary;
							cursor: pointer;

							&:before {
								@include icomoon();
								@include font-size(22px);
								@include transition(100ms);
								cursor: pointer;
								content: "\e904";
							}
							&:after {
									content: "";
									position: absolute;
									top: 38%;
									left: 42%;
									@include translate(-50%, -50%);
									color: $color-primary;
									cursor: pointer;
									width: 30px;
									height: 30px;
									border-radius: 50%;
									border: 1px solid transparent;
									@include media(sm) {
											width: 35px;
											height: 35px;
									}
							}
							&:focus,
							&:hover {
									&:after {
										@include transition;
										border-color: $color-primary;
									}
							}
							&.open {
									&:after {
										border-color: $color-primary;
									}
							}
						}

			}
			.img-wrap {
					&:not(:first-of-type) {
						display: none;
					}
			}
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &-contact {
    position: relative;
    @include flex-block();
    @include flex-flow(column);

    @include media(xs) {
      @include calc("width", "60% - 20px");
      margin-top: 0px;
    }

    @include media(xs) {
      display: block;
    }

    @include media(md) {
      @include calc("width", "50% - 20px");
    }

    @include media(lg) {
      @include calc("width", "50% - 35px");
    }

    &-perex {
      order: 2;
      line-height: 18px;
      margin-top: 20px;

      @include media(xs) {
        margin-top: 0px;
				margin-bottom: 30px;
      }

    }

    &-types {
      order: 3;
      margin-top: 8px;

      @include flex-block();
      @include flex-flow();
      @include flex-wrap(wrap);
      @include justify-content(space-between);

      @include media(xs) {
        border-bottom: 0;
      }
      .product-line{
          width:100%;
          height:1px;
          background: $color-primary;
      }
      &-format,
      &-load {
        width: 45%;
        padding: 10px 0 14px;

        @include media(xs) {
          width: 48%;
          padding: 13px 0 7px;
        }

        @include media(xl) {
          width: 53%;
          padding: 16px 0 12px;
        }

        &-title {
          @include font-size(15px);
          font-weight: 300;

          @include media(sm, sm) {
            @include font-size(18px);
          }

          @include media(lg) {
            @include font-size(18px);
          }

        }
        a {
          display: inline-block;
          color: $color-black;
          text-decoration: none;
          padding: 2px 0;
          margin-top: 2px;
          @include transition(100ms);
          border-bottom: 1px solid transparent;
          @include font-size(13px);

          @include media(sm, sm) {
            @include font-size(15px);
          }

          @include media(lg) {
            @include font-size(15px);
          }

          @include media(xl) {
            @include font-size(17px);
            margin-top: 5px;
          }

          &:not(:last-of-type) {
            margin-right: 8px;

            @include media(lg) {
              margin-right: 15px;
            }

            @include media(xl) {
              margin-right: 18px;
            }
          }

          &:hover,
          &:focus,
          &.active {
            border-bottom: 1px solid $color-black;
            text-decoration: none !important;
          }
        }
      }


      &-load {
        @include media(xl) {
          width: 47%;
        }
      }


    }

    &-colors {
      order: 0;
      position: relative;
      @include flex-block();
      @include flex-flow();
      padding: 0 2px;
      margin: 12px 0;
      margin-top: 20px;

      @include media(lg) {
        padding: 14px 0;
      }

      @include media(xl) {
        padding: 8px 5px;
      }

      &:before {
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-primary;
        z-index: 1;

        @include media(sm) {
          @include pseudo-elements("", absolute, -12px, 0px);
        }

        @include media(lg) {
          top: -10px;
        }
      }

      &-item {
        width: 31px;
        height: 31px;
        border-radius: 31px;
        cursor: pointer;

        &:not(:first-of-type) {
          margin-left: 15px;
        }

        @include media(xl) {
          width: 39px;
          height: 39px;

          &:not(:first-of-type) {
            margin-left: 22px;
          }
        }
      }
    }

    .product-contact {
      .contact-box-button {
        @include calc("width", "100% - 2px");
				position: relative;
        right: 0px;
				top: 0px;
        width: auto;
        @include font-size (13px);

        @include media (xs) {
          position: static;
          width: auto;
          min-width: 128px;
          @include font-size (14px);
        }

        @include media(lg) {
          min-width: 123px;
          position: absolute;
					top: auto;
					right: 10px;
        }

        @include breakpointhelper (992px, 1024px) {
          min-width: 100px;
          width: 110px;
        }

        @include media(xl) {
          min-width: 140px;
          position: absolute;
        }
      }
    }
  }
}


@keyframes loading-animate {
  100% {
    transform: rotate(360deg);
    @include transform-origin(center center);
  }
}

.loading-animation {

  &:after {
    @include icomoon();
    @include font-size(35px);
    position: absolute;
    content: "\e922";
    color: gray;
    top: 47%;
    left: 48%;
    animation: loading-animate 1s linear infinite;
    z-index: 200;

    @include media(xxs, md) {
      top: 43%;
      left: 48%;
    }
  }
}


.product-black {
  background-color: $color-black;
}

.product-grey{
  background-color: #919191;
}

.product-card {
  line-height: 18px;

  &-title {
    margin-bottom: 0px;
  }

  ul {
    list-style-type: none;
    padding: 0px;

    li {
      position: relative;
      padding-left: 7px;
      padding-bottom: 10px;
      line-height: 18px;

      &:before {
        @include pseudo-elements("", absolute, 7px, -1px);
        display: block;
        width: 4px;
        height: 4px;
        background-color: $color-black;
        border-radius: 4px;
      }
    }
  }
}

.product-technical-info {
  padding: 10px 0;
	line-height: 20px;
	@include media (sm) {
		padding: 0;
	}
}

.product-basic-info,
.product-technical-info  {
		@include font-size(14px);
		h2 {
				margin-bottom: 10px;
		}
		h3,
		h4 {
				@include font-size(18px);
				line-height: 21px;
				font-weight: 300;
				margin: 0px 0 15px !important;
				@include media(sm) {
						margin: 10px 0 30px !important;
				}
		}
		.col-sm-6:first-of-type {
				margin-bottom: 20px;
				padding-top: 10px;
				padding-bottom: 10px;
				@include media(sm) {
						margin-bottom: 0px;
						padding-top: 0px;
						padding-bottom: 0px;
				}
		}
		.download-item {
				h3 {
						padding: 0 !important;
						margin:0 !important;
				}
		}
}

.product-basic-info {
		margin-top: 20px;
		@include media(sm) {
				margin-top: 30px;
		}
		ul {
				@include list-reset;
				li {
						position: relative;
						padding-left: 20px;
						padding-bottom: 15px;
						&:before {
								position: absolute;
								content: ">";
								margin-right: 5px;
								left: 5px;
								top: 2px;
						}
				}
		}
}

.product-table {
  table{
      width: 100%;
      display: block;
      overflow-x: auto;
      margin-top: 15px;
      padding-right: 1px;
      padding-bottom: 5px;

  @include media(sm) {
    display: table;
    overflow-x: hidden;
  }

  tr:first-child {
   background-color: #ffd100;
   font-weight: 300;
  }

  tr:not(:first-of-type) td:first-child:not([colspan]) {
   font-weight: 300;
   border-right: 1px solid #000;
   padding: 2px 3px;
  }

  &-title td {
   border-right: none!important;
  }

 td {
   border: none;
   border-right: 1px solid #dedede;
   border-bottom: 1px solid #000;
  }

  tr td {
   padding: 2px 10px;
   border: 1px solid #dedede;

   @include media(sm) {
     padding: 2px 3px;
   }
  }
}
}

.basic-table {
  table{
      width: 100%;
      display: block;
      overflow-x: auto;
      margin-top: 15px;
      padding-right: 1px;
      padding-bottom: 5px;

			@include media(sm) {
				display: table;
				overflow-x: hidden;
			}

			tr:first-of-type {
				font-weight: bold;
				background-color: #ffd100;
				text-align: center;
			}

			th,
			tr:nth-of-type(2) {
				font-weight: bold;
			}

		  tr td {
				padding: 3px 5px;
				line-height: 18px;
			}

			tr {
				border-bottom: 1px solid #dedede;
			}
	}
}

.info-table {
		table {
				width: 100%;
				display: block;
				overflow-x: auto;
				margin-top: 15px;
				padding-right: 1px;
				padding-bottom: 5px;
				@include font-size(11px);

				@include media(sm) {
					display: table;
					overflow-x: hidden;
				}

				tr {
					border-bottom: 1px solid #000;
					background-color: #f5f4f4;
					vertical-align: middle;
					padding: 3px 1px 3px 2px !important;
				}

				tr td {
					vertical-align: middle;
					padding: 3px 1px 3px 2px !important;
					border: 0 !important;
				}

				 th {
					background-color: #ffd100;
					font-weight: 500;
					color: #010101;
					height: 25px;
					@include font-size(15px);
					text-align: right;
					padding: 2px 5px 0 0;
					vertical-align: middle;
				}

				tr:not(:first-of-type) td:first-child:not([colspan]) {
					font-weight: 400;
				}

				tr:first-of-type  {
					border-bottom: 1px solid #fff !important;
				}

				.gray-row {
					td {
						border: none !important;
						border-left: 1px solid #fff !important;
						border-top: 1px solid #fff !important;
					}
				}
	}

}

.gray-cell {
	background-color: #e0e0e0!important;
	color: #010101;
	text-align: center;
	width: 90px;
}

.gray-row {
	background-color: #e0e0e0!important;
	color: #010101;
	text-align: left;
	background-color: #ffd100;
	font-weight: 500;
	color: #010101;
	height: 25px;
	padding: 2px 5px 0 0;
	vertical-align: middle;

	td {
		border: none !important;
		border-left: 1px solid #fff !important;

		&:first-of-type {
			text-align: center;
			width: 90px;
		}
	}
}



.text-info-text{
    padding:0 0 20px 0;
}

/*.tech-info{
    padding: 5px 0 25px;
    @include flex-block;
    @include flex-wrap(wrap);
    margin:0px -15px 0 -15px;
    &-item{
        padding:0 15px;
        width:50%;
    }
    dl{
        @include flex-block();
        @include flex-flow();
        margin-bottom: 5px;
        border-bottom: 1px solid $color-black;
        dt,
        dd{
            width: 50%;
            background-color: $color-blue-bg;
            padding: 3px 5px;
            @include font-size(13px);

            @include media(md) {
              padding: 3px 10px;
              @include font-size(14px);
            }
        }
        dd{
            background-color: $color-white;
            text-align: right;
            line-height: 18px;
        }
    }
}*/

.tech-info{
		padding: 5px 0 25px;
		font-size: 0;
		column-count: 1;
    -webkit-column-count:1;
    -moz-column-count: 1;
    column-gap:22px;
    -webkit-column-gap:22px;
    -moz-column-gap:22px;

    dl{
        display: table;
        width: 100%;
        table-layout: fixed;
        margin-bottom: 5px;
        break-inside: avoid-column;
        -webkit-column-break-inside: avoid;
        border-bottom: 1px solid $color-black;
    }

	dt{
		background: $color-blue-bg;
	}

	dt,dd{
    display: table-cell;
    vertical-align: top;
    padding: 4px 10px;
    width: 50%;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
		font-weight: 400;
	}

	dd{
		text-align: right;
		padding-right: 0;
	}

	.no-columns &{
        column-count: 1;
        -webkit-column-count:1;
        -moz-column-count:1;
        column-gap:0;
        -webkit-column-gap:0;
        -moz-column-gap:0;
    }

   	@include media(sm){
			column-count: 2;
			-webkit-column-count:2;
			-moz-column-count: 2;
    	dt,dd{
    		font-size: 13px;
    	}
    }
    @include media(md){
    		column-count: 1;
        -webkit-column-count:1;
        -moz-column-count: 1;

        dt,dd{
        	font-size: 14px;
        }
    }
		@include media(lg){
			column-count: 2;
			-webkit-column-count:2;
			-moz-column-count: 2;
    	dt,dd{
    		font-size: 13px;
    	}
    }
		@include media(xl){
    	column-gap:14px;
   		-webkit-column-gap:14px;
  		-moz-column-gap:14px;

			dt,dd{
				padding: 4px 5px;
			}

			dt{
				width: 43%;
			}

			dd{
				width: 57%;
			}
    }
}

.row{
    .tech-info{
        &-item{
            width:100%;
            @include media(sm){
                width:50%;
            }
            @include media(md){
                width:100%;
            }
            @include media(lg){
                width:50%;
            }
        }
    }
}

.tech-contact{
    .contact-box {
        .contact-box-button{
            position:static;
            @include media(lg){
                right:10px;
                position:absolute;
                min-width: 123px;
            }
            @include media(xl){
                min-width: 155px;
            }
        }
    }
}

.product-video {
		&-holder {
				position: relative;
				margin-bottom: 20px;
				margin-top: 15px;
				@include media(md) {
						margin-top: 0;
						margin-bottom: 30px;
				}
				&:before {
						content: "";
						display: block;
						padding-bottom: 56.3%;
				}
				iframe {
						position: absolute;
						display: block;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
				}
		}
}
