@charset "UTF-8";
@import url(../fonts/icofont/style.css);
.pt-10 {
  padding-top: 10px; }

.pt-20 {
  padding-top: 20px; }

.pt-30 {
  padding-top: 30px; }

.pt-40 {
  padding-top: 40px; }

.pt-50 {
  padding-top: 50px; }

.pt-60 {
  padding-top: 60px; }

.pt-70 {
  padding-top: 70px; }

.pt-80 {
  padding-top: 80px; }

.pt-90 {
  padding-top: 90px; }

.pt-100 {
  padding-top: 100px; }

.pb-10 {
  padding-bottom: 10px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-100 {
  padding-bottom: 100px; }

.mt-10 {
  margin-top: 10px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-40 {
  margin-top: 40px; }

.mt-50 {
  margin-top: 50px; }

.mt-60 {
  margin-top: 60px; }

.mt-70 {
  margin-top: 70px; }

.mt-80 {
  margin-top: 80px; }

.mt-90 {
  margin-top: 90px; }

.mt-100 {
  margin-top: 100px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-100 {
  margin-bottom: 100px; }

.line {
  width: 100%;
  margin: 20px 0;
  height: 2px;
  background: #000; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 15px; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #043a63;
  border-radius: 0px;
  background: #e8edf1;
  position: relative;
  overflow: hidden; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 36px 0 10px;
    font-size: 15px;
    line-height: 36px;
    color: #043a63;
    height: 36px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background-color: #e8edf1;
    color: #e8edf1;
    text-align: center;
    font: 0/0 a;
    *font: 20px/36px Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms; }
    .selectric .button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      content: "\e91f";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #043a63; }

.selectric-focus .selectric {
  border-color: #043a63;
  color: #e8edf1; }

.selectric-hover .selectric {
  border-color: #043a63;
  color: #e8edf1; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #043a63;
    border-bottom: 0px; }
    .selectric-open .selectric .button {
      color: #c8d4de;
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #e8edf1;
  border: 1px solid #043a63;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: #043a63;
    background: #e8edf1;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li.selected {
      background-color: #043a63;
      color: #e8edf1; }
    .selectric-items li.highlighted {
      background-color: #043a63;
      color: #e8edf1; }
    .selectric-items li:hover {
      background-color: #043a63;
      color: #e8edf1; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

.hot-stuff-header-select .selectric-wrapper {
  width: 100%;
  margin-bottom: 0 !important; }

.hot-stuff-header-select .selectric {
  width: 100%;
  background: #e8edf1;
  font-size: 13px; }
  .hot-stuff-header-select .selectric .label {
    color: #000 !important;
    font-weight: 800; }
  .hot-stuff-header-select .selectric .button {
    background: transparent !important; }
    .hot-stuff-header-select .selectric .button:after {
      color: #000; }

.hot-stuff-header-select .selectric-open .selectric {
  height: 38px; }

.hot-stuff-header-select li {
  background: #e8edf1;
  color: #000 !important;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px; }
  .hot-stuff-header-select li.selected {
    color: #e8edf1; }
  .hot-stuff-header-select li.highlighted {
    color: #e8edf1; }
  .hot-stuff-header-select li:hover {
    background: #e8edf1;
    color: #000;
    text-decoration: underline; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 320px) {
    .pux-container {
      max-width: 480px; } }
  @media (min-width: 480px) {
    .pux-container {
      max-width: 576px; } }
  @media (min-width: 545px) {
    .pux-container {
      max-width: 720px; } }
  @media (min-width: 768px) {
    .pux-container {
      max-width: 940px; } }
  @media (min-width: 992px) {
    .pux-container {
      max-width: 1130px; } }
  @media (min-width: 1200px) {
    .pux-container {
      max-width: 1130px; } }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }

@media (min-width: 480px) and (max-width: 544px) {
  .col-xs-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 545px) and (max-width: 767px) {
  .col-sm-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .col-md-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .col-lg-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 1200px) and (max-width: 1399px) {
  .col-xl-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 992px) {
  .col-lg-eleven-grid {
    -webkit-flex: 0 1 calc(100% / 11);
    -moz-flex: 0 1 calc(100% / 11);
    -ms-flex: 0 1 calc(100% / 11);
    -webkit-flex: 0 1 calc(100% / 11);
    flex: 0 1 calc(100% / 11);
    flex-grow: 1; } }

@media (min-width: 992px) {
  .col-lg-eleven-grid:last-child {
    margin-right: -1px; } }

.flex-as-end {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end; }

.flex-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.jc-space {
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }

.ai-center {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.full-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  background-color: inherit; }
  .full-container:after {
    display: block;
    content: "";
    clear: both; }

.header-helper {
  height: 50px;
  width: 100%; }

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #043a63;
  color: #fff;
  z-index: 9;
  width: 100%;
  max-width: 1280px;
  min-height: 50px;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }
  header .logo {
    padding: 0 0px 10px 0; }
    @media (min-width: 480px) {
      header .logo {
        padding: 0 13px 10px 0; } }
    header .logo a {
      display: inline-block; }
    header .logo img {
      position: relative;
      height: 39px;
      top: -1px;
      margin-top: 0px;
      width: 98px; }
      @media (max-width: 350px) {
        header .logo img {
          height: 29px;
          top: -5px;
          margin-top: 10px; } }
      @media (min-width: 992px) {
        header .logo img {
          top: -5px;
          height: 39px;
          margin: 0;
          width: 133px; } }
    header .logo span,
    header .logo h1 {
      display: none; }
  header .main-menu {
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    background: #043a63;
    max-height: -webkit-calc(100vh - 50px);
    max-height: -moz-calc(100vh - 50px);
    max-height: calc(100vh - 50px);
    overflow: auto; }
    header .main-menu > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      header .main-menu > ul > li {
        border-bottom: 1px solid #fff;
        position: relative; }
        @media (min-width: 992px) {
          header .main-menu > ul > li {
            border-bottom: 1px solid #043a63; } }
        header .main-menu > ul > li > a {
          cursor: pointer;
          display: block;
          font-size: 17px;
          font-size: 1.0625rem;
          font-weight: 300;
          line-height: 1;
          position: relative;
          padding: 18px 13px;
          color: #fff;
          text-decoration: none; }
          header .main-menu > ul > li > a > span {
            display: inline-block;
            position: relative; }
            header .main-menu > ul > li > a > span:after {
              display: block;
              content: "";
              height: 1px;
              left: 0;
              top: 100%;
              margin-top: 4px;
              width: 100%;
              background: #fff;
              opacity: 0;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              filter: alpha(opacity=0);
              visibility: hidden;
              -webkit-transition-property: opacity, visibility;
              -moz-transition-property: opacity, visibility;
              -o-transition-property: opacity, visibility;
              transition-property: opacity, visibility;
              -webkit-transition-duration: 250ms;
              -moz-transition-duration: 250ms;
              -o-transition-duration: 250ms;
              transition-duration: 250ms;
              -webkit-transition-timing-function: ease;
              -moz-transition-timing-function: ease;
              -o-transition-timing-function: ease;
              transition-timing-function: ease;
              position: absolute; }
          @media (min-width: 992px) {
            header .main-menu > ul > li > a:focus span:after, header .main-menu > ul > li > a:hover span:after {
              opacity: 1;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
              filter: alpha(opacity=100);
              visibility: visible; } }
          @media (min-width: 992px) {
            header .main-menu > ul > li > a {
              font-size: 14px;
              font-size: 0.875rem; } }
          @media (min-width: 1200px) {
            header .main-menu > ul > li > a {
              padding: 18px 22px; } }
        header .main-menu > ul > li.Highlighted > a span:after {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          visibility: visible; }
        @media (min-width: 992px) {
          header .main-menu > ul > li {
            border: none;
            position: static; } }
        header .main-menu > ul > li:hover .submenu {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          visibility: visible; }
        header .main-menu > ul > li .menu-mobile {
          position: absolute;
          right: 0;
          top: 0;
          width: 65px;
          height: 53px;
          text-align: center;
          background: none; }
          header .main-menu > ul > li .menu-mobile:before {
            content: "";
            width: 26px;
            height: 2px;
            background: #fff;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -1px;
            margin-left: -13px; }
          header .main-menu > ul > li .menu-mobile:after {
            content: "";
            width: 2px;
            height: 26px;
            background: #fff;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -13px;
            margin-left: -1px;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .main-menu > ul > li .menu-mobile.open:after {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
          @media (min-width: 992px) {
            header .main-menu > ul > li .menu-mobile {
              display: none; } }
      @media (min-width: 992px) {
        header .main-menu > ul {
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center; } }
    @media (min-width: 992px) {
      header .main-menu {
        position: relative;
        top: 0;
        overflow: visible;
        display: block !important; } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    header .header-search {
      -webkit-flex: 0 0 auto;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      position: absolute;
      width: 100%;
      background: #043a63;
      padding: 0 10px;
      left: 0;
      top: 50px;
      height: 0;
      overflow: hidden;
      -moz-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      -webkit-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      /*.searchBox{
                position:relative;
                &:before{
                    content:"\e909";
                    @include font-size(20px);
                    line-height:32px;
                    position:absolute;
                    bottom:0;
                    right:5px;
                }
            }*/ }
      @media (min-width: 992px) {
        header .header-search {
          position: static;
          max-width: 130px;
          overflow: visible;
          height: auto !important;
          padding: 0; } }
      header .header-search label {
        display: none; }
      header .header-search input[type="text"] {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        border-radius: 0px;
        width: 100%;
        height: 32px;
        padding: 0 35px 0 10px;
        border: none;
        outline: 0;
        color: #043a63;
        font-weight: 300;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; }
        header .header-search input[type="text"]:focus {
          border: none;
          outline: 0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none; }
      header .header-search .btn-search {
        position: absolute;
        top: 10px;
        right: 0;
        font-size: 0;
        width: 30px;
        text-align: center; }
        header .header-search .btn-search:before {
          content: "\e909";
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px;
          color: #043a63; }
        @media (min-width: 992px) {
          header .header-search .btn-search {
            top: 0px; } }
      header .header-search-inner {
        position: relative;
        padding: 10px 0 15px 0; }
        @media (min-width: 992px) {
          header .header-search-inner {
            padding: 0; } }
    header .header-login {
      position: absolute;
      background: #fff;
      top: 49px;
      right: 0;
      width: 100%;
      display: none; }
      header .header-login-inner {
        position: relative;
        padding: 10px;
        border: 1px solid #043a63; }
        header .header-login-inner input[type="password"],
        header .header-login-inner input[type="text"] {
          width: 100%;
          height: 40px;
          border: none;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          border-radius: 0;
          color: #043a63;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          font-size: 18px;
          font-size: 1.125rem;
          border: 1px solid #043a63;
          font-weight: 300; }
          header .header-login-inner input[type="password"]:focus,
          header .header-login-inner input[type="text"]:focus {
            color: #043a63;
            background: #f2f2f2; }
          header .header-login-inner input[type="password"]::placeholder,
          header .header-login-inner input[type="text"]::placeholder {
            color: #043a63 !important;
            font-weight: 300; }
          header .header-login-inner input[type="password"]::-webkit-input-placeholder,
          header .header-login-inner input[type="text"]::-webkit-input-placeholder {
            color: #043a63 !important;
            font-weight: 300; }
          header .header-login-inner input[type="password"]:-moz-placeholder,
          header .header-login-inner input[type="text"]:-moz-placeholder {
            color: #043a63 !important;
            font-weight: 300; }
          header .header-login-inner input[type="password"]::-moz-placeholder,
          header .header-login-inner input[type="text"]::-moz-placeholder {
            color: #043a63 !important;
            font-weight: 300; }
          header .header-login-inner input[type="password"]:-ms-input-placeholder,
          header .header-login-inner input[type="text"]:-ms-input-placeholder {
            color: #043a63 !important;
            font-weight: 300; }
      @media (min-width: 992px) {
        header .header-login {
          right: 90px;
          width: 360px; } }
      header .header-login .logon-remember-me-checkbox {
        display: none; }
      header .header-login .logon-password-retrieval-link,
      header .header-login .no-href {
        font-size: 18px;
        font-size: 1.125rem;
        color: #043a63 !important;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        text-decoration: none !important;
        font-weight: 300;
        display: inline-block;
        border-bottom: 1px solid #fff; }
        header .header-login .logon-password-retrieval-link:focus, header .header-login .logon-password-retrieval-link:hover,
        header .header-login .no-href:focus,
        header .header-login .no-href:hover {
          color: #043a63 !important;
          border-bottom: 1px solid #043a63; }
      header .header-login .login-btn {
        position: absolute;
        right: 10px;
        top: 144px; }
        header .header-login .login-btn input {
          background-color: #043a63;
          color: #fff;
          border-color: #043a63; }
          header .header-login .login-btn input:focus, header .header-login .login-btn input:hover {
            background-color: #fff;
            color: #043a63; }
      header .header-login .logon-panel-password-retrieval {
        padding: 10px 0 0 0; }
      header .header-login .error-label,
      header .header-login .form-control-error {
        color: #e20b33;
        margin-bottom: 10px;
        display: block; }
      header .header-login .logon-password-retrieval-result {
        color: #e20b33;
        margin-top: 10px;
        display: block; }
    header .header-nav {
      -webkit-flex: 0 0 auto;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      header .header-nav a {
        width: 32px;
        height: 32px;
        text-align: center;
        display: block;
        color: #043a63;
        text-decoration: none;
        font-weight: 300;
        margin: 0 0 0 10px; }
        @media (min-width: 992px) {
          header .header-nav a {
            width: auto;
            height: auto; } }
      header .header-nav-search {
        font-size: 0; }
        header .header-nav-search:before {
          content: "\e909";
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px;
          color: #fff; }
          @media (min-width: 992px) {
            header .header-nav-search:before {
              display: none; } }
      header .header-nav-logout, header .header-nav-login {
        margin: 0 2px;
        font-size: 0;
        position: relative;
        color: #043a63 !important;
        -webkit-transition-property: background-color, color;
        -moz-transition-property: background-color, color;
        -o-transition-property: background-color, color;
        transition-property: background-color, color;
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease; }
        @media (min-width: 992px) {
          header .header-nav-logout, header .header-nav-login {
            color: #fff !important;
            background: #043a63 !important;
            border: 1px solid #fff; } }
        @media (min-width: 992px) {
          header .header-nav-logout:focus, header .header-nav-logout:hover, header .header-nav-login:focus, header .header-nav-login:hover {
            color: #043a63 !important;
            background: #fff !important; } }
        header .header-nav-logout:before, header .header-nav-login:before {
          content: "\e90f";
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px;
          color: #fff !important; }
          @media (min-width: 992px) {
            header .header-nav-logout:before, header .header-nav-login:before {
              display: none; } }
        @media (min-width: 992px) {
          header .header-nav-logout, header .header-nav-login {
            padding: 7px;
            margin: 0 8px;
            font-size: 14px;
            font-size: 0.875rem;
            width: 84px !important;
            text-align: center;
            line-height: 1; } }
      header .header-nav-language {
        font-size: 0;
        margin: 0 2px; }
        header .header-nav-language:after {
          content: "\e905";
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 32px; }
          @media (min-width: 992px) {
            header .header-nav-language:after {
              display: none; } }
        @media (min-width: 992px) {
          header .header-nav-language {
            margin: 0 8px;
            font-size: 14px;
            font-size: 0.875rem;
            padding: 0px 0 0 20px; } }
      header .header-nav-language-selector {
        display: inline-block;
        margin: 0 2px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none; }
        @media (min-width: 992px) {
          header .header-nav-language-selector {
            margin: 0 0 0 30px; } }
        header .header-nav-language-selector .header-language-current {
          cursor: pointer;
          margin-left: 10px; }
          @media (min-width: 992px) {
            header .header-nav-language-selector .header-language-current {
              margin-left: 0px; } }
          header .header-nav-language-selector .header-language-current:after {
            content: "\e905";
            font-size: 20px;
            font-size: 1.25rem;
            line-height: 32px;
            position: relative;
            top: 2px; }
            @media (min-width: 992px) {
              header .header-nav-language-selector .header-language-current:after {
                display: none; } }
        header .header-nav-language-selector span {
          display: inline-block;
          color: #fff;
          font-size: 14px;
          font-size: 0.875rem;
          font-weight: 300;
          font-size: 0; }
          @media (min-width: 992px) {
            header .header-nav-language-selector span {
              font-size: 14px;
              font-size: 0.875rem; } }
        header .header-nav-language-selector .header-language-inner {
          position: absolute;
          border: 1px solid #043a63;
          display: none;
          min-width: 100px;
          background-color: #fff;
          top: 40px;
          right: 0px; }
          @media (min-width: 992px) {
            header .header-nav-language-selector .header-language-inner {
              top: 34px; } }
          header .header-nav-language-selector .header-language-inner ul, header .header-nav-language-selector .header-language-inner li {
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center; }
          header .header-nav-language-selector .header-language-inner a {
            display: inline-block;
            width: auto;
            height: auto;
            margin: 10px;
            border-bottom: 1px solid #fff; }
            header .header-nav-language-selector .header-language-inner a:focus, header .header-nav-language-selector .header-language-inner a:hover {
              border-bottom: 1px solid #043a63; }
      header .header-nav-menu {
        position: relative; }
        header .header-nav-menu span {
          width: 26px;
          position: absolute;
          top: 50%;
          margin-top: -1px;
          background: #fff;
          height: 2px;
          left: 50%;
          margin-left: -13px;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-nav-menu span:before, header .header-nav-menu span:after {
            content: "";
            width: 26px;
            height: 2px;
            position: absolute;
            display: block;
            background: #fff;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-nav-menu span:before {
            top: -8px; }
          header .header-nav-menu span:after {
            bottom: -8px; }
        @media (min-width: 992px) {
          header .header-nav-menu {
            display: none !important; } }
        header .header-nav-menu.open span {
          background: transparent; }
          header .header-nav-menu.open span:before {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 0; }
          header .header-nav-menu.open span:after {
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 0; }
    @media (min-width: 992px) {
      header .header {
        -webkit-box-pack: flex-start;
        -moz-box-pack: flex-start;
        -ms-flex-pack: flex-start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start; } }
  header .submenu {
    font-size: 0;
    white-space: nowrap;
    background: #fff;
    border-top: none;
    z-index: 5;
    display: none;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    header .submenu > ul {
      display: block;
      width: 100%;
      min-width: 187px;
      white-space: normal;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      line-height: 1.125rem;
      border-top: 1px solid #000;
      margin: 0;
      padding: 0; }
      header .submenu > ul li {
        display: block;
        border-bottom: 1px solid #043a63;
        position: relative;
        padding: 0; }
        @media (min-width: 992px) {
          header .submenu > ul li {
            border-bottom: 1px solid #fff;
            padding: 0 22px; } }
        header .submenu > ul li a {
          display: block;
          padding: 9px 15px 9px 25px;
          position: relative; }
          @media (min-width: 992px) {
            header .submenu > ul li a {
              padding: 6px 0; } }
          header .submenu > ul li a span {
            display: inline-block;
            padding: 3px 0;
            font-weight: 300;
            color: #043a63;
            border-bottom: 1px solid #fff; }
            header .submenu > ul li a span:hover {
              color: #043a63;
              border-bottom: 1px solid #043a63; }
        header .submenu > ul li:hover .megamenu ul, header .submenu > ul li:focus .megamenu ul {
          opacity: 1;
          visibility: visible; }
        header .submenu > ul li .menu-mobile {
          height: 43px;
          border: 0; }
          header .submenu > ul li .menu-mobile:before, header .submenu > ul li .menu-mobile:after {
            background-color: #043a63; }
        header .submenu > ul li > span {
          display: block;
          font-weight: 300;
          padding: 9px 15px;
          background: #000;
          color: #043a63;
          border-bottom: 1px solid #000; }
          @media (min-width: 992px) {
            header .submenu > ul li > span {
              background: #7f9bb0;
              color: #000;
              padding: 9px 0; } }
        header .submenu > ul li:last-child {
          border: none; }
        header .submenu > ul li ul {
          margin: 0;
          padding: 0;
          width: auto;
          min-width: 10px; }
      @media (min-width: 992px) {
        header .submenu > ul {
          width: 187px;
          border: none;
          padding: 0; } }
    @media (min-width: 992px) {
      header .submenu {
        position: absolute;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 10px 0px 15px;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        visibility: hidden;
        -webkit-transition-property: opacity, visibility;
        -moz-transition-property: opacity, visibility;
        -o-transition-property: opacity, visibility;
        transition-property: opacity, visibility;
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        border: 1px solid #043a63;
        border-top: 0px; } }

.EditMode header,
DesginMode header {
  position: static; }

.submenu-full .submenu {
  left: 0; }

main {
  min-height: -webkit-calc(100vh - 100px);
  min-height: -moz-calc(100vh - 100px);
  min-height: calc(100vh - 100px); }

footer {
  max-width: 1280px;
  margin: auto;
  background: #043a63;
  color: #fff;
  margin-top: 75px;
  padding: 20px 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }
  footer .nwl-title {
    margin-top: 20px;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      footer .nwl-title {
        margin-top: 0; } }
  footer .nwl-box {
    position: relative; }
    footer .nwl-box label {
      display: none; }
    footer .nwl-box .form-horizontal {
      padding: 0 0 26px 0;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: flex-start;
      -moz-box-align: flex-start;
      -ms-flex-align: flex-start;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      @media (min-width: 768px) {
        footer .nwl-box .form-horizontal {
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row; } }
      footer .nwl-box .form-horizontal .form-group {
        width: 100%;
        margin: 0; }
        @media (min-width: 768px) {
          footer .nwl-box .form-horizontal .form-group {
            width: auto; } }
    footer .nwl-box .ErrorLabel {
      display: none; }
    footer .nwl-box .EditingFormErrorLabel {
      left: 0;
      display: inline-block;
      margin-top: 0.3125rem;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #ff5445; }
      @media (min-width: 768px) {
        footer .nwl-box .EditingFormErrorLabel {
          position: absolute; } }
    footer .nwl-box input[type="text"] {
      width: 100%;
      margin-bottom: 0;
      padding: 0 5px;
      height: 36px;
      line-height: 34px;
      font-size: 14px;
      color: #fff;
      background: #043a63;
      border: 1px solid #fff;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      -webkit-transition-property: border-color;
      -moz-transition-property: border-color;
      -o-transition-property: border-color;
      transition-property: border-color;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease; }
      @media (min-width: 768px) {
        footer .nwl-box input[type="text"] {
          height: 26px;
          line-height: 26px; } }
      footer .nwl-box input[type="text"]:focus {
        border-color: #7f9bb0; }
      footer .nwl-box input[type="text"]:-moz-placeholder {
        color: #fff; }
      footer .nwl-box input[type="text"]::-moz-placeholder {
        color: #fff; }
      footer .nwl-box input[type="text"]::-webkit-input-placeholder {
        color: #fff; }
      footer .nwl-box input[type="text"]:-ms-input-placeholder {
        color: #fff; }
      @media (min-width: 768px) {
        footer .nwl-box input[type="text"] {
          width: 200px; } }
    footer .nwl-box input[type="submit"] {
      cursor: pointer;
      display: block;
      float: right;
      width: 100%;
      margin-left: 10px;
      font-size: 14px;
      line-height: 1;
      padding: 10px;
      height: 26px;
      outline: none;
      text-align: center;
      text-decoration: none;
      margin-top: 15px;
      color: #7f9bb0;
      background: #043a63;
      border: 1px solid #7f9bb0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      font-weight: bold;
      -webkit-transition-property: background, border-color, color;
      -moz-transition-property: background, border-color, color;
      -o-transition-property: background, border-color, color;
      transition-property: background, border-color, color;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease; }
      footer .nwl-box input[type="submit"]:hover, footer .nwl-box input[type="submit"].active {
        border-color: #fff;
        color: #fff; }
      @media (min-width: 768px) {
        footer .nwl-box input[type="submit"] {
          margin-top: 0;
          width: 85px;
          padding: 5px; } }
  footer .nwl-bottom {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    footer .nwl-bottom .copyright {
      padding-bottom: 10px;
      padding-top: 22px;
      font-size: 10px;
      font-size: 0.625rem;
      line-height: 14px;
      line-height: 0.875rem; }
      @media (min-width: 768px) {
        footer .nwl-bottom .copyright {
          padding-right: 10px; } }
      @media (min-width: 992px) {
        footer .nwl-bottom .copyright {
          padding-right: 85px;
          padding-bottom: 0; } }
    @media (min-width: 768px) {
      footer .nwl-bottom {
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; } }
  footer ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    footer ul li {
      display: inline-block;
      vertical-align: top;
      margin: 0 5px 0 -5px;
      font-size: 10px;
      font-size: 0.625rem;
      line-height: 14px;
      line-height: 0.875rem; }
      footer ul li a {
        display: block;
        padding: 5px;
        color: #fff;
        text-decoration: none; }
        footer ul li a:hover {
          color: #fff;
          text-decoration: underline; }
  footer.footer-home {
    margin-top: 70px; }
    @media (min-width: 768px) {
      footer.footer-home {
        margin-top: 100px; } }
  footer-logo {
    height: 39px; }
  footer .social {
    font-size: 0; }
    footer .social .ico-social {
      display: inline-block;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      margin: 5px;
      height: 27px;
      width: 27px;
      text-decoration: none !important;
      position: relative; }
      footer .social .ico-social:before {
        color: #fff;
        -webkit-transition-property: color;
        -moz-transition-property: color;
        -o-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
        font-size: 27px;
        font-size: 1.6875rem;
        position: absolute;
        top: 0;
        left: 0;
        color: #7f9bb0; }
      footer .social .ico-social:focus:before, footer .social .ico-social:hover:before {
        color: #fff; }
      footer .social .ico-social:first-child {
        margin: 5px 5px 5px 0; }
        @media (min-width: 768px) {
          footer .social .ico-social:first-child {
            margin: 5px; } }
      footer .social .ico-social.s-google:before {
        content: ""; }
      footer .social .ico-social.s-tumblr:before {
        content: ""; }
      footer .social .ico-social.s-facebook:before {
        content: ""; }
      footer .social .ico-social.s-mail:before {
        content: ""; }
      footer .social .ico-social.s-twitter:before {
        content: ""; }
      footer .social .ico-social.s-linkedin:before {
        content: ""; }
      footer .social .ico-social.s-vine:before {
        content: ""; }
      footer .social .ico-social.s-youtube:before {
        content: ""; }
      footer .social .ico-social.s-reddit:before {
        content: ""; }
  footer .big-social {
    margin-bottom: 10px; }
    footer .big-social > div {
      text-align: center; }
    footer .big-social .ico-social {
      height: 35px;
      width: 35px; }
      @media (min-width: 1400px) {
        footer .big-social .ico-social {
          height: 40px;
          width: 40px; } }
      footer .big-social .ico-social:before {
        font-size: 35px;
        font-size: 2.1875rem; }
        @media (min-width: 1400px) {
          footer .big-social .ico-social:before {
            font-size: 40px;
            font-size: 2.5rem; } }

.items-container {
  min-width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.map-area-image {
  position: relative; }
  .map-area-image .language-area-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 33; }

.modal-language-country {
  color: #fff !important;
  display: inline-block; }
  .modal-language-country:focus, .modal-language-country:hover, .modal-language-country.hover {
    color: #043a63 !important; }

.quick-search {
  position: fixed;
  background: #e0e0e0;
  top: 106px;
  left: 0;
  width: 100%;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  border: 1px solid #043a63; }
  @media (min-width: 992px) {
    .quick-search {
      position: absolute;
      top: 40px;
      width: 500px;
      right: -170px;
      left: auto; } }
  .quick-search-inner {
    padding-bottom: 10px;
    min-height: 293px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .quick-search .header-search-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    color: #043a63;
    text-decoration: none !important;
    border-bottom: 1px solid #043a63;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    .quick-search .header-search-item-image {
      position: relative;
      width: 130px;
      min-width: 130px;
      height: 90px;
      background: #fff;
      text-align: center;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      overflow: hidden; }
    .quick-search .header-search-item-name {
      font-weight: bold;
      padding: 0 15px;
      width: 100%;
      font-size: 18px;
      font-size: 1.125rem; }
    .quick-search .header-search-item-arrow {
      width: 30px;
      height: 30px;
      min-width: 30px;
      background: transparent;
      color: #043a63;
      margin-right: 10px;
      text-align: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .quick-search .header-search-item-arrow:before {
        content: "\e91f";
        line-height: 28px;
        font-size: 24px;
        font-size: 1.5rem;
        margin-left: -2px; }
    .quick-search .header-search-item:focus, .quick-search .header-search-item:hover {
      background: #043a63;
      color: #fff; }
      .quick-search .header-search-item:focus .header-search-item-arrow, .quick-search .header-search-item:hover .header-search-item-arrow {
        color: #fff; }
  .quick-search.open {
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .quick-search .btn-search-more {
    color: #043a63; }

.quick-search-btn {
  padding: 10px 10px 20px 10px; }

.loader {
  display: none;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #043a63 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #043a63 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #043a63 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #043a63 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #043a63 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 33;
  left: 50%;
  margin-left: -60px; }

.loader:before {
  width: 50%;
  height: 50%;
  background: #043a63;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.quick-search.opacity .loader {
  display: inline-block;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100); }

.quick-search.opacity .quick-search-inner {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  max-height: 293px;
  overflow: hidden; }
  .quick-search.opacity .quick-search-inner header {
    display: none; }

.load-more-products {
  width: 100%; }
  .load-more-products .inner-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

.order-top {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
  order: 1; }
  @media (min-width: 768px) {
    .order-top {
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      -moz-order: 2;
      -ms-order: 2;
      order: 2; } }

.order-bottom {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2;
  order: 2; }
  @media (min-width: 768px) {
    .order-bottom {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -ms-box-ordinal-group: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      -moz-order: 1;
      -ms-order: 1;
      order: 1; } }

.card-toptext {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px); }
  @media (min-width: 992px) {
    .card-toptext {
      margin-bottom: 40px;
      height: calc(100% - 40px); } }
  .card-toptext-title {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #043a63;
    flex: 1 0 auto;
    text-align: center; }
    @media (min-width: 768px) {
      .card-toptext-title {
        padding: 15px 30px; } }
    @media (min-width: 992px) {
      .card-toptext-title {
        padding: 20px 40px; } }
    .card-toptext-title.static-title {
      flex: 0 0 auto; }
    .card-toptext-title h2 {
      position: relative;
      color: #fff !important;
      font-size: 22px;
      line-height: 1.2;
      padding: 0 !important;
      margin: 0 0 13px !important; }
      @media (min-width: 768px) {
        .card-toptext-title h2 {
          font-size: 26px; } }
      @media (min-width: 992px) {
        .card-toptext-title h2 {
          font-size: 32px; } }
      .card-toptext-title h2 a {
        color: #fff !important;
        text-decoration: none; }
      .card-toptext-title h2:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 200px;
        height: 1px;
        background-color: #fff;
        transform: translate(-50%, 10px);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
      .card-toptext-title h2:hover:not(.static):after, .card-toptext-title h2:focus:not(.static):after {
        width: 150px; }
      .card-toptext-title h2.bolder-line:after {
        height: 2px; }
  .card-toptext-list {
    display: flex;
    overflow: hidden; }
    .card-toptext-list.grow-list {
      flex: 1 1 auto; }
  .card-toptext-content {
    position: relative;
    overflow: hidden;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    display: block;
    width: 100%; }
    .card-toptext-content:before {
      content: "";
      display: block;
      padding-top: 60%; }
    .card-toptext-content img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
    .card-toptext-content:hover:not(.static), .card-toptext-content:focus:not(.static) {
      transform: scale(1.075); }
  .card-toptext.single-preview:hover .card-toptext-title h2:after, .card-toptext.single-preview:focus .card-toptext-title h2:after {
    width: 150px; }
  .card-toptext.single-preview:hover .card-toptext-content, .card-toptext.single-preview:focus .card-toptext-content {
    transform: scale(1.075); }

.hp-card-showcase {
  position: relative;
  overflow: hidden;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-decoration: none; }
  .hp-card-showcase-image {
    position: relative;
    overflow: hidden;
    padding-top: 60%; }
    .hp-card-showcase-image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .hp-card-showcase-title {
    display: none;
    flex: 1 0 60px;
    font-size: 20px;
    font-size: 1.25rem;
    padding: 10px 20px;
    border-bottom: 1px solid #043a63; }
    @media (min-width: 768px) {
      .hp-card-showcase-title {
        flex: 1 0 80px;
        padding: 15px 25px;
        font-size: 22px;
        font-size: 1.375rem; } }
    @media (min-width: 1200px) {
      .hp-card-showcase-title {
        flex: 1 0 100px;
        font-size: 26px;
        font-size: 1.625rem;
        padding: 20px 30px; } }
  .hp-card-showcase:hover .hp-card-showcase-image img, .hp-card-showcase:focus .hp-card-showcase-image img {
    transform: scale(1.075); }

.training-card {
  /*width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;*/
  position: relative;
  overflow: hidden;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-decoration: none; }
  .training-card-image {
    /*position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;*/
    position: relative;
    padding-top: 60%; }
    .training-card-image img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .training-card-content {
    position: relative;
    z-index: 8;
    padding: 30px;
    height: 100%;
    display: flex;
    width: 100%;
    display: none; }
    .training-card-content-inner {
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      width: 100%;
      padding: 20px 50px; }
      .training-card-content-inner-text {
        font-size: 26px;
        line-height: 1.2;
        color: #043a63; }
        @media (min-width: 768px) {
          .training-card-content-inner-text {
            font-size: 32px; } }
        @media (min-width: 1200px) {
          .training-card-content-inner-text {
            font-size: 36px; } }
  .training-card:hover .training-card-image img, .training-card:focus .training-card-image img {
    transform: scale(1.075); }

.show-more-center .show-more-container {
  text-align: center; }

@media (min-width: 992px) {
  .space-20 .row,
  .space-20 .items-container {
    margin-left: -20px;
    margin-right: -20px; } }

@media (min-width: 992px) {
  .space-20 .row div[class*=col-],
  .space-20 .items-container div[class*=col-] {
    padding-left: 20px;
    padding-right: 20px; } }

.footer-social-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.megamenu {
  display: none; }
  @media (min-width: 992px) {
    .megamenu {
      display: block; } }
  .megamenu ul {
    padding: 0;
    border-top: 1px solid #043a63; }
    @media (min-width: 992px) {
      .megamenu ul {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(100%);
        border: 1px solid #043a63;
        padding: 0 15px !important;
        background-color: #fff;
        min-width: 100px;
        opacity: 0;
        visibility: hidden; } }
    .megamenu ul li {
      display: block;
      border-bottom: 1px solid #043a63; }
      @media (min-width: 992px) {
        .megamenu ul li {
          border-bottom: 1px solid #fff;
          padding: 0 22px;
          position: relative; } }
      .megamenu ul li a {
        display: block;
        padding: 9px 40px 9px 40px !important;
        position: relative;
        text-decoration: none; }
        @media (min-width: 992px) {
          .megamenu ul li a {
            padding: 6px 0; } }
        .megamenu ul li a span {
          display: inline-block;
          padding: 3px 0;
          font-weight: 300;
          color: #043a63;
          border-bottom: 1px solid #fff; }
          .megamenu ul li a span:hover {
            color: #043a63;
            border-bottom: 1px solid #043a63; }

.training-container {
  margin-top: 50px; }
  @media (min-width: 768px) {
    .training-container {
      margin-top: 90px; } }
  @media (min-width: 1200px) {
    .training-container {
      margin-top: 100px; } }

.training-card-text {
  padding: 20px 10px;
  font-size: 16px !important;
  line-height: 1.6 !important;
  margin-bottom: 20px !important; }

.insert-image {
  width: 100%;
  position: relative;
  margin: 20px 0;
  text-align: center; }
  .insert-image img {
    max-width: 100%; }

.insert-image-bottom {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  text-align: center; }
  .insert-image-bottom img {
    max-width: 100%; }

.products-list .items-container {
  margin-left: -5px;
  margin-right: -5px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.small-space .items-container [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px; }

.big-space > .row,
.big-space > .items-container {
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 992px) {
    .big-space > .row,
    .big-space > .items-container {
      margin-left: -13px;
      margin-right: -13px; } }
  @media (min-width: 1200px) {
    .big-space > .row,
    .big-space > .items-container {
      margin-left: -26px;
      margin-right: -26px; } }
  .big-space > .row > .col-md-3,
  .big-space > .row > .col-md-9,
  .big-space > .items-container > .col-md-3,
  .big-space > .items-container > .col-md-9 {
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .big-space > .row > .col-md-3,
      .big-space > .row > .col-md-9,
      .big-space > .items-container > .col-md-3,
      .big-space > .items-container > .col-md-9 {
        padding-left: 13px;
        padding-right: 13px; } }
    @media (min-width: 1200px) {
      .big-space > .row > .col-md-3,
      .big-space > .row > .col-md-9,
      .big-space > .items-container > .col-md-3,
      .big-space > .items-container > .col-md-9 {
        padding-left: 26px;
        padding-right: 26px; }
        .big-space > .row > .col-md-3:last-of-type,
        .big-space > .row > .col-md-9:last-of-type,
        .big-space > .items-container > .col-md-3:last-of-type,
        .big-space > .items-container > .col-md-9:last-of-type {
          padding-left: 33.5px; } }

.mini-space .row,
.mini-space .items-container {
  margin-left: -7px;
  margin-right: -7px; }
  .mini-space .row [class*="col-"],
  .mini-space .items-container [class*="col-"] {
    padding-left: 7px;
    padding-right: 7px; }

.small-space .row,
.small-space .items-container {
  margin-left: -10px;
  margin-right: -10px; }
  .small-space .row [class*="col-"],
  .small-space .items-container [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

.medium-space .row,
.medium-space .items-container {
  margin-left: -10px;
  margin-right: -10px; }
  .medium-space .row [class*="col-"],
  .medium-space .items-container [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

.box-header {
  position: relative;
  max-width: 1280px;
  min-height: 87px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: #fff; }
  @media (min-width: 480px) {
    .box-header {
      min-height: 112px; } }
  @media (min-width: 545px) {
    .box-header {
      min-height: 117px; } }
  @media (min-width: 768px) {
    .box-header {
      min-height: 132px; } }
  @media (min-width: 992px) {
    .box-header {
      min-height: 145px;
      margin-bottom: 35px; } }
  .box-header .box-header-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px 0 10px; }
    .box-header .box-header-content .pux-container {
      position: relative;
      padding-right: 100px; }
    @media (min-width: 480px) {
      .box-header .box-header-content {
        padding: 25px 0 20px; } }
    .box-header .box-header-content .breadcrumbs {
      color: inherit;
      line-height: 18px;
      padding-right: 80px;
      color: #043a63; }
      .box-header .box-header-content .breadcrumbs a,
      .box-header .box-header-content .breadcrumbs span {
        margin-top: -5px; }
      .box-header .box-header-content .breadcrumbs .CMSBreadCrumbsCurrentItem {
        color: inherit; }
      .box-header .box-header-content .breadcrumbs a {
        color: inherit;
        text-decoration: none; }
        .box-header .box-header-content .breadcrumbs a:hover, .box-header .box-header-content .breadcrumbs a:focus {
          text-decoration: underline; }
    .box-header .box-header-content .box-header-title {
      padding-top: 10px;
      font-weight: bold; }
      @media (min-width: 545px) {
        .box-header .box-header-content .box-header-title {
          padding-top: 15px; } }
      @media (min-width: 768px) {
        .box-header .box-header-content .box-header-title {
          padding-top: 25px; } }
      @media (min-width: 992px) {
        .box-header .box-header-content .box-header-title {
          padding-top: 30px; } }
    .box-header .box-header-content .box-header-social-print {
      position: absolute;
      top: 0px;
      right: 15px; }
      .box-header .box-header-content .box-header-social-print .social-list {
        position: absolute;
        top: -5px;
        right: 75px;
        width: 0;
        height: 30px;
        background-color: transparent;
        overflow: hidden;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex; }
        @media (min-width: 545px) {
          .box-header .box-header-content .box-header-social-print .social-list {
            height: 37px;
            top: -8px;
            right: 75px; } }
        .box-header .box-header-content .box-header-social-print .social-list.open {
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          width: auto; }
        .box-header .box-header-content .box-header-social-print .social-list-icon {
          display: inline-block;
          float: left;
          width: 30px;
          height: 30px;
          font-size: 0px;
          font-size: 0rem;
          text-decoration: none;
          position: relative; }
          @media (min-width: 545px) {
            .box-header .box-header-content .box-header-social-print .social-list-icon {
              width: 37px;
              height: 37px; } }
          .box-header .box-header-content .box-header-social-print .social-list-icon:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 23px;
            font-size: 1.4375rem;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #043a63;
            cursor: pointer; }
            @media (min-width: 545px) {
              .box-header .box-header-content .box-header-social-print .social-list-icon:before {
                font-size: 27px;
                font-size: 1.6875rem; } }
          .box-header .box-header-content .box-header-social-print .social-list-icon:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #043a63;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid transparent; }
            @media (min-width: 545px) {
              .box-header .box-header-content .box-header-social-print .social-list-icon:after {
                width: 35px;
                height: 35px; } }
          .box-header .box-header-content .box-header-social-print .social-list-icon:focus:after, .box-header .box-header-content .box-header-social-print .social-list-icon:hover:after {
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            border-color: #043a63; }
        .box-header .box-header-content .box-header-social-print .social-list .s-google:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-tumblr:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-facebook:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-mail:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-twitter:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-linkedin:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-vine:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-youtube:before {
          content: ""; }
        .box-header .box-header-content .box-header-social-print .social-list .s-reddit:before {
          content: ""; }
      .box-header .box-header-content .box-header-social-print .box-header-social,
      .box-header .box-header-content .box-header-social-print .box-header-print {
        position: relative;
        display: inline-block;
        color: inherit;
        font-size: 0px;
        font-size: 0rem;
        width: 30px;
        height: 30px;
        color: #043a63;
        cursor: pointer; }
        .box-header .box-header-content .box-header-social-print .box-header-social:before,
        .box-header .box-header-content .box-header-social-print .box-header-print:before {
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 22px;
          font-size: 1.375rem;
          -moz-transition: all ease-in-out 100ms;
          -o-transition: all ease-in-out 100ms;
          -webkit-transition: all ease-in-out 100ms;
          transition: all ease-in-out 100ms;
          cursor: pointer; }
        .box-header .box-header-content .box-header-social-print .box-header-social:after,
        .box-header .box-header-content .box-header-social-print .box-header-print:after {
          content: "";
          position: absolute;
          top: 38%;
          left: 42%;
          -ms-transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          color: #043a63;
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid transparent; }
          @media (min-width: 545px) {
            .box-header .box-header-content .box-header-social-print .box-header-social:after,
            .box-header .box-header-content .box-header-social-print .box-header-print:after {
              width: 35px;
              height: 35px; } }
        .box-header .box-header-content .box-header-social-print .box-header-social:focus:after, .box-header .box-header-content .box-header-social-print .box-header-social:hover:after,
        .box-header .box-header-content .box-header-social-print .box-header-print:focus:after,
        .box-header .box-header-content .box-header-social-print .box-header-print:hover:after {
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          border-color: #043a63; }
        .box-header .box-header-content .box-header-social-print .box-header-social.open:after,
        .box-header .box-header-content .box-header-social-print .box-header-print.open:after {
          border-color: #043a63; }
      .box-header .box-header-content .box-header-social-print .box-header-social {
        margin-right: 5px; }
        .box-header .box-header-content .box-header-social-print .box-header-social:before {
          content: "\e904"; }
      .box-header .box-header-content .box-header-social-print .box-header-print:before {
        content: "\e902"; }

.main-content,
.main-content-product {
  width: 100%;
  margin-top: 20px;
  color: #043a63;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px; }
  @media (min-width: 768px) {
    .main-content,
    .main-content-product {
      margin-top: 0px; } }
  .main-content h1,
  .main-content h2,
  .main-content-product h1,
  .main-content-product h2 {
    font-weight: 300;
    display: inline-block;
    border-bottom: 1px solid #043a63;
    color: #043a63;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .main-content h1,
      .main-content h2,
      .main-content-product h1,
      .main-content-product h2 {
        margin-bottom: 20px; } }
  .main-content h1,
  .main-content-product h1 {
    min-width: 200px;
    text-align: center; }
  .main-content h2,
  .main-content-product h2 {
    font-size: 20px;
    font-size: 1.25rem;
    padding: 20px 0 4px; }
    @media (min-width: 768px) {
      .main-content h2,
      .main-content-product h2 {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 32px; } }
  .main-content img,
  .main-content-product img {
    max-width: 100%; }
  .main-content .insert-image,
  .main-content-product .insert-image {
    max-width: 100%; }
  .main-content .content-text,
  .main-content-product .content-text {
    margin-bottom: 20px;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    font-weight: 300; }
    @media (min-width: 768px) {
      .main-content .content-text,
      .main-content-product .content-text {
        margin-bottom: 40px; } }
  .main-content .biggest-font,
  .main-content-product .biggest-font {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 24px; }
  .main-content a,
  .main-content-product a {
    text-decoration: none; }
    .main-content a:hover, .main-content a:focus,
    .main-content-product a:hover,
    .main-content-product a:focus {
      text-decoration: underline; }
  .main-content ul,
  .main-content-product ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .main-content ul li,
    .main-content-product ul li {
      position: relative;
      padding-left: 20px;
      padding-bottom: 15px; }
      .main-content ul li:before,
      .main-content-product ul li:before {
        position: absolute;
        content: ">";
        margin-right: 5px;
        left: 5px;
        top: 2px; }

.border-none {
  border: none !important; }
  .border-none * {
    border: none !important; }

.main-content-product h1,
.main-content-product h2 {
  font-weight: 300;
  display: block;
  border-bottom: 1px solid #b85e82;
  color: #b85e82;
  width: 100%; }
  @media (min-width: 768px) {
    .main-content-product h1,
    .main-content-product h2 {
      margin-bottom: 35px; } }

.content-button-level-up,
.content-button-level-up-small {
  position: relative;
  display: none;
  padding-left: 35px;
  margin-top: 15px;
  margin-bottom: 25px;
  line-height: 20px;
  font-size: 14px;
  font-size: 0.875rem;
  text-decoration: none;
  color: #043a63; }
  @media (min-width: 768px) {
    .content-button-level-up,
    .content-button-level-up-small {
      display: block; } }
  @media (min-width: 992px) {
    .content-button-level-up,
    .content-button-level-up-small {
      padding-left: 45px;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 300; } }
  .content-button-level-up:hover, .content-button-level-up:focus,
  .content-button-level-up-small:hover,
  .content-button-level-up-small:focus {
    color: #043a63;
    text-decoration: none !important; }
    .content-button-level-up:hover:before, .content-button-level-up:focus:before,
    .content-button-level-up-small:hover:before,
    .content-button-level-up-small:focus:before {
      color: #fff;
      background-color: #043a63; }
  .content-button-level-up:before,
  .content-button-level-up-small:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    display: inline-block;
    -ms-transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    font-weight: 500;
    color: #043a63;
    font-size: 28px;
    font-size: 1.75rem;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    text-align: center;
    line-height: 26px;
    border: 1px solid #043a63;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    -webkit-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms;
    padding-right: 3px; }
    @media (min-width: 992px) {
      .content-button-level-up:before,
      .content-button-level-up-small:before {
        font-size: 35px;
        font-size: 2.1875rem;
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        line-height: 32px; } }

.content-button-level-up-small {
  display: block; }
  @media (min-width: 768px) {
    .content-button-level-up-small {
      display: none; } }

.content-mobile-menu {
  display: none !important;
  margin-top: 10px;
  font-size: 16px;
  font-size: 1rem;
  color: #043a63 !important;
  border-color: #043a63 !important; }
  .content-mobile-menu:hover {
    color: #fff !important;
    background: #043a63 !important; }
  .content-mobile-menu:after {
    display: inline-block;
    vertical-align: middle;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e903";
    margin-left: 5px;
    margin-top: -3px;
    font-size: 35px;
    font-size: 2.1875rem;
    -webkit-transition-property: transform;
    -moz-transition-property: transform;
    -o-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
  .content-mobile-menu.active:after {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  @media (min-width: 320px) and (max-width: 767px) {
    .content-mobile-menu {
      display: block !important; } }

.content-menu,
.products-menu {
  width: 100.25%;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 20px;
  color: #043a63; }
  @media (min-width: 768px) {
    .content-menu,
    .products-menu {
      display: block !important; } }
  @media (min-width: 768px) {
    .content-menu,
    .products-menu {
      margin-top: 0px; } }
  .content-menu .content-menu-item > a,
  .content-menu .products-menu-item > a,
  .products-menu .content-menu-item > a,
  .products-menu .products-menu-item > a {
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    font-size: 1rem;
    line-height: 17px;
    font-weight: 300;
    padding: 10px 0px 3px 0px;
    -webkit-transition-property: background-color, color;
    -moz-transition-property: background-color, color;
    -o-transition-property: background-color, color;
    transition-property: background-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    color: #043a63;
    border-bottom: 1px solid transparent; }
    @media (min-width: 768px) {
      .content-menu .content-menu-item > a,
      .content-menu .products-menu-item > a,
      .products-menu .content-menu-item > a,
      .products-menu .products-menu-item > a {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 5px 0px; } }
    @media (min-width: 992px) {
      .content-menu .content-menu-item > a,
      .content-menu .products-menu-item > a,
      .products-menu .content-menu-item > a,
      .products-menu .products-menu-item > a {
        font-size: 18px;
        font-size: 1.125rem;
        font-weight: 300;
        padding: 5px 0px; } }
    @media (min-width: 1200px) {
      .content-menu .content-menu-item > a,
      .content-menu .products-menu-item > a,
      .products-menu .content-menu-item > a,
      .products-menu .products-menu-item > a {
        line-height: 20px; } }
    .content-menu .content-menu-item > a:hover, .content-menu .content-menu-item > a:focus,
    .content-menu .products-menu-item > a:hover,
    .content-menu .products-menu-item > a:focus,
    .products-menu .content-menu-item > a:hover,
    .products-menu .content-menu-item > a:focus,
    .products-menu .products-menu-item > a:hover,
    .products-menu .products-menu-item > a:focus {
      color: #043a63;
      border-bottom: 1px solid #043a63; }
  .content-menu .content-menu-item.active > a,
  .content-menu .products-menu-item.active > a,
  .products-menu .content-menu-item.active > a,
  .products-menu .products-menu-item.active > a {
    color: #043a63;
    border-bottom: 1px solid #043a63; }
  .content-menu .content-menu-item.active .content-submenu,
  .content-menu .content-menu-item.active .products-submenu,
  .content-menu .products-menu-item.active .content-submenu,
  .content-menu .products-menu-item.active .products-submenu,
  .products-menu .content-menu-item.active .content-submenu,
  .products-menu .content-menu-item.active .products-submenu,
  .products-menu .products-menu-item.active .content-submenu,
  .products-menu .products-menu-item.active .products-submenu {
    display: block; }
  .content-menu .content-menu-item .content-submenu,
  .content-menu .content-menu-item .products-submenu,
  .content-menu .products-menu-item .content-submenu,
  .content-menu .products-menu-item .products-submenu,
  .products-menu .content-menu-item .content-submenu,
  .products-menu .content-menu-item .products-submenu,
  .products-menu .products-menu-item .content-submenu,
  .products-menu .products-menu-item .products-submenu {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 10px;
    text-decoration: none;
    display: none; }
    @media (min-width: 768px) {
      .content-menu .content-menu-item .content-submenu,
      .content-menu .content-menu-item .products-submenu,
      .content-menu .products-menu-item .content-submenu,
      .content-menu .products-menu-item .products-submenu,
      .products-menu .content-menu-item .content-submenu,
      .products-menu .content-menu-item .products-submenu,
      .products-menu .products-menu-item .content-submenu,
      .products-menu .products-menu-item .products-submenu {
        columns: 2;
        margin-bottom: 15px; } }
    .content-menu .content-menu-item .content-submenu .content-submenu-item,
    .content-menu .content-menu-item .content-submenu .products-submenu-item,
    .content-menu .content-menu-item .products-submenu .content-submenu-item,
    .content-menu .content-menu-item .products-submenu .products-submenu-item,
    .content-menu .products-menu-item .content-submenu .content-submenu-item,
    .content-menu .products-menu-item .content-submenu .products-submenu-item,
    .content-menu .products-menu-item .products-submenu .content-submenu-item,
    .content-menu .products-menu-item .products-submenu .products-submenu-item,
    .products-menu .content-menu-item .content-submenu .content-submenu-item,
    .products-menu .content-menu-item .content-submenu .products-submenu-item,
    .products-menu .content-menu-item .products-submenu .content-submenu-item,
    .products-menu .content-menu-item .products-submenu .products-submenu-item,
    .products-menu .products-menu-item .content-submenu .content-submenu-item,
    .products-menu .products-menu-item .content-submenu .products-submenu-item,
    .products-menu .products-menu-item .products-submenu .content-submenu-item,
    .products-menu .products-menu-item .products-submenu .products-submenu-item {
      -webkit-column-break-inside: avoid;
      -moz-column-break-inside: avoid;
      -o-column-break-inside: avoid;
      -ms-column-break-inside: avoid;
      column-break-inside: avoid; }
      .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
      .content-menu .content-menu-item .content-submenu .products-submenu-item > a,
      .content-menu .content-menu-item .products-submenu .content-submenu-item > a,
      .content-menu .content-menu-item .products-submenu .products-submenu-item > a,
      .content-menu .products-menu-item .content-submenu .content-submenu-item > a,
      .content-menu .products-menu-item .content-submenu .products-submenu-item > a,
      .content-menu .products-menu-item .products-submenu .content-submenu-item > a,
      .content-menu .products-menu-item .products-submenu .products-submenu-item > a,
      .products-menu .content-menu-item .content-submenu .content-submenu-item > a,
      .products-menu .content-menu-item .content-submenu .products-submenu-item > a,
      .products-menu .content-menu-item .products-submenu .content-submenu-item > a,
      .products-menu .content-menu-item .products-submenu .products-submenu-item > a,
      .products-menu .products-menu-item .content-submenu .content-submenu-item > a,
      .products-menu .products-menu-item .content-submenu .products-submenu-item > a,
      .products-menu .products-menu-item .products-submenu .content-submenu-item > a,
      .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
        position: relative;
        display: inline-block;
        padding: 7px 0 2px 0px;
        color: #043a63;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 17px;
        text-decoration: none;
        font-weight: 300;
        border-bottom: 1px solid transparent; }
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a:hover, .content-menu .content-menu-item .content-submenu .content-submenu-item > a:focus,
        .content-menu .content-menu-item .content-submenu .products-submenu-item > a:hover,
        .content-menu .content-menu-item .content-submenu .products-submenu-item > a:focus,
        .content-menu .content-menu-item .products-submenu .content-submenu-item > a:hover,
        .content-menu .content-menu-item .products-submenu .content-submenu-item > a:focus,
        .content-menu .content-menu-item .products-submenu .products-submenu-item > a:hover,
        .content-menu .content-menu-item .products-submenu .products-submenu-item > a:focus,
        .content-menu .products-menu-item .content-submenu .content-submenu-item > a:hover,
        .content-menu .products-menu-item .content-submenu .content-submenu-item > a:focus,
        .content-menu .products-menu-item .content-submenu .products-submenu-item > a:hover,
        .content-menu .products-menu-item .content-submenu .products-submenu-item > a:focus,
        .content-menu .products-menu-item .products-submenu .content-submenu-item > a:hover,
        .content-menu .products-menu-item .products-submenu .content-submenu-item > a:focus,
        .content-menu .products-menu-item .products-submenu .products-submenu-item > a:hover,
        .content-menu .products-menu-item .products-submenu .products-submenu-item > a:focus,
        .products-menu .content-menu-item .content-submenu .content-submenu-item > a:hover,
        .products-menu .content-menu-item .content-submenu .content-submenu-item > a:focus,
        .products-menu .content-menu-item .content-submenu .products-submenu-item > a:hover,
        .products-menu .content-menu-item .content-submenu .products-submenu-item > a:focus,
        .products-menu .content-menu-item .products-submenu .content-submenu-item > a:hover,
        .products-menu .content-menu-item .products-submenu .content-submenu-item > a:focus,
        .products-menu .content-menu-item .products-submenu .products-submenu-item > a:hover,
        .products-menu .content-menu-item .products-submenu .products-submenu-item > a:focus,
        .products-menu .products-menu-item .content-submenu .content-submenu-item > a:hover,
        .products-menu .products-menu-item .content-submenu .content-submenu-item > a:focus,
        .products-menu .products-menu-item .content-submenu .products-submenu-item > a:hover,
        .products-menu .products-menu-item .content-submenu .products-submenu-item > a:focus,
        .products-menu .products-menu-item .products-submenu .content-submenu-item > a:hover,
        .products-menu .products-menu-item .products-submenu .content-submenu-item > a:focus,
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a:hover,
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a:focus {
          text-decoration: none !important;
          border-bottom: 1px solid #043a63; }
        @media (min-width: 768px) {
          .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
          .content-menu .content-menu-item .content-submenu .products-submenu-item > a,
          .content-menu .content-menu-item .products-submenu .content-submenu-item > a,
          .content-menu .content-menu-item .products-submenu .products-submenu-item > a,
          .content-menu .products-menu-item .content-submenu .content-submenu-item > a,
          .content-menu .products-menu-item .content-submenu .products-submenu-item > a,
          .content-menu .products-menu-item .products-submenu .content-submenu-item > a,
          .content-menu .products-menu-item .products-submenu .products-submenu-item > a,
          .products-menu .content-menu-item .content-submenu .content-submenu-item > a,
          .products-menu .content-menu-item .content-submenu .products-submenu-item > a,
          .products-menu .content-menu-item .products-submenu .content-submenu-item > a,
          .products-menu .content-menu-item .products-submenu .products-submenu-item > a,
          .products-menu .products-menu-item .content-submenu .content-submenu-item > a,
          .products-menu .products-menu-item .content-submenu .products-submenu-item > a,
          .products-menu .products-menu-item .products-submenu .content-submenu-item > a,
          .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
            padding: 5px 0px 2px;
            font-size: 13px;
            font-size: 0.8125rem; } }
        @media (min-width: 992px) {
          .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
          .content-menu .content-menu-item .content-submenu .products-submenu-item > a,
          .content-menu .content-menu-item .products-submenu .content-submenu-item > a,
          .content-menu .content-menu-item .products-submenu .products-submenu-item > a,
          .content-menu .products-menu-item .content-submenu .content-submenu-item > a,
          .content-menu .products-menu-item .content-submenu .products-submenu-item > a,
          .content-menu .products-menu-item .products-submenu .content-submenu-item > a,
          .content-menu .products-menu-item .products-submenu .products-submenu-item > a,
          .products-menu .content-menu-item .content-submenu .content-submenu-item > a,
          .products-menu .content-menu-item .content-submenu .products-submenu-item > a,
          .products-menu .content-menu-item .products-submenu .content-submenu-item > a,
          .products-menu .content-menu-item .products-submenu .products-submenu-item > a,
          .products-menu .products-menu-item .content-submenu .content-submenu-item > a,
          .products-menu .products-menu-item .content-submenu .products-submenu-item > a,
          .products-menu .products-menu-item .products-submenu .content-submenu-item > a,
          .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
            font-size: 15px;
            font-size: 0.9375rem; } }
        @media (min-width: 1200px) {
          .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
          .content-menu .content-menu-item .content-submenu .products-submenu-item > a,
          .content-menu .content-menu-item .products-submenu .content-submenu-item > a,
          .content-menu .content-menu-item .products-submenu .products-submenu-item > a,
          .content-menu .products-menu-item .content-submenu .content-submenu-item > a,
          .content-menu .products-menu-item .content-submenu .products-submenu-item > a,
          .content-menu .products-menu-item .products-submenu .content-submenu-item > a,
          .content-menu .products-menu-item .products-submenu .products-submenu-item > a,
          .products-menu .content-menu-item .content-submenu .content-submenu-item > a,
          .products-menu .content-menu-item .content-submenu .products-submenu-item > a,
          .products-menu .content-menu-item .products-submenu .content-submenu-item > a,
          .products-menu .content-menu-item .products-submenu .products-submenu-item > a,
          .products-menu .products-menu-item .content-submenu .content-submenu-item > a,
          .products-menu .products-menu-item .content-submenu .products-submenu-item > a,
          .products-menu .products-menu-item .products-submenu .content-submenu-item > a,
          .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
            font-size: 16px;
            font-size: 1rem;
            line-height: 20px; } }
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a:before,
        .content-menu .content-menu-item .content-submenu .products-submenu-item > a:before,
        .content-menu .content-menu-item .products-submenu .content-submenu-item > a:before,
        .content-menu .content-menu-item .products-submenu .products-submenu-item > a:before,
        .content-menu .products-menu-item .content-submenu .content-submenu-item > a:before,
        .content-menu .products-menu-item .content-submenu .products-submenu-item > a:before,
        .content-menu .products-menu-item .products-submenu .content-submenu-item > a:before,
        .content-menu .products-menu-item .products-submenu .products-submenu-item > a:before,
        .products-menu .content-menu-item .content-submenu .content-submenu-item > a:before,
        .products-menu .content-menu-item .content-submenu .products-submenu-item > a:before,
        .products-menu .content-menu-item .products-submenu .content-submenu-item > a:before,
        .products-menu .content-menu-item .products-submenu .products-submenu-item > a:before,
        .products-menu .products-menu-item .content-submenu .content-submenu-item > a:before,
        .products-menu .products-menu-item .content-submenu .products-submenu-item > a:before,
        .products-menu .products-menu-item .products-submenu .content-submenu-item > a:before,
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a:before {
          display: block;
          content: "";
          width: 4px;
          height: 4px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          border-radius: 10px;
          position: absolute;
          left: 0;
          top: 11px; }
          @media (min-width: 1200px) {
            .content-menu .content-menu-item .content-submenu .content-submenu-item > a:before,
            .content-menu .content-menu-item .content-submenu .products-submenu-item > a:before,
            .content-menu .content-menu-item .products-submenu .content-submenu-item > a:before,
            .content-menu .content-menu-item .products-submenu .products-submenu-item > a:before,
            .content-menu .products-menu-item .content-submenu .content-submenu-item > a:before,
            .content-menu .products-menu-item .content-submenu .products-submenu-item > a:before,
            .content-menu .products-menu-item .products-submenu .content-submenu-item > a:before,
            .content-menu .products-menu-item .products-submenu .products-submenu-item > a:before,
            .products-menu .content-menu-item .content-submenu .content-submenu-item > a:before,
            .products-menu .content-menu-item .content-submenu .products-submenu-item > a:before,
            .products-menu .content-menu-item .products-submenu .content-submenu-item > a:before,
            .products-menu .content-menu-item .products-submenu .products-submenu-item > a:before,
            .products-menu .products-menu-item .content-submenu .content-submenu-item > a:before,
            .products-menu .products-menu-item .content-submenu .products-submenu-item > a:before,
            .products-menu .products-menu-item .products-submenu .content-submenu-item > a:before,
            .products-menu .products-menu-item .products-submenu .products-submenu-item > a:before {
              top: 14px; } }
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a:hover, .content-menu .content-menu-item .content-submenu .content-submenu-item > a:focus,
        .content-menu .content-menu-item .content-submenu .products-submenu-item > a:hover,
        .content-menu .content-menu-item .content-submenu .products-submenu-item > a:focus,
        .content-menu .content-menu-item .products-submenu .content-submenu-item > a:hover,
        .content-menu .content-menu-item .products-submenu .content-submenu-item > a:focus,
        .content-menu .content-menu-item .products-submenu .products-submenu-item > a:hover,
        .content-menu .content-menu-item .products-submenu .products-submenu-item > a:focus,
        .content-menu .products-menu-item .content-submenu .content-submenu-item > a:hover,
        .content-menu .products-menu-item .content-submenu .content-submenu-item > a:focus,
        .content-menu .products-menu-item .content-submenu .products-submenu-item > a:hover,
        .content-menu .products-menu-item .content-submenu .products-submenu-item > a:focus,
        .content-menu .products-menu-item .products-submenu .content-submenu-item > a:hover,
        .content-menu .products-menu-item .products-submenu .content-submenu-item > a:focus,
        .content-menu .products-menu-item .products-submenu .products-submenu-item > a:hover,
        .content-menu .products-menu-item .products-submenu .products-submenu-item > a:focus,
        .products-menu .content-menu-item .content-submenu .content-submenu-item > a:hover,
        .products-menu .content-menu-item .content-submenu .content-submenu-item > a:focus,
        .products-menu .content-menu-item .content-submenu .products-submenu-item > a:hover,
        .products-menu .content-menu-item .content-submenu .products-submenu-item > a:focus,
        .products-menu .content-menu-item .products-submenu .content-submenu-item > a:hover,
        .products-menu .content-menu-item .products-submenu .content-submenu-item > a:focus,
        .products-menu .content-menu-item .products-submenu .products-submenu-item > a:hover,
        .products-menu .content-menu-item .products-submenu .products-submenu-item > a:focus,
        .products-menu .products-menu-item .content-submenu .content-submenu-item > a:hover,
        .products-menu .products-menu-item .content-submenu .content-submenu-item > a:focus,
        .products-menu .products-menu-item .content-submenu .products-submenu-item > a:hover,
        .products-menu .products-menu-item .content-submenu .products-submenu-item > a:focus,
        .products-menu .products-menu-item .products-submenu .content-submenu-item > a:hover,
        .products-menu .products-menu-item .products-submenu .content-submenu-item > a:focus,
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a:hover,
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a:focus {
          text-decoration: underline; }
      .content-menu .content-menu-item .content-submenu .content-submenu-item .active,
      .content-menu .content-menu-item .content-submenu .products-submenu-item .active,
      .content-menu .content-menu-item .products-submenu .content-submenu-item .active,
      .content-menu .content-menu-item .products-submenu .products-submenu-item .active,
      .content-menu .products-menu-item .content-submenu .content-submenu-item .active,
      .content-menu .products-menu-item .content-submenu .products-submenu-item .active,
      .content-menu .products-menu-item .products-submenu .content-submenu-item .active,
      .content-menu .products-menu-item .products-submenu .products-submenu-item .active,
      .products-menu .content-menu-item .content-submenu .content-submenu-item .active,
      .products-menu .content-menu-item .content-submenu .products-submenu-item .active,
      .products-menu .content-menu-item .products-submenu .content-submenu-item .active,
      .products-menu .content-menu-item .products-submenu .products-submenu-item .active,
      .products-menu .products-menu-item .content-submenu .content-submenu-item .active,
      .products-menu .products-menu-item .content-submenu .products-submenu-item .active,
      .products-menu .products-menu-item .products-submenu .content-submenu-item .active,
      .products-menu .products-menu-item .products-submenu .products-submenu-item .active {
        font-weight: 300;
        border-color: #043a63; }

.menu-yellow.active > a {
  border-color: #ffd100 !important; }

.menu-yellow a {
  color: #ffd100 !important; }
  .menu-yellow a:hover, .menu-yellow a:focus {
    border-color: #ffd100 !important; }

.menu-yellow .content-submenu * {
  color: #ffd100 !important; }
  .menu-yellow .content-submenu *:hover, .menu-yellow .content-submenu *:focus, .menu-yellow .content-submenu *.active {
    border-color: #ffd100 !important; }

.menu-green.active > a {
  border-color: #87d07b !important; }

.menu-green a {
  color: #87d07b !important; }
  .menu-green a:hover, .menu-green a:focus {
    border-color: #87d07b !important; }

.menu-green .content-submenu * {
  color: #87d07b !important; }
  .menu-green .content-submenu *:hover, .menu-green .content-submenu *:focus, .menu-green .content-submenu *.active {
    border-color: #87d07b !important; }

.menu-blue.active > a {
  border-color: #87d0c5 !important; }

.menu-blue a {
  color: #87d0c5 !important; }
  .menu-blue a:hover, .menu-blue a:focus {
    border-color: #87d0c5 !important; }

.menu-blue .content-submenu * {
  color: #87d0c5 !important; }
  .menu-blue .content-submenu *:hover, .menu-blue .content-submenu *:focus, .menu-blue .content-submenu *.active {
    border-color: #87d0c5 !important; }

.menu-pink.active > a {
  border-color: #b85e82 !important; }

.menu-pink a {
  color: #b85e82 !important; }
  .menu-pink a:hover, .menu-pink a:focus {
    border-color: #b85e82 !important; }

.menu-pink .content-submenu * {
  color: #b85e82 !important; }
  .menu-pink .content-submenu *:hover, .menu-pink .content-submenu *:focus, .menu-pink .content-submenu *.active {
    border-color: #b85e82 !important; }

.menu-Trusses.active > a {
  border-color: #d6426f !important; }

.menu-Trusses a {
  color: #d6426f !important; }
  .menu-Trusses a:hover, .menu-Trusses a:focus {
    border-color: #d6426f !important; }

.menu-Trusses .content-submenu * {
  color: #d6426f !important; }
  .menu-Trusses .content-submenu *:hover, .menu-Trusses .content-submenu *:focus, .menu-Trusses .content-submenu *.active {
    border-color: #d6426f !important; }

.menu-Ballast-systems.active > a {
  border-color: #bf9632 !important; }

.menu-Ballast-systems a {
  color: #bf9632 !important; }
  .menu-Ballast-systems a:hover, .menu-Ballast-systems a:focus {
    border-color: #bf9632 !important; }

.menu-Ballast-systems .content-submenu * {
  color: #bf9632 !important; }
  .menu-Ballast-systems .content-submenu *:hover, .menu-Ballast-systems .content-submenu *:focus, .menu-Ballast-systems .content-submenu *.active {
    border-color: #bf9632 !important; }

.menu-LED-Screen-support.active > a {
  border-color: #5e88c2 !important; }

.menu-LED-Screen-support a {
  color: #5e88c2 !important; }
  .menu-LED-Screen-support a:hover, .menu-LED-Screen-support a:focus {
    border-color: #5e88c2 !important; }

.menu-LED-Screen-support .content-submenu * {
  color: #5e88c2 !important; }
  .menu-LED-Screen-support .content-submenu *:hover, .menu-LED-Screen-support .content-submenu *:focus, .menu-LED-Screen-support .content-submenu *.active {
    border-color: #5e88c2 !important; }

.menu-Roofs.active > a {
  border-color: #90b947 !important; }

.menu-Roofs a {
  color: #90b947 !important; }
  .menu-Roofs a:hover, .menu-Roofs a:focus {
    border-color: #90b947 !important; }

.menu-Roofs .content-submenu * {
  color: #90b947 !important; }
  .menu-Roofs .content-submenu *:hover, .menu-Roofs .content-submenu *:focus, .menu-Roofs .content-submenu *.active {
    border-color: #90b947 !important; }

.menu-Crowd-Barriers.active > a {
  border-color: #d64546 !important; }

.menu-Crowd-Barriers a {
  color: #d64546 !important; }
  .menu-Crowd-Barriers a:hover, .menu-Crowd-Barriers a:focus {
    border-color: #d64546 !important; }

.menu-Crowd-Barriers .content-submenu * {
  color: #d64546 !important; }
  .menu-Crowd-Barriers .content-submenu *:hover, .menu-Crowd-Barriers .content-submenu *:focus, .menu-Crowd-Barriers .content-submenu *.active {
    border-color: #d64546 !important; }

.menu-Circles.active > a {
  border-color: #51599e !important; }

.menu-Circles a {
  color: #51599e !important; }
  .menu-Circles a:hover, .menu-Circles a:focus {
    border-color: #51599e !important; }

.menu-Circles .content-submenu * {
  color: #51599e !important; }
  .menu-Circles .content-submenu *:hover, .menu-Circles .content-submenu *:focus, .menu-Circles .content-submenu *.active {
    border-color: #51599e !important; }

.menu-Clamps.active > a {
  border-color: #b93b6b !important; }

.menu-Clamps a {
  color: #b93b6b !important; }
  .menu-Clamps a:hover, .menu-Clamps a:focus {
    border-color: #b93b6b !important; }

.menu-Clamps .content-submenu * {
  color: #b93b6b !important; }
  .menu-Clamps .content-submenu *:hover, .menu-Clamps .content-submenu *:focus, .menu-Clamps .content-submenu *.active {
    border-color: #b93b6b !important; }

.menu-Towers.active > a {
  border-color: #b63264 !important; }

.menu-Towers a {
  color: #b63264 !important; }
  .menu-Towers a:hover, .menu-Towers a:focus {
    border-color: #b63264 !important; }

.menu-Towers .content-submenu * {
  color: #b63264 !important; }
  .menu-Towers .content-submenu *:hover, .menu-Towers .content-submenu *:focus, .menu-Towers .content-submenu *.active {
    border-color: #b63264 !important; }

.menu-Cablecross.active > a {
  border-color: #8d65a1 !important; }

.menu-Cablecross a {
  color: #8d65a1 !important; }
  .menu-Cablecross a:hover, .menu-Cablecross a:focus {
    border-color: #8d65a1 !important; }

.menu-Cablecross .content-submenu * {
  color: #8d65a1 !important; }
  .menu-Cablecross .content-submenu *:hover, .menu-Cablecross .content-submenu *:focus, .menu-Cablecross .content-submenu *.active {
    border-color: #8d65a1 !important; }

.menu-Rigging-Accessories.active > a {
  border-color: #de7130 !important; }

.menu-Rigging-Accessories a {
  color: #de7130 !important; }
  .menu-Rigging-Accessories a:hover, .menu-Rigging-Accessories a:focus {
    border-color: #de7130 !important; }

.menu-Rigging-Accessories .content-submenu * {
  color: #de7130 !important; }
  .menu-Rigging-Accessories .content-submenu *:hover, .menu-Rigging-Accessories .content-submenu *:focus, .menu-Rigging-Accessories .content-submenu *.active {
    border-color: #de7130 !important; }

.menu-LitecCad.active > a {
  border-color: #73beca !important; }

.menu-LitecCad a {
  color: #73beca !important; }
  .menu-LitecCad a:hover, .menu-LitecCad a:focus {
    border-color: #73beca !important; }

.menu-LitecCad .content-submenu * {
  color: #73beca !important; }
  .menu-LitecCad .content-submenu *:hover, .menu-LitecCad .content-submenu *:focus, .menu-LitecCad .content-submenu *.active {
    border-color: #73beca !important; }

.menu-Ramps.active > a {
  border-color: #eeca44 !important; }

.menu-Ramps a {
  color: #eeca44 !important; }
  .menu-Ramps a:hover, .menu-Ramps a:focus {
    border-color: #eeca44 !important; }

.menu-Ramps .content-submenu * {
  color: #eeca44 !important; }
  .menu-Ramps .content-submenu *:hover, .menu-Ramps .content-submenu *:focus, .menu-Ramps .content-submenu *.active {
    border-color: #eeca44 !important; }

.menu-Xstage.active > a {
  border-color: #dfb3d0 !important; }

.menu-Xstage a {
  color: #dfb3d0 !important; }
  .menu-Xstage a:hover, .menu-Xstage a:focus {
    border-color: #dfb3d0 !important; }

.menu-Xstage .content-submenu * {
  color: #dfb3d0 !important; }
  .menu-Xstage .content-submenu *:hover, .menu-Xstage .content-submenu *:focus, .menu-Xstage .content-submenu *.active {
    border-color: #dfb3d0 !important; }

.content-menu-generated {
  width: 100.25%;
  margin-top: 20px;
  color: #043a63; }
  .content-menu-generated ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  @media (min-width: 768px) {
    .content-menu-generated {
      display: block !important; } }
  @media (min-width: 768px) {
    .content-menu-generated {
      margin-top: 0px; } }
  .content-menu-generated li > a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    font-size: 1rem;
    line-height: 17px;
    color: inherit;
    font-weight: 300;
    padding: 5px 5px;
    -webkit-transition-property: background-color, color;
    -moz-transition-property: background-color, color;
    -o-transition-property: background-color, color;
    transition-property: background-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
    @media (min-width: 768px) {
      .content-menu-generated li > a {
        font-size: 14px;
        font-size: 0.875rem; } }
    @media (min-width: 992px) {
      .content-menu-generated li > a {
        font-size: 16px;
        font-size: 1rem;
        font-weight: 300;
        padding: 5px 10px; } }
    @media (min-width: 1200px) {
      .content-menu-generated li > a {
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 20px; } }
    .content-menu-generated li > a:hover, .content-menu-generated li > a:focus {
      background-color: #e8edf1;
      color: #043a63; }
  .content-menu-generated li.Highlighted > a {
    background-color: #e8edf1;
    color: #043a63; }
  .content-menu-generated li.Highlighted .content-submenu {
    display: block; }
  .content-menu-generated li > ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #7f9bb0;
    padding: 0 10px;
    margin-bottom: 10px;
    text-decoration: none;
    display: none; }
    .content-menu-generated li > ul > li > a {
      position: relative;
      display: block;
      padding: 7px 0 7px 10px;
      color: #000;
      font-size: 16px;
      font-size: 1rem;
      line-height: 17px;
      text-decoration: none; }
      @media (min-width: 768px) {
        .content-menu-generated li > ul > li > a {
          padding: 5px 0 5px 10px;
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media (min-width: 992px) {
        .content-menu-generated li > ul > li > a {
          font-size: 16px;
          font-size: 1rem; } }
      @media (min-width: 1200px) {
        .content-menu-generated li > ul > li > a {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 20px; } }
      .content-menu-generated li > ul > li > a:before {
        display: block;
        content: "";
        width: 4px;
        height: 4px;
        background: #000;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 14px; }
      .content-menu-generated li > ul > li > a:hover, .content-menu-generated li > ul > li > a:focus {
        text-decoration: underline; }
    .content-menu-generated li > ul > li .Highlighted {
      font-weight: 300; }

.content-list .content-item-card,
.products-list .content-item-card {
  display: block;
  width: 100%;
  margin-top: 10px;
  background-color: #7f9bb0;
  border: 1px solid #7f9bb0;
  cursor: pointer;
  color: #000;
  text-decoration: none; }
  @media (min-width: 545px) {
    .content-list .content-item-card,
    .products-list .content-item-card {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      height: 140px; } }
  @media (min-width: 545px) and (max-width: 767px) {
    .content-list .content-item-card,
    .products-list .content-item-card {
      border: 7px solid #7f9bb0;
      height: 160px; } }
  @media (min-width: 545px) and (max-width: 650px) {
    .content-list .content-item-card,
    .products-list .content-item-card {
      border: 1px solid #7f9bb0;
      height: 140px; } }
  @media (min-width: 992px) {
    .content-list .content-item-card,
    .products-list .content-item-card {
      border: 7px solid #7f9bb0;
      height: 158px; } }
  .content-list .content-item-card:hover, .content-list .content-item-card:focus,
  .products-list .content-item-card:hover,
  .products-list .content-item-card:focus {
    background-color: #043a63;
    border-color: #043a63;
    text-decoration: none !important; }
    .content-list .content-item-card:hover .content-item-card-text:before, .content-list .content-item-card:focus .content-item-card-text:before,
    .products-list .content-item-card:hover .content-item-card-text:before,
    .products-list .content-item-card:focus .content-item-card-text:before {
      background-color: #000; }
    .content-list .content-item-card:hover .content-item-card-text:after, .content-list .content-item-card:focus .content-item-card-text:after,
    .products-list .content-item-card:hover .content-item-card-text:after,
    .products-list .content-item-card:focus .content-item-card-text:after {
      color: #043a63; }
  .content-list .content-item-card-img,
  .products-list .content-item-card-img {
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    overflow: hidden;
    position: relative; }
    @media (min-width: 545px) {
      .content-list .content-item-card-img,
      .products-list .content-item-card-img {
        width: 48.5%;
        height: 100%;
        padding-bottom: 0; } }
    .content-list .content-item-card-img img,
    .products-list .content-item-card-img img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
  .content-list .content-item-card-text,
  .products-list .content-item-card-text {
    width: 100%;
    position: relative; }
    @media (min-width: 545px) {
      .content-list .content-item-card-text,
      .products-list .content-item-card-text {
        width: 48.5%;
        padding: 5px 10px 10px 0; } }
    .content-list .content-item-card-text .content-item-title,
    .products-list .content-item-card-text .content-item-title {
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 26px;
      max-height: 26px;
      overflow: hidden;
      font-weight: 300;
      border-bottom: 0 !important; }
      @media (min-width: 320px) and (max-width: 544px) {
        .content-list .content-item-card-text .content-item-title,
        .products-list .content-item-card-text .content-item-title {
          text-decoration: underline;
          margin: 0;
          padding: 0 5px; } }
      @media (min-width: 545px) {
        .content-list .content-item-card-text .content-item-title,
        .products-list .content-item-card-text .content-item-title {
          max-height: 52px;
          line-height: 26px;
          font-size: 18px;
          font-size: 1.125rem;
          margin-bottom: 5px;
          background-image: linear-gradient(#000 2px, transparent 2px);
          background-size: 100% 26px;
          background-position: 0 23px; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .content-list .content-item-card-text .content-item-title,
        .products-list .content-item-card-text .content-item-title {
          font-size: 15px;
          font-size: 0.9375rem;
          line-height: 20px;
          max-height: 40px;
          background-size: 100% 20px;
          background-position: 0 18px; } }
      @media (min-width: 992px) {
        .content-list .content-item-card-text .content-item-title,
        .products-list .content-item-card-text .content-item-title {
          max-height: 52px;
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 26px;
          font-weight: 300; } }
      .content-list .content-item-card-text .content-item-title a,
      .products-list .content-item-card-text .content-item-title a {
        display: block;
        color: #000;
        text-decoration: none; }
    .content-list .content-item-card-text .content-item-perex,
    .products-list .content-item-card-text .content-item-perex {
      display: block;
      height: 36px;
      line-height: 18px;
      overflow: hidden;
      display: none; }
      @media (min-width: 545px) {
        .content-list .content-item-card-text .content-item-perex,
        .products-list .content-item-card-text .content-item-perex {
          display: block; } }
    .content-list .content-item-card-text:before,
    .products-list .content-item-card-text:before {
      content: "";
      position: absolute;
      display: none;
      bottom: 13px;
      left: 2px;
      width: 27px;
      height: 27px;
      -webkit-border-radius: 27px;
      -moz-border-radius: 27px;
      -ms-border-radius: 27px;
      border-radius: 27px;
      background-color: transparent;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 992px) {
        .content-list .content-item-card-text:before,
        .products-list .content-item-card-text:before {
          display: block; } }
    .content-list .content-item-card-text:after,
    .products-list .content-item-card-text:after {
      content: "\e91f";
      position: absolute;
      display: none;
      font-size: 30px;
      font-size: 1.875rem;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 545px) {
        .content-list .content-item-card-text:after,
        .products-list .content-item-card-text:after {
          display: block;
          bottom: 0px;
          left: -2px; } }
      @media (min-width: 545px) and (max-width: 991px) {
        .content-list .content-item-card-text:after,
        .products-list .content-item-card-text:after {
          color: #000 !important; } }
      @media (min-width: 992px) {
        .content-list .content-item-card-text:after,
        .products-list .content-item-card-text:after {
          bottom: 12px;
          left: 0px; } }

.card-black .content-item-card:focus, .card-black .content-item-card:hover,
.card-black .products-item-card:focus,
.card-black .products-item-card:hover {
  background-color: #7f9bb0;
  border-color: #7f9bb0; }
  .card-black .content-item-card:focus .content-item-card-text:before,
  .card-black .content-item-card:focus .products-item-card-content:before, .card-black .content-item-card:hover .content-item-card-text:before,
  .card-black .content-item-card:hover .products-item-card-content:before,
  .card-black .products-item-card:focus .content-item-card-text:before,
  .card-black .products-item-card:focus .products-item-card-content:before,
  .card-black .products-item-card:hover .content-item-card-text:before,
  .card-black .products-item-card:hover .products-item-card-content:before {
    background-color: #000; }
  .card-black .content-item-card:focus .content-item-card-text:after,
  .card-black .content-item-card:focus .products-item-card-content:after, .card-black .content-item-card:hover .content-item-card-text:after,
  .card-black .content-item-card:hover .products-item-card-content:after,
  .card-black .products-item-card:focus .content-item-card-text:after,
  .card-black .products-item-card:focus .products-item-card-content:after,
  .card-black .products-item-card:hover .content-item-card-text:after,
  .card-black .products-item-card:hover .products-item-card-content:after {
    color: #fff; }

.card-yellow-hover .content-item-card:focus, .card-yellow-hover .content-item-card:hover,
.card-yellow-hover .products-item-card:focus,
.card-yellow-hover .products-item-card:hover {
  background-color: #043a63;
  border-color: #043a63; }
  .card-yellow-hover .content-item-card:focus .content-item-card-text:before,
  .card-yellow-hover .content-item-card:focus .products-item-card-content:before, .card-yellow-hover .content-item-card:hover .content-item-card-text:before,
  .card-yellow-hover .content-item-card:hover .products-item-card-content:before,
  .card-yellow-hover .products-item-card:focus .content-item-card-text:before,
  .card-yellow-hover .products-item-card:focus .products-item-card-content:before,
  .card-yellow-hover .products-item-card:hover .content-item-card-text:before,
  .card-yellow-hover .products-item-card:hover .products-item-card-content:before {
    background-color: #000; }
  .card-yellow-hover .content-item-card:focus .content-item-card-text:after,
  .card-yellow-hover .content-item-card:focus .products-item-card-content:after, .card-yellow-hover .content-item-card:hover .content-item-card-text:after,
  .card-yellow-hover .content-item-card:hover .products-item-card-content:after,
  .card-yellow-hover .products-item-card:focus .content-item-card-text:after,
  .card-yellow-hover .products-item-card:focus .products-item-card-content:after,
  .card-yellow-hover .products-item-card:hover .content-item-card-text:after,
  .card-yellow-hover .products-item-card:hover .products-item-card-content:after {
    color: #043a63; }

.button-show-content,
.arrow-down-link {
  margin-top: 20px;
  margin-bottom: 30px; }

.content-gallery {
  margin: 40px 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row nowrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .content-gallery-list {
    max-height: 100px;
    width: -webkit-calc(100% - 100px);
    width: -moz-calc(100% - 100px);
    width: calc(100% - 100px);
    overflow: hidden; }
    .content-gallery-list-item {
      width: auto;
      height: 100px; }
      .content-gallery-list-item img {
        width: auto;
        height: 100px;
        margin-left: 50%;
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0); }
  .content-gallery-arrow-left, .content-gallery-arrow-right {
    width: 40px;
    font-size: 0px;
    font-size: 0rem;
    cursor: pointer;
    margin-right: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none; }
    .content-gallery-arrow-left:before, .content-gallery-arrow-right:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: relative;
      top: 50%;
      left: 50%;
      display: block;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: #043a63;
      font-size: 40px;
      font-size: 2.5rem;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid #043a63;
      -moz-transition: all ease-in-out 150ms;
      -o-transition: all ease-in-out 150ms;
      -webkit-transition: all ease-in-out 150ms;
      transition: all ease-in-out 150ms;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      padding-left: 2px; }
    .content-gallery-arrow-left:focus:before, .content-gallery-arrow-left:hover:before, .content-gallery-arrow-right:focus:before, .content-gallery-arrow-right:hover:before {
      color: #fff;
      background-color: #043a63; }
  .content-gallery-arrow-right {
    margin-right: 0px;
    margin-left: 10px; }
    .content-gallery-arrow-right:before {
      content: "\e91f";
      padding-left: 5px; }

.content-tabs {
  margin-top: 25px; }
  .content-tabs-title {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row nowrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    border-bottom: 1px solid #b85e82;
    padding-bottom: 10px !important; }
    @media (min-width: 545px) {
      .content-tabs-title {
        padding-bottom: 15px !important;
        margin-top: 20px; } }
    .content-tabs-title-link {
      display: inline-block;
      margin-bottom: -5px;
      padding: 2px 10px;
      font-size: 14px;
      font-size: 0.875rem;
      color: #043a63;
      text-decoration: none;
      font-weight: 300; }
      @media (min-width: 545px) {
        .content-tabs-title-link {
          padding: 2px 20px;
          font-size: 16px;
          font-size: 1rem; } }
      @media (min-width: 768px) {
        .content-tabs-title-link {
          font-size: 18px;
          font-size: 1.125rem; } }
      .content-tabs-title-link:first-of-type {
        padding-left: 0; }
      .content-tabs-title-link.active, .content-tabs-title-link:focus, .content-tabs-title-link:hover {
        color: #b85e82;
        text-decoration: none !important; }
  @media (min-width: 545px) {
    .content-tabs-list {
      padding-top: 15px; } }
  .content-tabs-list-item {
    display: inline-block;
    width: 100%;
    height: 50px;
    padding-top: 10px;
    text-decoration: none; }
    .content-tabs-list-item:focus-name, .content-tabs-list-item:hover-name {
      text-decoration: underline !important;
      color: #043a63; }
    .content-tabs-list-item-image {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 65%;
      border: 1px solid #043a63;
      overflow: hidden; }
      .content-tabs-list-item-image img {
        display: block;
        position: absolute;
        position: absolute;
        left: -200%;
        right: -200%;
        margin: auto;
        bottom: -200%;
        top: -200%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover; }
    .content-tabs-list-item-name {
      margin-top: 5px;
      font-size: 11px;
      font-size: 0.6875rem;
      color: #043a63; }
      @media (min-width: 480px) {
        .content-tabs-list-item-name {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media (min-width: 768px) {
        .content-tabs-list-item-name {
          margin-top: 10px; } }
      @media (min-width: 1200px) {
        .content-tabs-list-item-name {
          font-size: 14px;
          font-size: 0.875rem; } }
      .content-tabs-list-item-name-overflow {
        display: inline-block;
        width: 100%;
        height: 40px;
        overflow: hidden !important;
        text-overflow: ellipsis; }

.load-more-products {
  display: none; }

.contact-box {
  background-color: #e8edf1;
  padding: 10px;
  position: relative; }
  @media (min-width: 768px) {
    .contact-box {
      padding: 7px; } }
  @media (min-width: 992px) {
    .contact-box {
      padding: 12px; } }
  .contact-box h2 {
    color: #043a63;
    border-bottom: 1px solid #043a63;
    padding: 0;
    margin-bottom: 10px; }
  .contact-box-text {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 18px;
    font-weight: 300; }
    @media (min-width: 480px) and (max-width: 767px) {
      .contact-box-text {
        font-size: 14px;
        font-size: 0.875rem; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .contact-box-text {
        font-size: 13px;
        font-size: 0.8125rem; } }
    @media (min-width: 992px) {
      .contact-box-text {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 21px; } }
    .contact-box-text table {
      border-collapse: collapse; }
      .contact-box-text table td {
        padding-right: 5px; }
        .contact-box-text table td a {
          color: #043a63;
          text-decoration: none; }
          .contact-box-text table td a:hover, .contact-box-text table td a:focus {
            color: #043a63;
            text-decoration: none; }
  .contact-box .contact-box-button {
    width: -webkit-calc(100% - 2px);
    width: -moz-calc(100% - 2px);
    width: calc(100% - 2px);
    position: relative;
    bottom: 12px;
    right: 27px;
    width: auto;
    font-size: 13px;
    font-size: 0.8125rem;
    color: #e8edf1 !important;
    background-color: #819cb1 !important;
    -webkit-transition-property: background-color, color, border-color;
    -moz-transition-property: background-color, color, border-color;
    -o-transition-property: background-color, color, border-color;
    transition-property: background-color, color, border-color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    font-weight: 300 !important;
    border-color: #819cb1; }
    @media (min-width: 480px) {
      .contact-box .contact-box-button {
        right: 27px;
        min-width: 128px;
        font-size: 14px;
        font-size: 0.875rem; } }
    @media (min-width: 768px) {
      .contact-box .contact-box-button {
        position: static; } }
    @media (min-width: 992px) {
      .contact-box .contact-box-button {
        min-width: 130px; } }
    .contact-box .contact-box-button:hover, .contact-box .contact-box-button:focus {
      color: #fff !important;
      background-color: #043a63 !important;
      border-color: #043a63 !important; }

.events-tabs .content-tabs-title-link {
  background-color: transparent;
  text-transform: none; }
  @media (min-width: 320px) and (max-width: 479px) {
    .events-tabs .content-tabs-title-link {
      font-size: 12px;
      font-size: 0.75rem; } }
  .events-tabs .content-tabs-title-link:focus, .events-tabs .content-tabs-title-link:hover {
    background-color: #043a63; }
  .events-tabs .content-tabs-title-link.active {
    background-color: #043a63; }

.events-tabs .content-tabs-list-cont {
  padding-top: 10px; }
  @media (min-width: 768px) {
    .events-tabs .content-tabs-list-cont {
      padding-top: 25px; } }

.events-tabs-item {
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background-color: #043a63; }
  .events-tabs-item-image {
    width: 40%;
    min-height: 95px;
    padding: 5px;
    text-align: center; }
    @media (min-width: 480px) {
      .events-tabs-item-image {
        min-height: 135px;
        padding: 15px 12.5px; } }
    @media (min-width: 768px) {
      .events-tabs-item-image {
        padding: 25px 15.5px 25px 19.5px; } }
    @media (min-width: 992px) {
      .events-tabs-item-image {
        width: 27.5%;
        min-height: 168px; } }
    .events-tabs-item-image img {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      max-width: 100%;
      max-height: 100%;
      margin: 0px; }
  .events-tabs-item-text {
    position: relative;
    width: 75%;
    padding: 5px 5px 35px 5px;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 15px; }
    @media (min-width: 545px) {
      .events-tabs-item-text {
        padding: 10px;
        padding-bottom: 50px;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 18px; } }
    .events-tabs-item-text-title {
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 18px; }
      @media (min-width: 480px) {
        .events-tabs-item-text-title {
          font-size: 16px;
          font-size: 1rem;
          line-height: 25px; } }
      @media (min-width: 992px) {
        .events-tabs-item-text-title {
          font-size: 18px;
          font-size: 1.125rem;
          line-height: 35px; } }
    .events-tabs-item-text-date {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 13px;
      font-size: 0.8125rem;
      font-weight: 300; }
      @media (min-width: 480px) {
        .events-tabs-item-text-date {
          bottom: 20px;
          right: 15px;
          font-size: 14px;
          font-size: 0.875rem; } }
      @media (min-width: 992px) {
        .events-tabs-item-text-date {
          font-size: 18px;
          font-size: 1.125rem; } }

.newsletter-box {
  background-color: #043a63;
  padding: 9px 10px;
  margin-bottom: 25px; }
  .newsletter-box .ErrorLabel {
    display: none; }
  .newsletter-box .EditingFormErrorLabel {
    padding: 5px 10px;
    color: #000;
    background: #ffd100;
    font-size: 14px;
    font-size: 0.875rem;
    display: block;
    margin: 0 0 15px 0; }
  @media (min-width: 992px) {
    .newsletter-box {
      padding: 9px 18px; } }
  .newsletter-box .form-horizontal {
    position: relative;
    width: -webkit-calc(100%);
    width: -moz-calc(100%);
    width: calc(100%); }
    @media (min-width: 545px) {
      .newsletter-box .form-horizontal {
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex; } }
  .newsletter-box .form-group {
    margin: 0;
    width: 100%; }
    @media (min-width: 768px) {
      .newsletter-box .form-group {
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        width: -webkit-calc(100% - 120px);
        width: -moz-calc(100% - 120px);
        width: calc(100% - 120px); } }
  .newsletter-box .editing-form-label-cell {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px; }
    @media (min-width: 480px) {
      .newsletter-box .editing-form-label-cell {
        font-size: 17px;
        font-size: 1.0625rem; } }
    @media (min-width: 768px) {
      .newsletter-box .editing-form-label-cell {
        width: 60%; } }
    @media (min-width: 992px) {
      .newsletter-box .editing-form-label-cell {
        font-size: 22px;
        font-size: 1.375rem; } }
    .newsletter-box .editing-form-label-cell .control-label {
      margin: 0px;
      padding-bottom: 5px; }
      @media (min-width: 545px) {
        .newsletter-box .editing-form-label-cell .control-label {
          padding-bottom: 0px;
          padding-top: 5px; } }
  .newsletter-box label {
    margin: 3px 0; }
    @media (min-width: 768px) {
      .newsletter-box label {
        margin-bottom: 0px; } }
  .newsletter-box .editing-form-value-cell {
    display: inline-block;
    width: -webkit-calc(100% - 100px);
    width: -moz-calc(100% - 100px);
    width: calc(100% - 100px); }
    @media (min-width: 480px) {
      .newsletter-box .editing-form-value-cell {
        width: -webkit-calc(100% - 120px);
        width: -moz-calc(100% - 120px);
        width: calc(100% - 120px); } }
    @media (min-width: 545px) {
      .newsletter-box .editing-form-value-cell {
        width: 100%; } }
    @media (min-width: 768px) {
      .newsletter-box .editing-form-value-cell {
        width: 40%;
        padding-right: 5px; } }
    .newsletter-box .editing-form-value-cell .form-control {
      display: inline;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      height: 32px;
      border: 1px solid #fff;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      outline: 0; }
      .newsletter-box .editing-form-value-cell .form-control:focus {
        border-color: #000; }
  .newsletter-box .form-group-submit {
    position: absolute;
    display: inline-block;
    width: 120px;
    text-align: right;
    bottom: 0;
    right: -100px; }
    @media (min-width: 480px) {
      .newsletter-box .form-group-submit {
        right: -120px; } }
    @media (min-width: 545px) {
      .newsletter-box .form-group-submit {
        position: static;
        width: 120px;
        padding-left: 10px;
        right: -120px; } }
    .newsletter-box .form-group-submit input[type="submit"] {
      display: inline-block;
      width: 90px;
      border: 1px solid #000;
      color: #000;
      background-color: transparent;
      font-size: 15px;
      font-size: 0.9375rem;
      line-height: 20px;
      font-weight: 300;
      padding: 5px 0;
      height: 32px;
      -webkit-transition-property: background-color, color;
      -moz-transition-property: background-color, color;
      -o-transition-property: background-color, color;
      transition-property: background-color, color;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      cursor: pointer;
      outline: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0; }
      @media (min-width: 480px) {
        .newsletter-box .form-group-submit input[type="submit"] {
          width: 110px; } }
      @media (min-width: 768px) {
        .newsletter-box .form-group-submit input[type="submit"] {
          width: 100%; } }
      @media (min-width: 768px) {
        .newsletter-box .form-group-submit input[type="submit"] {
          font-size: 16px;
          font-size: 1rem; } }
      @media (min-width: 992px) {
        .newsletter-box .form-group-submit input[type="submit"] {
          font-size: 14px;
          font-size: 0.875rem; } }
      .newsletter-box .form-group-submit input[type="submit"]:focus, .newsletter-box .form-group-submit input[type="submit"]:hover {
        background-color: #000;
        color: #fff; }
  .newsletter-box .ErrorMessage {
    display: block;
    background-color: #043a63;
    margin-bottom: 10px;
    font-weight: 600; }

.locked-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: #e8edf1;
  padding: 5px 10px;
  margin-top: 45px;
  margin-bottom: 35px; }
  @media (min-width: 992px) {
    .locked-box {
      padding: 10px 15px; } }
  .locked-box-text {
    position: relative;
    padding-left: 30px;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 17px; }
    @media (min-width: 480px) {
      .locked-box-text {
        font-size: 17px;
        font-size: 1.0625rem;
        line-height: 19px; } }
    @media (min-width: 545px) {
      .locked-box-text {
        font-size: 19px;
        font-size: 1.1875rem;
        line-height: 21px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .locked-box-text {
        padding-left: 40px;
        font-size: 17px;
        font-size: 1.0625rem;
        line-height: 19px; } }
    @media (min-width: 1200px) {
      .locked-box-text {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 25px; } }
    .locked-box-text:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      top: 50%;
      left: -5px;
      font-size: 20px;
      font-size: 1.25rem;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #043a63; }
      @media (min-width: 545px) {
        .locked-box-text:before {
          font-size: 22px;
          font-size: 1.375rem; } }
      @media (min-width: 1200px) {
        .locked-box-text:before {
          font-size: 25px;
          font-size: 1.5625rem; } }
  .locked-box-submit {
    -webkit-flex: 0 0 90px;
    -moz-flex: 0 0 90px;
    -ms-flex: 0 0 90px;
    -webkit-flex: 0 0 90px;
    flex: 0 0 90px;
    padding-left: 10px; }
    @media (min-width: 545px) {
      .locked-box-submit {
        -webkit-flex-basis: 110px;
        -moz-flex-basis: 110px;
        -ms-flex-basis: 110px;
        -webkit-flex-basis: 110px;
        flex-basis: 110px; } }
    @media (min-width: 992px) {
      .locked-box-submit {
        -webkit-flex-basis: 160px;
        -moz-flex-basis: 160px;
        -ms-flex-basis: 160px;
        -webkit-flex-basis: 160px;
        flex-basis: 160px; } }
    .locked-box-submit .button-sign-up {
      width: 100%;
      min-height: 32px;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 16px;
      padding: 8px 5px; }

.download-cont {
  padding-bottom: 30px; }

.download-item {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  background-color: #e8edf1;
  padding: 10px 10px 10px 45px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  color: #043a63;
  text-decoration: none;
  margin-bottom: 10px;
  margin-top: 15px; }
  @media (min-width: 480px) and (max-width: 544px) {
    .download-item {
      padding-left: 55px; } }
  @media (min-width: 768px) {
    .download-item {
      padding-left: 55px; } }
  .download-item:hover, .download-item:focus {
    background-color: #043a63;
    color: #fff;
    text-decoration: none !important; }
    .download-item:hover:before, .download-item:focus:before {
      color: #fff; }
  .download-item:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    top: 25px;
    left: 7px;
    font-size: 24px;
    font-size: 1.5rem;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #043a63; }
    @media (min-width: 480px) {
      .download-item:before {
        font-size: 30px;
        font-size: 1.875rem;
        left: 10px; } }
    @media (min-width: 545px) {
      .download-item:before {
        font-size: 24px;
        font-size: 1.5rem;
        left: 7px; } }
    @media (min-width: 768px) {
      .download-item:before {
        font-size: 30px;
        font-size: 1.875rem;
        left: 10px; } }
  .download-item h3 {
    font-size: 14px;
    font-size: 0.875rem;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 300;
    line-height: 18px;
    word-break: break-all; }
    @media (min-width: 480px) {
      .download-item h3 {
        font-size: 14px;
        font-size: 0.875rem; } }

.download-small-cont {
  padding-bottom: 30px;
  margin-top: 20px; }
  @media (min-width: 992px) {
    .download-small-cont {
      margin-top: 40px; } }
  .download-small-cont .product-card-title {
    margin-bottom: 5px; }
  .download-small-cont .download-item {
    margin-top: 10px;
    margin-bottom: 0px; }

.subscribed .locked-box {
  display: none; }

.unsubscribed .download-item.locked:before {
  content: "\e90a"; }

.big-gallery-item {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 15px;
  margin-bottom: 10px;
  color: #000 !important; }
  @media (min-width: 545px) {
    .big-gallery-item {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px; } }
  .big-gallery-item:hover, .big-gallery-item:focus {
    color: #000 !important;
    text-decoration: underline !important; }
  .big-gallery-item-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0px;
    padding-bottom: 82%;
    margin-bottom: 5px; }
    .big-gallery-item-image img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }

.logos-gallery-item {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  margin-bottom: 20px;
  font-weight: 300; }
  .logos-gallery-item-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .logos-gallery-item-image {
        margin-bottom: 25px; } }
    .logos-gallery-item-image img {
      max-width: 100%;
      max-height: 100%; }
  .logos-gallery-item-links {
    font-weight: 400; }
    .logos-gallery-item-links a {
      color: #043a63;
      text-decoration: underline;
      display: inline-block;
      margin-top: 10px; }
      @media (min-width: 768px) {
        .logos-gallery-item-links a {
          margin-top: 0px; } }
      .logos-gallery-item-links a:focus, .logos-gallery-item-links a:hover {
        color: #043a63;
        text-decoration: none; }

.info-box {
  background-color: #043a63;
  margin-top: 40px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  font-weight: 300;
  padding: 7px 15px;
  display: inline-block; }
  @media (min-width: 768px) {
    .info-box {
      display: block; } }
  @media (min-width: 992px) {
    .info-box {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 23px; } }

.hot-stuff-video-item {
  position: relative;
  display: block;
  padding: 10px 0;
  margin-top: 20px;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  color: #000 !important;
  text-decoration: none !important; }
  @media (min-width: 480px) {
    .hot-stuff-video-item {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      padding: 10px;
      margin: 0;
      border-bottom: 1px solid #000;
      min-height: 155px; } }
  @media (min-width: 768px) {
    .hot-stuff-video-item {
      min-height: 184px; } }
  .hot-stuff-video-item:hover, .hot-stuff-video-item:focus {
    text-decoration: none !important; }
    @media (min-width: 992px) {
      .hot-stuff-video-item:hover, .hot-stuff-video-item:focus {
        background-color: #043a63; } }
    .hot-stuff-video-item:hover .hot-stuff-video-item-content:before, .hot-stuff-video-item:focus .hot-stuff-video-item-content:before {
      background-color: #000; }
    .hot-stuff-video-item:hover .hot-stuff-video-item-content:after, .hot-stuff-video-item:focus .hot-stuff-video-item-content:after {
      color: #043a63; }
    @media (min-width: 992px) {
      .hot-stuff-video-item:hover .hot-stuff-video-item-content-button, .hot-stuff-video-item:focus .hot-stuff-video-item-content-button {
        color: #043a63 !important;
        background: #000 !important;
        text-decoration: none !important; } }
  .hot-stuff-video-item-img {
    width: 100%;
    position: relative;
    text-align: center; }
    @media (min-width: 480px) {
      .hot-stuff-video-item-img {
        width: auto;
        -webkit-flex: 0 1 160px;
        -moz-flex: 0 1 160px;
        -ms-flex: 0 1 160px;
        -webkit-flex: 0 1 160px;
        flex: 0 1 160px; } }
    @media (min-width: 768px) {
      .hot-stuff-video-item-img {
        -webkit-flex: 0 1 225px;
        -moz-flex: 0 1 225px;
        -ms-flex: 0 1 225px;
        -webkit-flex: 0 1 225px;
        flex: 0 1 225px; } }
    .hot-stuff-video-item-img img {
      max-width: 100%;
      max-height: 100%; }
      @media (min-width: 480px) {
        .hot-stuff-video-item-img img {
          position: relative;
          top: 50%;
          -ms-transform: translate(0, -50%);
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); } }
  .hot-stuff-video-item-content {
    position: relative;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center; }
    @media (min-width: 480px) {
      .hot-stuff-video-item-content {
        padding-left: 10px;
        text-align: left; } }
    @media (min-width: 768px) {
      .hot-stuff-video-item-content {
        padding-left: 20px; } }
    .hot-stuff-video-item-content-title {
      font-size: 15px;
      font-size: 0.9375rem;
      margin-top: 10px;
      border-bottom: 0px;
      text-decoration: none !important; }
      @media (min-width: 480px) {
        .hot-stuff-video-item-content-title {
          margin-top: 0px;
          border-bottom: 2px solid #000; } }
      @media (min-width: 545px) {
        .hot-stuff-video-item-content-title {
          font-size: 18px;
          font-size: 1.125rem;
          padding-bottom: 5px; } }
      @media (min-width: 768px) {
        .hot-stuff-video-item-content-title {
          font-size: 20px;
          font-size: 1.25rem; } }
      @media (min-width: 1200px) {
        .hot-stuff-video-item-content-title {
          font-size: 22px;
          font-size: 1.375rem; } }
    .hot-stuff-video-item-content-button {
      width: 130px;
      padding: 6px;
      margin-top: 5px; }
      @media (min-width: 480px) {
        .hot-stuff-video-item-content-button {
          position: absolute;
          bottom: 0;
          right: 0; } }
    .hot-stuff-video-item-content:before {
      content: "";
      position: absolute;
      display: none;
      bottom: 2px;
      left: 17px;
      width: 27px;
      height: 27px;
      -webkit-border-radius: 27px;
      -moz-border-radius: 27px;
      -ms-border-radius: 27px;
      border-radius: 27px;
      background-color: transparent;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 992px) {
        .hot-stuff-video-item-content:before {
          display: block; } }
    .hot-stuff-video-item-content:after {
      content: "\e91f";
      position: absolute;
      display: none;
      font-size: 30px;
      font-size: 1.875rem;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 480px) {
        .hot-stuff-video-item-content:after {
          display: block;
          bottom: 0px;
          left: 5px; } }
      @media (min-width: 768px) {
        .hot-stuff-video-item-content:after {
          bottom: 0px;
          left: 15px; } }
      @media (min-width: 545px) and (max-width: 991px) {
        .hot-stuff-video-item-content:after {
          color: #000 !important; } }

.full-width-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0;
  border-bottom: 1px solid #043a63;
  min-height: 91px;
  position: relative;
  color: #043a63;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease; }
  @media (min-width: 480px) {
    .full-width-item {
      min-height: 155px; } }
  @media (min-width: 768px) {
    .full-width-item {
      min-height: 184px; } }
  @media (min-width: 992px) {
    .full-width-item:hover .full-width-item-content, .full-width-item:focus .full-width-item-content {
      background-color: #e8edf1; } }
  .full-width-item:hover .full-width-item-content:before, .full-width-item:focus .full-width-item-content:before {
    border-color: #043a63; }
  @media (min-width: 992px) {
    .full-width-item:hover .full-width-item-content:after, .full-width-item:focus .full-width-item-content:after {
      color: #043a63; } }
  .full-width-item-img {
    width: auto;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: left;
    -moz-box-align: left;
    -ms-flex-align: left;
    -webkit-align-items: left;
    align-items: left;
    -webkit-flex: 0 0 100px;
    -moz-flex: 0 0 100px;
    -ms-flex: 0 0 100px;
    -webkit-flex: 0 0 100px;
    flex: 0 0 100px;
    position: relative; }
    @media (min-width: 480px) {
      .full-width-item-img {
        -webkit-flex: 0 0 160px;
        -moz-flex: 0 0 160px;
        -ms-flex: 0 0 160px;
        -webkit-flex: 0 0 160px;
        flex: 0 0 160px; } }
    @media (min-width: 768px) {
      .full-width-item-img {
        -webkit-flex: 0 0 225px;
        -moz-flex: 0 0 225px;
        -ms-flex: 0 0 225px;
        -webkit-flex: 0 0 225px;
        flex: 0 0 225px; } }
    .full-width-item-img img {
      position: relative;
      max-width: 100%;
      max-height: 100%; }
      @media (min-width: 480px) {
        .full-width-item-img img {
          top: 50%;
          -ms-transform: translate(0, -50%);
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); } }
  .full-width-item-content {
    position: relative;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 20px 10px 20px 10px;
    text-align: left;
    color: #043a63; }
    @media (min-width: 480px) {
      .full-width-item-content {
        padding-right: 0px; } }
    @media (min-width: 768px) {
      .full-width-item-content {
        padding-left: 20px;
        padding-right: 30px; } }
    .full-width-item-content-title {
      font-size: 15px;
      font-size: 0.9375rem;
      border-bottom: 0;
      color: #043a63;
      padding-right: 20px; }
      @media (min-width: 320px) and (max-width: 479px) {
        .full-width-item-content-title {
          line-height: 19px;
          font-weight: 300;
          text-decoration: underline; } }
      @media (min-width: 545px) {
        .full-width-item-content-title {
          font-size: 18px;
          font-size: 1.125rem;
          padding-bottom: 5px; } }
      @media (min-width: 768px) {
        .full-width-item-content-title {
          font-size: 20px;
          font-size: 1.25rem; } }
      @media (min-width: 1200px) {
        .full-width-item-content-title {
          font-size: 22px;
          font-size: 1.375rem; } }
    @media (min-width: 480px) {
      .full-width-item-content-perex {
        padding-bottom: 25px; } }
    .full-width-item-content:before {
      content: "";
      position: absolute;
      display: none;
      bottom: 7px;
      left: 13px;
      width: 27px;
      height: 27px;
      -webkit-border-radius: 27px;
      -moz-border-radius: 27px;
      -ms-border-radius: 27px;
      border-radius: 27px;
      border: 1px solid transparent;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 992px) {
        .full-width-item-content:before {
          display: block; } }
    .full-width-item-content:after {
      content: "\e91f";
      position: absolute;
      display: block;
      top: 18px;
      right: 0px;
      font-size: 25px;
      font-size: 1.5625rem;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 480px) {
        .full-width-item-content:after {
          top: auto;
          bottom: 5px;
          left: 5px;
          font-size: 30px;
          font-size: 1.875rem; } }
      @media (min-width: 768px) {
        .full-width-item-content:after {
          bottom: 5px;
          left: 15px; } }
      @media (min-width: 545px) and (max-width: 991px) {
        .full-width-item-content:after {
          color: #000 !important; } }

.embed-video.small {
  height: 420px; }

.embed-video.small-16 {
  height: 320px; }

.embed-video {
  height: 640px;
  position: relative; }

.embed-video.large-16 {
  height: 540px; }

.embed-video iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.embed-video-overflow.autoplay-1,
.embed-video-overflow.played {
  opacity: 0;
  visibility: hidden;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

.embed-video-overflow {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #252525;
  background-size: cover;
  background-position: center;
  cursor: pointer; }
  .embed-video-overflow.in-popup {
    display: block; }

.embed-video-icon {
  display: block;
  height: 90px;
  width: 90px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

.embed-video-icon:before {
  content: "";
  display: inline-block;
  font-size: 32px;
  width: 0;
  height: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid #fff;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  top: 22px;
  left: 35px;
  position: absolute; }

.center-box {
  width: 100%;
  text-align: center; }

.last-news {
  margin-top: 20px;
  color: #043a63; }
  @media (min-width: 768px) {
    .last-news {
      margin-top: 35px; } }
  .last-news h2 {
    margin-bottom: 20px; }
  .last-news .litec-card {
    padding-bottom: 20px; }
    .last-news .litec-card-item-title {
      text-align: left;
      padding-left: 15px;
      font-size: 14px;
      font-size: 0.875rem; }

.apps-text {
  max-width: 320px;
  margin-top: 50px;
  font-size: 16px;
  font-size: 1rem; }

.apps-cont {
  margin-top: 20px; }
  .apps-cont-text {
    font-size: 14px;
    font-size: 0.875rem; }
  .apps-cont .app-android,
  .apps-cont .app-apple {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    font-size: 0;
    text-decoration: none !important;
    width: 60px;
    height: 60px;
    background-color: #e0e0e0;
    border-radius: 50%;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    .apps-cont .app-android:before,
    .apps-cont .app-apple:before {
      display: inline-block;
      position: absolute;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      font-size: 1.875rem;
      color: #043a63;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .apps-cont .app-android:hover, .apps-cont .app-android:focus,
    .apps-cont .app-apple:hover,
    .apps-cont .app-apple:focus {
      background-color: #043a63; }
      .apps-cont .app-android:hover:before, .apps-cont .app-android:focus:before,
      .apps-cont .app-apple:hover:before,
      .apps-cont .app-apple:focus:before {
        color: #e0e0e0; }
  .apps-cont .app-android:before {
    content: "\e917";
    top: 25%;
    left: 31%; }
  .apps-cont .app-apple:before {
    content: "\e918";
    top: 23%;
    left: 25%; }

.page-404 {
  margin-top: 30px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  font-weight: 300; }
  @media (min-width: 768px) {
    .page-404 {
      margin-top: 70px; } }
  .page-404 h2 {
    border: none !important;
    font-size: 18px;
    font-size: 1.125rem;
    margin: 0;
    padding: 10px 0;
    font-weight: 300 !important; }
    @media (min-width: 768px) {
      .page-404 h2 {
        font-size: 26px;
        font-size: 1.625rem; } }
  @media (min-width: 768px) {
    .page-404 {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 24px; } }
  .page-404 .searchBox {
    position: relative;
    width: 100%;
    max-width: 250px;
    height: auto;
    overflow: hidden;
    -moz-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    -webkit-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    margin: 30px 0; }
    .page-404 .searchBox label {
      display: none; }
    .page-404 .searchBox input[type="text"] {
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      border-radius: 0px;
      width: 100%;
      height: 40px;
      padding: 0 35px 0 10px;
      border: none;
      outline: 0;
      color: #043a63;
      background: #e8edf1;
      font-weight: 300;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
      .page-404 .searchBox input[type="text"]:focus {
        border: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; }
    .page-404 .searchBox .btn-search {
      position: absolute;
      right: 5px;
      font-size: 0;
      width: 30px;
      text-align: center;
      top: 5px; }
      .page-404 .searchBox .btn-search:before {
        content: "\e909";
        font-size: 25px;
        font-size: 1.5625rem;
        line-height: 32px;
        color: #043a63; }

@media (min-width: 992px) {
  .two-column ul {
    column-count: 2;
    column-gap: 50px;
    width: 400px !important; } }

@media (min-width: 992px) {
  .two-column ul li {
    display: block !important; } }

.box-header-content {
  color: #043a63;
  font-weight: 600; }
  .box-header-content a {
    color: #043a63;
    text-decoration: none;
    font-weight: 600; }

.filter-container {
  display: flex; }

.filter-title {
  font-weight: 700;
  padding-right: 15px;
  display: inline-block;
  padding-top: 5px; }

.filter-list {
  -webkit-flex: none;
  -moz-flex: none;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap; }

.filter-item {
  border: 1px solid #043a63;
  padding: 5px 10px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  margin: 0 15px 15px 0;
  display: inline-block;
  font-weight: 700;
  cursor: pointer; }
  .filter-item:hover, .filter-item:focus, .filter-item.active {
    background-color: #043a63;
    color: #fff; }

.contact-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px; }
  @media (min-width: 768px) {
    .contact-panel {
      padding-top: 30px; } }
  @media (min-width: 992px) {
    .contact-panel {
      padding-top: 40px; } }
  .contact-panel-btn {
    display: inline-block;
    padding: 8px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #043a63;
    text-align: center; }
    @media (min-width: 768px) {
      .contact-panel-btn {
        padding: 10px 50px; } }
    @media (min-width: 992px) {
      .contact-panel-btn {
        padding: 12px 70px; } }
    .contact-panel-btn-inner {
      position: relative;
      color: #fff !important;
      font-size: 22px;
      line-height: 1;
      padding: 0 !important;
      font-weight: 300;
      margin: 0 0 10px !important; }
      @media (min-width: 768px) {
        .contact-panel-btn-inner {
          font-size: 24px; } }
      @media (min-width: 992px) {
        .contact-panel-btn-inner {
          font-size: 30px; } }
      .contact-panel-btn-inner:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 2px;
        left: 50%;
        width: 150px;
        height: 1px;
        background-color: #fff;
        transform: translate(-50%, 10px);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        @media (min-width: 768px) {
          .contact-panel-btn-inner:after {
            width: 200px; } }
      .contact-panel-btn-inner:hover:after, .contact-panel-btn-inner:focus:after {
        width: 150px; }

.afi-gallery-item {
  padding-left: 5px;
  padding-bottom: 5px;
  flex: 0 1 100%;
  max-width: 100%; }
  @media (min-width: 480px) {
    .afi-gallery-item {
      flex: 0 1 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .afi-gallery-item {
      flex: 0 1 33.333%;
      max-width: 33.333%; } }
  .afi-gallery-item-inner {
    position: relative;
    overflow: hidden;
    display: block; }
    .afi-gallery-item-inner:before {
      content: "";
      display: block;
      padding-top: 80%; }
    .afi-gallery-item-inner img {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: top; }

.afi-gallery.large-space .afi-gallery-item {
  padding-bottom: 40px; }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: "Urban-Grotesk";
  src: url("../fonts/Urban-Grotesk-Light.otf");
  font-weight: 300;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Urban-Grotesk";
    src: url("../fonts/Urban-Grotesk-Light.svg") format("svg"); } }

body,
input,
select,
textarea {
  font-family: "Urban-Grotesk", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-size: 0.875rem; }

.text-right {
  text-align: right; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 300; }

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block; }

h1, .h1 {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 29px;
  line-height: 1.8125rem;
  font-weight: 300;
  display: inline-block;
  padding-top: 20px 0 2px;
  margin-bottom: 20px; }
  @media (min-width: 545px) {
    h1, .h1 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 34px;
      line-height: 2.125rem; } }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 35px;
      font-size: 2.1875rem;
      line-height: 39px;
      line-height: 2.4375rem;
      margin-bottom: 50px; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 44px;
      line-height: 2.75rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 46px;
      font-size: 2.875rem;
      line-height: 50px;
      line-height: 3.125rem; } }

h2, .h2 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 22px;
  line-height: 1.375rem; }

h3, .h3 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  line-height: 1.25rem;
  margin: 15px 0 10px; }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 22px;
      line-height: 1.375rem;
      margin: 20px 0 10px; } }

strong,
.bold,
b {
  font-weight: 600; }

.semibold {
  font-weight: 600; }

.no-href,
a {
  text-decoration: underline;
  color: #043a63;
  cursor: pointer;
  outline: none; }
  .no-href:hover,
  a:hover {
    text-decoration: none;
    color: #043a63; }

.logon-password-retrieval-button,
.btn {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
  color: #fff;
  background-color: #043a63;
  border: 1px solid #043a63;
  height: 30px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0 15px;
  outline: 0 !important; }

.logon-password-retrieval-button:focus, .logon-password-retrieval-button:hover,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #fff;
  color: #043a63;
  border: 1px solid #000; }

.title-line {
  padding-bottom: 5px;
  width: 100%; }

.title-line-slim {
  font-weight: 300;
  padding-bottom: 3px;
  border-bottom: 1px solid #043a63;
  width: 100%; }

.square-button,
.square-button-bw {
  display: inline-block;
  padding: 5px;
  margin: 0 2px;
  margin-top: 15px;
  position: relative;
  border: 1px solid #043a63;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 20px;
  text-decoration: none;
  color: #043a63;
  text-align: center; }
  .square-button:focus, .square-button:hover,
  .square-button-bw:focus,
  .square-button-bw:hover {
    color: #fff !important;
    background: #043a63 !important;
    text-decoration: none !important; }

.square-button-bw:focus, .square-button-bw:hover {
  color: #fff !important;
  background: #043a63; }

.big-button-bw,
.big-button-bw-small,
.arrow-down-link {
  width: auto;
  padding: 0 15px;
  min-height: 40px;
  line-height: 38px;
  vertical-align: middle;
  display: inline-block;
  font-size: 18px;
  border: 1px solid #043a63;
  color: #fff;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  text-align: center;
  -webkit-transition-property: background, color;
  -moz-transition-property: background, color;
  -o-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  font-weight: 300; }
  .big-button-bw:hover, .big-button-bw:focus,
  .big-button-bw-small:hover,
  .big-button-bw-small:focus,
  .arrow-down-link:hover,
  .arrow-down-link:focus {
    color: #fff !important;
    background: #043a63;
    text-decoration: none; }

.big-button-bw-small,
.arrow-down-link {
  font-size: 15px;
  padding: 0 10px; }
  @media (min-width: 768px) {
    .big-button-bw-small,
    .arrow-down-link {
      font-size: 18px;
      font-size: 1.125rem;
      padding: 0 20px; } }

.btn-close {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #fff !important;
  color: #fff !important;
  text-shadow: 0;
  opacity: 1;
  font-size: 15px;
  font-size: 0.9375rem;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .btn-close:hover, .btn-close:focus {
    color: #043a63 !important;
    background-color: #fff; }

.show-more-wraper {
  width: 100%;
  margin: 40px 0 20px 0;
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem; }
  @media (min-width: 768px) {
    .show-more-wraper {
      margin: 75px 0 20px 0;
      font-size: 18px;
      font-size: 1.125rem; } }

.vertical-line {
  background-color: #043a63;
  height: 1px;
  width: 100%; }

.FormButton,
.btn-show-more {
  display: inline-block;
  border: 1px solid #043a63;
  color: #043a63;
  text-decoration: none;
  background-color: #fff;
  -webkit-transition-property: background, color;
  -moz-transition-property: background, color;
  -o-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  padding: 8px 13px;
  height: auto !important; }
  .FormButton:focus, .FormButton:hover,
  .btn-show-more:focus,
  .btn-show-more:hover {
    color: #fff !important;
    background-color: #043a63 !important;
    text-decoration: none !important; }
  @media (min-width: 768px) {
    .FormButton,
    .btn-show-more {
      padding: 10px 20px; } }

.password-strength-weak {
  color: #B22222; }

.password-strength-acceptable {
  color: #F4A460; }

.password-strength-average {
  color: #4169E1; }

.password-strength-strong {
  color: #3CB371; }

.password-strength-excellent {
  color: #228B22; }

table {
  max-width: 100%;
  width: 100%;
  overflow-x: auto; }
  table td {
    padding: 5px 10px 5px 0px; }
    @media (min-width: 768px) {
      table td {
        padding: 2px 15px 2px 0px; } }
  table thead {
    background-color: #b85e82;
    color: #fff;
    font-weight: bolder; }
    table thead td {
      background-color: #b85e82;
      color: #fff;
      font-weight: bolder; }
  table img {
    min-width: 21px; }

.main-slider {
  width: 100%;
  position: relative; }
  .main-slider-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    -webkit-background-size: cover !important;
    background-size: cover !important; }
    .main-slider-item-title {
      font-size: 35px;
      font-size: 2.1875rem;
      line-height: 40px;
      font-weight: 300;
      text-align: center;
      color: #043a63; }
      @media (min-width: 545px) {
        .main-slider-item-title {
          font-size: 40px;
          font-size: 2.5rem;
          line-height: 45px; } }
      @media (min-width: 768px) {
        .main-slider-item-title {
          font-size: 55px;
          font-size: 3.4375rem;
          line-height: 60px; } }
      @media (min-width: 992px) {
        .main-slider-item-title {
          font-size: 65px;
          font-size: 4.0625rem;
          line-height: 70px; } }
      @media (min-width: 1200px) {
        .main-slider-item-title {
          font-size: 95px;
          font-size: 5.9375rem;
          line-height: 97px; } }
    .main-slider-item-inner {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      height: 300px; }
      @media (min-width: 545px) {
        .main-slider-item-inner {
          height: 375px; } }
      @media (min-width: 768px) {
        .main-slider-item-inner {
          height: 450px; } }
      @media (min-width: 992px) {
        .main-slider-item-inner {
          height: 535px; } }
  .main-slider .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    text-align: center;
    padding: 0;
    margin: 0; }
    .main-slider .slick-dots li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px;
      width: 8px;
      height: 8px;
      cursor: pointer;
      background: #fff;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition-property: all;
      -moz-transition-property: all;
      -o-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      border: 1px solid #043a63; }
      .main-slider .slick-dots li:focus, .main-slider .slick-dots li:hover {
        background: #043a63; }
      .main-slider .slick-dots li button {
        display: none; }
      .main-slider .slick-dots li.slick-active {
        background-color: #043a63; }

.hp-category-links {
  display: none;
  border: 1px solid #043a63;
  width: 100%; }
  @media (min-width: 992px) {
    .hp-category-links {
      display: block; } }
  .hp-category-links * {
    margin: 0;
    padding: 0; }
  .hp-category-links-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #fff;
    color: #043a63;
    -webkit-transition-property: background, color;
    -moz-transition-property: background, color;
    -o-transition-property: background, color;
    transition-property: background, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    text-decoration: none;
    text-align: center;
    padding: 5px; }
    .hp-category-links-item:hover, .hp-category-links-item:focus {
      color: #fff;
      background-color: #043a63;
      text-decoration: none !important; }
    .hp-category-links-item:before {
      content: "";
      display: block;
      padding-top: 75%; }
      @media (min-width: 768px) {
        .hp-category-links-item:before {
          padding-top: 100%; } }
    .hp-category-links-item-wrap {
      /*&:first-child{
              border-left: 1px solid $color-primary;
            }*/ }
      .hp-category-links-item-wrap:not(:nth-child(11)) {
        border-right: 1px solid #043a63; }

.hp-news-card {
  display: flex;
  flex: 0 1 100%;
  max-width: 100%;
  text-decoration: none;
  height: 100%;
  width: 100%; }
  .hp-news-card-image, .hp-news-card-content {
    flex: 0 0 50% !important;
    max-width: 50% !important; }
  .hp-news-card-image {
    position: relative;
    overflow: hidden; }
    .hp-news-card-image img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      height: auto;
      width: 100%; }
  .hp-news-card-content {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px 10px 10px; }
    @media (min-width: 768px) {
      .hp-news-card-content {
        padding: 12px 20px 12px 20px; } }
    @media (min-width: 1200px) {
      .hp-news-card-content {
        padding: 15px 20px 15px 30px; } }
    .hp-news-card-content h3 {
      font-size: 16px;
      padding-right: 0;
      margin-bottom: 5px;
      margin: 0px !important;
      font-weight: 300; }
      @media (min-width: 480px) {
        .hp-news-card-content h3 {
          font-size: 16px;
          line-height: 25px;
          padding-right: 30px; } }
      @media (min-width: 768px) {
        .hp-news-card-content h3 {
          font-size: 18px;
          padding-right: 25px;
          display: block;
          min-height: 24;
          max-height: 48px;
          line-height: 24px;
          overflow: hidden; } }
      @media (min-width: 992px) {
        .hp-news-card-content h3 {
          font-size: 26px;
          font-size: 1.625rem;
          line-height: 31px;
          max-height: 60px;
          height: auto;
          margin-bottom: 5px;
          display: block;
          min-height: 31;
          max-height: 62px;
          line-height: 31px;
          overflow: hidden; } }
  .hp-news-card:first-of-type {
    min-height: 125px; }
    @media (min-width: 768px) {
      .hp-news-card:first-of-type {
        min-height: 130px; } }
    @media (min-width: 1200px) {
      .hp-news-card:first-of-type {
        min-height: 140px; } }
    @media (min-width: 1200px) {
      .hp-news-card:first-of-type .hp-news-card-arrow {
        width: 29px;
        height: 29px; } }
    @media (min-width: 1200px) {
      .hp-news-card:first-of-type .hp-news-card-arrow:before {
        font-size: 36px;
        font-size: 2.25rem; } }
  .hp-news-card:last-of-type {
    min-height: 100px; }
    @media (min-width: 768px) {
      .hp-news-card:last-of-type {
        min-height: 110px; } }
    @media (min-width: 1200px) {
      .hp-news-card:last-of-type {
        min-height: 120px; } }
    @media (min-width: 1200px) {
      .hp-news-card:last-of-type .hp-news-card-content {
        padding-left: 20px !important; } }
    @media (min-width: 1200px) {
      .hp-news-card:last-of-type .hp-news-card-content h3 {
        font-size: 22px;
        font-size: 1.375rem; } }
  .hp-news-card:hover .hp-news-card-arrow, .hp-news-card:focus .hp-news-card-arrow {
    border-color: #043a63; }
  .hp-news-card:hover .hp-news-card-image img, .hp-news-card:focus .hp-news-card-image img {
    transform: scale(1.08); }

.hp-news-box {
  width: 100%;
  height: 100%; }
  .hp-news-box .slick-list,
  .hp-news-box .slick-track {
    height: 100%; }
  .hp-news-box .hp-news-card-arrow {
    display: none !important; }

.hp-tv-card {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #171717;
  width: 100%;
  height: 100%;
  text-decoration: none;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .hp-tv-card-text {
    color: #fff;
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 300; }
    @media (min-width: 768px) {
      .hp-tv-card-text {
        font-size: 32px;
        font-size: 2rem;
        margin-top: 5px; } }
    @media (min-width: 1200px) {
      .hp-tv-card-text {
        font-size: 40px;
        font-size: 2.5rem; } }
  .hp-tv-card-image {
    background-color: #000;
    padding: 20px 10px;
    margin-top: 15px; }
    @media (min-width: 768px) {
      .hp-tv-card-image {
        margin-top: 0; } }
    .hp-tv-card-image img {
      max-width: 200px; }
      @media (min-width: 768px) {
        .hp-tv-card-image img {
          max-width: 240px; } }
      @media (min-width: 992px) {
        .hp-tv-card-image img {
          max-width: 280px; } }
  .hp-tv-card:hover, .hp-tv-card:focus {
    transform: scale(1.08); }

.devider-title {
  background: #87d0c5;
  padding: 15px 0; }
  .devider-title h2 {
    margin: 0;
    font-size: 23px;
    line-height: 25px;
    color: #fff;
    font-weight: 300;
    display: inline-block;
    border-bottom: 1px solid #fff; }
  .devider-title-link {
    text-decoration: none;
    color: #fff;
    display: inline-block;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 16px;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-decoration: none !important; }
    .devider-title-link:after {
      content: "\e91f";
      background: transparent;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      border: 1px solid #fff;
      margin: 0 0 0 10px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      width: 24px;
      height: 24px;
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 23px;
      padding-left: 2px; }
    .devider-title-link:focus, .devider-title-link:hover {
      color: #000; }
      .devider-title-link:focus:after, .devider-title-link:hover:after {
        background: #fff;
        color: #87d0c5;
        border: 1px solid #fff; }
  .devider-title span {
    display: none; }
  @media (min-width: 480px) {
    .devider-title span {
      display: inline-block;
      margin: 0 0 0 5px; } }
  @media (min-width: 768px) {
    .devider-title h2 {
      font-size: 27px;
      line-height: 30px;
      margin-left: 50%;
      -ms-transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      border-bottom: 1px solid #fff;
      padding-bottom: 5px; }
    .devider-title-link {
      font-size: 20px; }
      .devider-title-link:after {
        width: 30px;
        height: 30px;
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 29px;
        padding-left: 3px; } }
  @media (min-width: 992px) {
    .devider-title {
      padding: 25px 0; }
      .devider-title h2 {
        font-size: 30px;
        line-height: 34px; } }
  @media (min-width: 1200px) {
    .devider-title h2 {
      font-size: 35px; }
    .devider-title-link:after {
      width: 34px;
      height: 34px;
      font-size: 34px;
      font-size: 2.125rem;
      line-height: 33px;
      padding-left: 4px; } }
  @media (min-width: 1400px) {
    .devider-title {
      padding: 30px 0; }
      .devider-title h2 {
        font-size: 41px;
        line-height: 1; }
      .devider-title-link {
        font-size: 22px;
        line-height: 1;
        color: #fff;
        padding-left: 4px; } }
  .devider-title.devider-pink {
    background: #b85e82; }
    .devider-title.devider-pink h2 {
      color: #fff; }
    .devider-title.devider-pink .devider-title-link {
      text-decoration: none !important;
      color: #fff !important; }
      .devider-title.devider-pink .devider-title-link:after {
        background: #b85e82;
        border: 1px solid #fff; }
      .devider-title.devider-pink .devider-title-link:focus, .devider-title.devider-pink .devider-title-link:hover {
        text-decoration: none !important; }
        .devider-title.devider-pink .devider-title-link:focus:after, .devider-title.devider-pink .devider-title-link:hover:after {
          text-decoration: none !important;
          background: #fff;
          color: #b85e82;
          border: 1px solid #fff; }
  .devider-title.devider-green {
    background: #87d07b; }
    .devider-title.devider-green .devider-title-link {
      text-decoration: none !important;
      color: #fff !important; }
      .devider-title.devider-green .devider-title-link:after {
        background: #87d07b;
        border: 1px solid #fff; }
      .devider-title.devider-green .devider-title-link:focus, .devider-title.devider-green .devider-title-link:hover {
        text-decoration: none !important; }
        .devider-title.devider-green .devider-title-link:focus:after, .devider-title.devider-green .devider-title-link:hover:after {
          text-decoration: none !important;
          background: #fff;
          color: #87d07b;
          border: 1px solid #fff; }

.background-light-blue {
  background-color: #e8edf1; }

.black-circle-arrow {
  width: 26px;
  height: 26px;
  background: transparent;
  color: #000;
  text-align: center;
  border: 1px solid transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .black-circle-arrow:before {
    content: "\e91f";
    line-height: 26px;
    font-size: 24px;
    font-size: 1.5rem;
    margin-left: 1px; }
  @media (min-width: 992px) {
    .black-circle-arrow:focus, .black-circle-arrow:hover {
      border: 1px solid #043a63 !important;
      text-decoration: none !important; } }

.division-box .division-item,
.division-box .division-item-news,
.division-box .division-item-product {
  position: relative;
  text-decoration: none;
  color: #043a63;
  font-size: 13px;
  line-height: 16px;
  min-height: 1px;
  padding: 10px;
  width: 50%;
  max-width: 50%; }
  .division-box .division-item-image,
  .division-box .division-item-news-image,
  .division-box .division-item-product-image {
    padding-bottom: 50%;
    position: relative;
    width: auto;
    height: auto;
    margin: 10px 0; }
    .division-box .division-item-image img,
    .division-box .division-item-news-image img,
    .division-box .division-item-product-image img {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
      top: 0;
      max-height: 100%;
      max-width: 100%; }
  .division-box .division-item-content h3,
  .division-box .division-item-news-content h3,
  .division-box .division-item-product-content h3 {
    font-size: 16px;
    padding-right: 0;
    margin-bottom: 5px;
    display: block;
    min-height: 24;
    max-height: 24px;
    line-height: 24px;
    overflow: hidden;
    margin: 0px !important;
    font-weight: 300;
    background-image: linear-gradient(#000 1px, transparent 1px);
    background-size: 100% 24px;
    background-position: 0 23px; }
  .division-box .division-item-content-description,
  .division-box .division-item-news-content-description,
  .division-box .division-item-product-content-description {
    margin-top: 10px;
    display: block;
    min-height: 16;
    max-height: 64px;
    line-height: 16px;
    overflow: hidden; }
  .division-box .division-item-content-link,
  .division-box .division-item-news-content-link,
  .division-box .division-item-product-content-link {
    display: none; }
  @media (min-width: 320px) and (max-width: 479px) {
    .division-box .division-item img,
    .division-box .division-item-news img,
    .division-box .division-item-product img {
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; } }
  @media (min-width: 480px) {
    .division-box .division-item-image,
    .division-box .division-item-news-image,
    .division-box .division-item-product-image {
      padding-bottom: 55%; }
    .division-box .division-item-content h3,
    .division-box .division-item-news-content h3,
    .division-box .division-item-product-content h3 {
      font-size: 16px;
      line-height: 25px;
      max-height: 48px;
      background-size: 100% 24px;
      background-position: 0 21px;
      padding-right: 30px;
      display: block;
      min-height: 25;
      max-height: 25px;
      line-height: 25px;
      overflow: hidden;
      background-image: linear-gradient(#000 1px, transparent 1px);
      background-size: 100% 25px;
      background-position: 0 24px; }
    .division-box .division-item-content-link,
    .division-box .division-item-news-content-link,
    .division-box .division-item-product-content-link {
      position: absolute;
      display: block;
      left: auto;
      right: 10px;
      top: 8px;
      bottom: auto; } }
  @media (min-width: 768px) {
    .division-box .division-item,
    .division-box .division-item-news,
    .division-box .division-item-product {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      height: 120px; }
      .division-box .division-item-image,
      .division-box .division-item-news-image,
      .division-box .division-item-product-image {
        position: relative;
        width: 50%;
        height: -webkit-calc(100% + 20px);
        height: -moz-calc(100% + 20px);
        height: calc(100% + 20px);
        padding-bottom: 0;
        margin: -10px;
        overflow: hidden; }
        .division-box .division-item-image img,
        .division-box .division-item-news-image img,
        .division-box .division-item-product-image img {
          display: block;
          position: absolute;
          position: absolute;
          left: -200%;
          right: -200%;
          margin: auto;
          bottom: -200%;
          top: -200%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover; }
      .division-box .division-item-content,
      .division-box .division-item-news-content,
      .division-box .division-item-product-content {
        width: 50%;
        order: 2;
        position: relative;
        padding: 5px 10px; }
        .division-box .division-item-content h3,
        .division-box .division-item-news-content h3,
        .division-box .division-item-product-content h3 {
          font-size: 18px;
          padding-right: 25px;
          display: block;
          min-height: 24;
          max-height: 48px;
          line-height: 24px;
          overflow: hidden;
          background-image: none;
          background-size: 0;
          background-position: 0; }
        .division-box .division-item-content-description,
        .division-box .division-item-news-content-description,
        .division-box .division-item-product-content-description {
          display: block;
          min-height: 18;
          max-height: 72px;
          line-height: 18px;
          overflow: hidden;
          font-size: 14px;
          font-size: 0.875rem;
          line-height: 18px;
          margin-top: 20px; }
        .division-box .division-item-content-link,
        .division-box .division-item-news-content-link,
        .division-box .division-item-product-content-link {
          position: absolute;
          left: -2px;
          bottom: 10px;
          right: auto;
          top: auto;
          left: 5px; }
          .division-box .division-item-content-link:before,
          .division-box .division-item-news-content-link:before,
          .division-box .division-item-product-content-link:before {
            font-size: 30px;
            font-size: 1.875rem; } }
  @media (min-width: 992px) {
    .division-box .division-item,
    .division-box .division-item-news,
    .division-box .division-item-product {
      height: 150px; }
      .division-box .division-item-content h3,
      .division-box .division-item-news-content h3,
      .division-box .division-item-product-content h3 {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 31px;
        max-height: 60px;
        height: auto;
        margin-bottom: 5px; }
      .division-box .division-item-content-description,
      .division-box .division-item-news-content-description,
      .division-box .division-item-product-content-description {
        display: block;
        min-height: 18;
        max-height: 72px;
        line-height: 18px;
        overflow: hidden;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 18px; }
      .division-box .division-item-content-link,
      .division-box .division-item-news-content-link,
      .division-box .division-item-product-content-link {
        bottom: 10px; } }
  @media (min-width: 1200px) {
    .division-box .division-item-image,
    .division-box .division-item-news-image,
    .division-box .division-item-product-image {
      width: 51.5%; }
    .division-box .division-item-content,
    .division-box .division-item-news-content,
    .division-box .division-item-product-content {
      width: 48%; } }
  @media (min-width: 1400px) {
    .division-box .division-item,
    .division-box .division-item-news,
    .division-box .division-item-product {
      padding: 13px 25px; }
      .division-box .division-item-image,
      .division-box .division-item-news-image,
      .division-box .division-item-product-image {
        height: -webkit-calc(100% + 26px);
        height: -moz-calc(100% + 26px);
        height: calc(100% + 26px);
        margin: -13px -25px;
        width: 54%; }
      .division-box .division-item-content,
      .division-box .division-item-news-content,
      .division-box .division-item-product-content {
        width: 46%; } }
  .division-box .division-item.item-grey,
  .division-box .division-item-news.item-grey,
  .division-box .division-item-product.item-grey {
    background: #999999; }
  .division-box .division-item.item-yellow,
  .division-box .division-item-news.item-yellow,
  .division-box .division-item-product.item-yellow {
    background: #043a63; }
  .division-box .division-item.item-product,
  .division-box .division-item-news.item-product,
  .division-box .division-item-product.item-product {
    background: #e8edf1;
    border-bottom: 1px solid #043a63; }
  @media (min-width: 992px) {
    .division-box .division-item:focus .black-circle-arrow, .division-box .division-item:hover .black-circle-arrow,
    .division-box .division-item-news:focus .black-circle-arrow,
    .division-box .division-item-news:hover .black-circle-arrow,
    .division-box .division-item-product:focus .black-circle-arrow,
    .division-box .division-item-product:hover .black-circle-arrow {
      color: #043a63;
      border-color: #043a63; } }

.division-box .item-stuff .division-item-news-content-description {
  display: block;
  min-height: 16;
  max-height: 48px;
  line-height: 16px;
  overflow: hidden; }

@media (min-width: 768px) {
  .division-box .item-stuff .division-item-news-content {
    position: relative; }
    .division-box .item-stuff .division-item-news-content h3 {
      display: block;
      min-height: 24;
      max-height: 48px;
      line-height: 24px;
      overflow: hidden; }
    .division-box .item-stuff .division-item-news-content-description {
      display: block;
      min-height: 20;
      max-height: 80px;
      line-height: 20px;
      overflow: hidden; }
    .division-box .item-stuff .division-item-news-content-link {
      position: absolute;
      top: 140px;
      left: -6px; } }

@media (min-width: 992px) {
  .division-box .item-stuff .division-item-news-content h3 {
    display: block;
    min-height: 30;
    max-height: 60px;
    line-height: 30px;
    overflow: hidden; }
  .division-box .item-stuff .division-item-news-content-description {
    display: block;
    min-height: 24;
    max-height: 144px;
    line-height: 24px;
    overflow: hidden; }
  .division-box .item-stuff .division-item-news-content-link {
    position: absolute;
    top: 190px;
    left: 1px; } }

.hp-category-links-small-devices {
  width: 100%;
  display: block; }
  @media (min-width: 992px) {
    .hp-category-links-small-devices {
      display: none; } }
  .hp-category-links-small-devices .selectric-wrapper {
    margin: 0 !important; }

.litec-hp-project {
  width: 50%;
  min-height: 250px;
  text-decoration: none; }
  @media (min-width: 768px) {
    .litec-hp-project {
      min-height: 300px; } }
  @media (min-width: 992px) {
    .litec-hp-project {
      min-height: 350px; } }
  .litec-hp-project:first-of-type .litec-hp-project-content {
    border-right: 1px solid #043a63;
    border-left: 0; }
  .litec-hp-project-image {
    position: relative;
    height: 200px;
    overflow: hidden; }
    @media (min-width: 768px) {
      .litec-hp-project-image {
        height: 250px; } }
    @media (min-width: 992px) {
      .litec-hp-project-image {
        height: 300px; } }
    .litec-hp-project-image img {
      width: 100%;
      display: block;
      position: absolute;
      position: absolute;
      left: -200%;
      right: -200%;
      margin: auto;
      bottom: -200%;
      top: -200%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      object-fit: cover; }
  .litec-hp-project-content {
    padding: 10px;
    border-left: 1px solid #043a63; }
    @media (min-width: 768px) {
      .litec-hp-project-content {
        padding: 15px 20px; } }
    @media (min-width: 992px) {
      .litec-hp-project-content {
        padding: 20px 25px; } }
    .litec-hp-project-content h2 {
      height: 50px;
      overflow: hidden;
      margin: 0;
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 26px; }
      @media (min-width: 768px) {
        .litec-hp-project-content h2 {
          height: 56px;
          font-size: 22px;
          font-size: 1.375rem;
          line-height: 28px; } }
      @media (min-width: 992px) {
        .litec-hp-project-content h2 {
          height: 66px;
          font-size: 26px;
          font-size: 1.625rem;
          line-height: 34px; } }
  .litec-hp-project:hover h2, .litec-hp-project:focus h2 {
    text-decoration: underline; }

/*.hp-content,
.hp-banner  {
    padding: 0 20px;
    @include media(xxl) {
        padding: 0 40px;
    }
}*/
.theme-yellow .hp-category-links-item:hover, .theme-yellow .hp-category-links-item:focus {
  background-color: #ffd100 !important; }

.theme-green .hp-category-links-item:hover, .theme-green .hp-category-links-item:focus {
  background-color: #87d07b !important; }

.theme-blue .hp-category-links-item:hover, .theme-blue .hp-category-links-item:focus {
  background-color: #87d0c5 !important; }

.theme-pink .hp-category-links-item:hover, .theme-pink .hp-category-links-item:focus {
  background-color: #b85e82 !important; }

.theme-Trusses .hp-category-links-item:hover, .theme-Trusses .hp-category-links-item:focus {
  background-color: #d6426f !important; }

.theme-Ballast-systems .hp-category-links-item:hover, .theme-Ballast-systems .hp-category-links-item:focus {
  background-color: #bf9632 !important; }

.theme-LED-Screen-support .hp-category-links-item:hover, .theme-LED-Screen-support .hp-category-links-item:focus {
  background-color: #5e88c2 !important; }

.theme-Roofs .hp-category-links-item:hover, .theme-Roofs .hp-category-links-item:focus {
  background-color: #90b947 !important; }

.theme-Crowd-Barriers .hp-category-links-item:hover, .theme-Crowd-Barriers .hp-category-links-item:focus {
  background-color: #d64546 !important; }

.theme-Circles .hp-category-links-item:hover, .theme-Circles .hp-category-links-item:focus {
  background-color: #51599e !important; }

.theme-Clamps .hp-category-links-item:hover, .theme-Clamps .hp-category-links-item:focus {
  background-color: #b93b6b !important; }

.theme-Towers .hp-category-links-item:hover, .theme-Towers .hp-category-links-item:focus {
  background-color: #b63264 !important; }

.theme-Cablecross .hp-category-links-item:hover, .theme-Cablecross .hp-category-links-item:focus {
  background-color: #8d65a1 !important; }

.theme-Rigging-Accessories .hp-category-links-item:hover, .theme-Rigging-Accessories .hp-category-links-item:focus {
  background-color: #de7130 !important; }

.theme-LitecCad .hp-category-links-item:hover, .theme-LitecCad .hp-category-links-item:focus {
  background-color: #73beca !important; }

.theme-Ramps .hp-category-links-item:hover, .theme-Ramps .hp-category-links-item:focus {
  background-color: #eeca44 !important; }

.theme-Xstage .hp-category-links-item:hover, .theme-Xstage .hp-category-links-item:focus {
  background-color: #dfb3d0 !important; }

.hp-banner img {
  width: 100%; }

.litec-card {
  display: inline-block;
  width: 100%;
  padding-bottom: 30px;
  text-decoration: none !important; }
  .litec-card-item {
    width: 100%;
    display: block;
    text-decoration: none;
    border: 1px solid #e8edf1; }
    .litec-card-item-title {
      padding: 7px 45px 7px 30px;
      text-align: center;
      font-size: 14px;
      font-size: 0.875rem;
      color: #043a63;
      background-color: #e8edf1;
      position: relative;
      line-height: 1.25; }
      @media (min-width: 768px) {
        .litec-card-item-title {
          padding: 10px 45px 10px 30px;
          font-size: 18px;
          font-size: 1.125rem; } }
      .litec-card-item-title:before {
        content: "";
        position: absolute;
        display: inline-block;
        top: 50%;
        right: 15px;
        width: 20px;
        height: 20px;
        border: 1px solid transparent;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        -ms-transform: translate(2px, -50%);
        -webkit-transform: translate(2px, -50%);
        transform: translate(2px, -50%);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        @media (min-width: 768px) {
          .litec-card-item-title:before {
            width: 25px;
            height: 25px;
            font-size: 25px;
            font-size: 1.5625rem;
            -ms-transform: translate(3px, -50%);
            -webkit-transform: translate(3px, -50%);
            transform: translate(3px, -50%); } }
      .litec-card-item-title:after {
        content: "\e91f";
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 20px;
        font-size: 1.25rem;
        color: #043a63;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 15px;
        -ms-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%); }
        @media (min-width: 768px) {
          .litec-card-item-title:after {
            font-size: 25px;
            font-size: 1.5625rem; } }
    .litec-card-item-image {
      position: relative;
      width: 100%;
      padding-bottom: 50%;
      overflow: hidden; }
      .litec-card-item-image img {
        display: block;
        position: absolute;
        position: absolute;
        left: -200%;
        right: -200%;
        margin: auto;
        bottom: -200%;
        top: -200%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover; }
  .litec-card-text {
    color: #043a63;
    padding-top: 10px;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 14px; }
    @media (min-width: 768px) {
      .litec-card-text {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 18px; } }
  .litec-card:focus .litec-card-item-title:before, .litec-card:hover .litec-card-item-title:before {
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border: 1px solid #043a63; }
  @media (min-width: 768px) {
    .litec-card.card-v3 {
      height: 100%; } }
  .litec-card.card-v3 .litec-card-item {
    border-width: 2px; }
    @media (min-width: 768px) {
      .litec-card.card-v3 .litec-card-item {
        height: 100%; } }
    .litec-card.card-v3 .litec-card-item-image img {
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .litec-card.card-v3 .litec-card-text {
    padding: 10px; }
  .litec-card.card-v3:hover .litec-card-item-image img, .litec-card.card-v3:focus .litec-card-item-image img {
    transform: scale(1.08); }

.big-card-preview {
  margin-top: 60px; }
  @media (min-width: 768px) {
    .big-card-preview {
      margin-top: 80px; } }
  @media (min-width: 1200px) {
    .big-card-preview {
      margin-top: 140px; } }

.small-card-preview {
  margin-top: 40px; }
  @media (min-width: 768px) {
    .small-card-preview {
      margin-top: 60px;
      padding-bottom: 30px; } }
  @media (min-width: 1200px) {
    .small-card-preview {
      margin-top: 80px; } }

.litec-card-v2 {
  border: 2px solid #043a63;
  color: #043a63;
  position: relative;
  display: block;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 30px;
  font-size: 30px;
  font-size: 1.875rem; }
  @media (min-width: 768px) {
    .litec-card-v2 {
      font-size: 34px;
      font-size: 2.125rem;
      border-width: 3px; } }
  @media (min-width: 992px) {
    .litec-card-v2 {
      border-width: 4px;
      font-size: 42px;
      font-size: 2.625rem;
      margin-bottom: 40px; } }
  .litec-card-v2-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.1;
    text-align: center;
    width: 80%; }
  .litec-card-v2:hover, .litec-card-v2:focus {
    background-color: #043a63;
    color: #fff; }
  .litec-card-v2.small-size {
    margin-bottom: 20px;
    font-size: 24px;
    font-size: 1.5rem; }
    @media (min-width: 768px) {
      .litec-card-v2.small-size {
        font-size: 30px;
        font-size: 1.875rem; } }
    @media (min-width: 992px) {
      .litec-card-v2.small-size {
        font-size: 34px;
        font-size: 2.125rem;
        margin-bottom: 30px; } }

.card-yellow .litec-card-item {
  border-color: #ffd100;
  color: #fff; }
  .card-yellow .litec-card-item-title {
    background-color: #ffd100;
    color: #fff; }
    .card-yellow .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-yellow .litec-card-item-title:after {
      color: #fff; }

.card-yellow:focus .litec-card-item-title:before, .card-yellow:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-green .litec-card-item {
  border-color: #87d07b;
  color: #fff; }
  .card-green .litec-card-item-title {
    background-color: #87d07b;
    color: #fff; }
    .card-green .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-green .litec-card-item-title:after {
      color: #fff; }

.card-green:focus .litec-card-item-title:before, .card-green:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-blue .litec-card-item {
  border-color: #87d0c5;
  color: #fff; }
  .card-blue .litec-card-item-title {
    background-color: #87d0c5;
    color: #fff; }
    .card-blue .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-blue .litec-card-item-title:after {
      color: #fff; }

.card-blue:focus .litec-card-item-title:before, .card-blue:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-pink .litec-card-item {
  border-color: #b85e82;
  color: #fff; }
  .card-pink .litec-card-item-title {
    background-color: #b85e82;
    color: #fff; }
    .card-pink .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-pink .litec-card-item-title:after {
      color: #fff; }

.card-pink:focus .litec-card-item-title:before, .card-pink:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Trusses .litec-card-item {
  border-color: #d6426f;
  color: #fff; }
  .card-Trusses .litec-card-item-title {
    background-color: #d6426f;
    color: #fff; }
    .card-Trusses .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Trusses .litec-card-item-title:after {
      color: #fff; }

.card-Trusses:focus .litec-card-item-title:before, .card-Trusses:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Ballast-systems .litec-card-item {
  border-color: #bf9632;
  color: #fff; }
  .card-Ballast-systems .litec-card-item-title {
    background-color: #bf9632;
    color: #fff; }
    .card-Ballast-systems .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Ballast-systems .litec-card-item-title:after {
      color: #fff; }

.card-Ballast-systems:focus .litec-card-item-title:before, .card-Ballast-systems:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-LED-Screen-support .litec-card-item {
  border-color: #5e88c2;
  color: #fff; }
  .card-LED-Screen-support .litec-card-item-title {
    background-color: #5e88c2;
    color: #fff; }
    .card-LED-Screen-support .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-LED-Screen-support .litec-card-item-title:after {
      color: #fff; }

.card-LED-Screen-support:focus .litec-card-item-title:before, .card-LED-Screen-support:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Roofs .litec-card-item {
  border-color: #90b947;
  color: #fff; }
  .card-Roofs .litec-card-item-title {
    background-color: #90b947;
    color: #fff; }
    .card-Roofs .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Roofs .litec-card-item-title:after {
      color: #fff; }

.card-Roofs:focus .litec-card-item-title:before, .card-Roofs:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Crowd-Barriers .litec-card-item {
  border-color: #d64546;
  color: #fff; }
  .card-Crowd-Barriers .litec-card-item-title {
    background-color: #d64546;
    color: #fff; }
    .card-Crowd-Barriers .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Crowd-Barriers .litec-card-item-title:after {
      color: #fff; }

.card-Crowd-Barriers:focus .litec-card-item-title:before, .card-Crowd-Barriers:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Circles .litec-card-item {
  border-color: #51599e;
  color: #fff; }
  .card-Circles .litec-card-item-title {
    background-color: #51599e;
    color: #fff; }
    .card-Circles .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Circles .litec-card-item-title:after {
      color: #fff; }

.card-Circles:focus .litec-card-item-title:before, .card-Circles:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Clamps .litec-card-item {
  border-color: #b93b6b;
  color: #fff; }
  .card-Clamps .litec-card-item-title {
    background-color: #b93b6b;
    color: #fff; }
    .card-Clamps .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Clamps .litec-card-item-title:after {
      color: #fff; }

.card-Clamps:focus .litec-card-item-title:before, .card-Clamps:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Towers .litec-card-item {
  border-color: #b63264;
  color: #fff; }
  .card-Towers .litec-card-item-title {
    background-color: #b63264;
    color: #fff; }
    .card-Towers .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Towers .litec-card-item-title:after {
      color: #fff; }

.card-Towers:focus .litec-card-item-title:before, .card-Towers:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Cablecross .litec-card-item {
  border-color: #8d65a1;
  color: #fff; }
  .card-Cablecross .litec-card-item-title {
    background-color: #8d65a1;
    color: #fff; }
    .card-Cablecross .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Cablecross .litec-card-item-title:after {
      color: #fff; }

.card-Cablecross:focus .litec-card-item-title:before, .card-Cablecross:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Rigging-Accessories .litec-card-item {
  border-color: #de7130;
  color: #fff; }
  .card-Rigging-Accessories .litec-card-item-title {
    background-color: #de7130;
    color: #fff; }
    .card-Rigging-Accessories .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Rigging-Accessories .litec-card-item-title:after {
      color: #fff; }

.card-Rigging-Accessories:focus .litec-card-item-title:before, .card-Rigging-Accessories:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-LitecCad .litec-card-item {
  border-color: #73beca;
  color: #fff; }
  .card-LitecCad .litec-card-item-title {
    background-color: #73beca;
    color: #fff; }
    .card-LitecCad .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-LitecCad .litec-card-item-title:after {
      color: #fff; }

.card-LitecCad:focus .litec-card-item-title:before, .card-LitecCad:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Ramps .litec-card-item {
  border-color: #eeca44;
  color: #fff; }
  .card-Ramps .litec-card-item-title {
    background-color: #eeca44;
    color: #fff; }
    .card-Ramps .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Ramps .litec-card-item-title:after {
      color: #fff; }

.card-Ramps:focus .litec-card-item-title:before, .card-Ramps:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-Xstage .litec-card-item {
  border-color: #dfb3d0;
  color: #fff; }
  .card-Xstage .litec-card-item-title {
    background-color: #dfb3d0;
    color: #fff; }
    .card-Xstage .litec-card-item-title:before {
      border: 1px solid transparent; }
    .card-Xstage .litec-card-item-title:after {
      color: #fff; }

.card-Xstage:focus .litec-card-item-title:before, .card-Xstage:hover .litec-card-item-title:before {
  border: 1px solid #fff; }

.card-v2-yellow {
  color: #ffd100;
  border-color: #ffd100;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-yellow:focus, .card-v2-yellow:hover {
    background-color: #ffd100;
    color: #fff; }

.card-v2-green {
  color: #87d07b;
  border-color: #87d07b;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-green:focus, .card-v2-green:hover {
    background-color: #87d07b;
    color: #fff; }

.card-v2-blue {
  color: #87d0c5;
  border-color: #87d0c5;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-blue:focus, .card-v2-blue:hover {
    background-color: #87d0c5;
    color: #fff; }

.card-v2-pink {
  color: #b85e82;
  border-color: #b85e82;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-pink:focus, .card-v2-pink:hover {
    background-color: #b85e82;
    color: #fff; }

.card-v2-Trusses {
  color: #d6426f;
  border-color: #d6426f;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Trusses:focus, .card-v2-Trusses:hover {
    background-color: #d6426f;
    color: #fff; }

.card-v2-Ballast-systems {
  color: #bf9632;
  border-color: #bf9632;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Ballast-systems:focus, .card-v2-Ballast-systems:hover {
    background-color: #bf9632;
    color: #fff; }

.card-v2-LED-Screen-support {
  color: #5e88c2;
  border-color: #5e88c2;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-LED-Screen-support:focus, .card-v2-LED-Screen-support:hover {
    background-color: #5e88c2;
    color: #fff; }

.card-v2-Roofs {
  color: #90b947;
  border-color: #90b947;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Roofs:focus, .card-v2-Roofs:hover {
    background-color: #90b947;
    color: #fff; }

.card-v2-Crowd-Barriers {
  color: #d64546;
  border-color: #d64546;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Crowd-Barriers:focus, .card-v2-Crowd-Barriers:hover {
    background-color: #d64546;
    color: #fff; }

.card-v2-Circles {
  color: #51599e;
  border-color: #51599e;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Circles:focus, .card-v2-Circles:hover {
    background-color: #51599e;
    color: #fff; }

.card-v2-Clamps {
  color: #b93b6b;
  border-color: #b93b6b;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Clamps:focus, .card-v2-Clamps:hover {
    background-color: #b93b6b;
    color: #fff; }

.card-v2-Towers {
  color: #b63264;
  border-color: #b63264;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Towers:focus, .card-v2-Towers:hover {
    background-color: #b63264;
    color: #fff; }

.card-v2-Cablecross {
  color: #8d65a1;
  border-color: #8d65a1;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Cablecross:focus, .card-v2-Cablecross:hover {
    background-color: #8d65a1;
    color: #fff; }

.card-v2-Rigging-Accessories {
  color: #de7130;
  border-color: #de7130;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Rigging-Accessories:focus, .card-v2-Rigging-Accessories:hover {
    background-color: #de7130;
    color: #fff; }

.card-v2-LitecCad {
  color: #73beca;
  border-color: #73beca;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-LitecCad:focus, .card-v2-LitecCad:hover {
    background-color: #73beca;
    color: #fff; }

.card-v2-Ramps {
  color: #eeca44;
  border-color: #eeca44;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Ramps:focus, .card-v2-Ramps:hover {
    background-color: #eeca44;
    color: #fff; }

.card-v2-Xstage {
  color: #dfb3d0;
  border-color: #dfb3d0;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .card-v2-Xstage:focus, .card-v2-Xstage:hover {
    background-color: #dfb3d0;
    color: #fff; }

.button-show-products {
  margin-top: 20px;
  margin-bottom: 30px; }

.product-table tr:first-child td {
  background: #043a63; }
  .product-table tr:first-child td:first-child {
    border: 1px solid #dedede; }

.product-title {
  margin-bottom: 10px !important; }

.theme-yellow h2:not(.title-line-slim),
.theme-yellow .title-line {
  color: #ffd100;
  border-color: #ffd100; }

.theme-yellow .content-tabs-title-link {
  color: #ffd100; }
  .theme-yellow .content-tabs-title-link * {
    color: #ffd100; }

.theme-yellow .contact-panel-btn {
  background-color: #ffd100; }

.theme-yellow .slick-arrow:before {
  color: #ffd100;
  border-color: #ffd100; }

.theme-yellow .slick-arrow:hover:before, .theme-yellow .slick-arrow:focus:before {
  background-color: #ffd100;
  color: #fff; }

.theme-yellow .box-product-social-button:before,
.theme-yellow .product-social-list-icon:before {
  color: #ffd100 !important; }

.theme-yellow .box-product-social-button:hover:after, .theme-yellow .box-product-social-button:focus:after, .theme-yellow .box-product-social-button.open:after,
.theme-yellow .product-social-list-icon:hover:after,
.theme-yellow .product-social-list-icon:focus:after,
.theme-yellow .product-social-list-icon.open:after {
  border-color: #ffd100 !important; }

.theme-yellow.content-tabs-title,
.theme-yellow.content-tabs-title a {
  color: #ffd100;
  border-color: #ffd100; }

.theme-green h2:not(.title-line-slim),
.theme-green .title-line {
  color: #87d07b;
  border-color: #87d07b; }

.theme-green .content-tabs-title-link {
  color: #87d07b; }
  .theme-green .content-tabs-title-link * {
    color: #87d07b; }

.theme-green .contact-panel-btn {
  background-color: #87d07b; }

.theme-green .slick-arrow:before {
  color: #87d07b;
  border-color: #87d07b; }

.theme-green .slick-arrow:hover:before, .theme-green .slick-arrow:focus:before {
  background-color: #87d07b;
  color: #fff; }

.theme-green .box-product-social-button:before,
.theme-green .product-social-list-icon:before {
  color: #87d07b !important; }

.theme-green .box-product-social-button:hover:after, .theme-green .box-product-social-button:focus:after, .theme-green .box-product-social-button.open:after,
.theme-green .product-social-list-icon:hover:after,
.theme-green .product-social-list-icon:focus:after,
.theme-green .product-social-list-icon.open:after {
  border-color: #87d07b !important; }

.theme-green.content-tabs-title,
.theme-green.content-tabs-title a {
  color: #87d07b;
  border-color: #87d07b; }

.theme-blue h2:not(.title-line-slim),
.theme-blue .title-line {
  color: #87d0c5;
  border-color: #87d0c5; }

.theme-blue .content-tabs-title-link {
  color: #87d0c5; }
  .theme-blue .content-tabs-title-link * {
    color: #87d0c5; }

.theme-blue .contact-panel-btn {
  background-color: #87d0c5; }

.theme-blue .slick-arrow:before {
  color: #87d0c5;
  border-color: #87d0c5; }

.theme-blue .slick-arrow:hover:before, .theme-blue .slick-arrow:focus:before {
  background-color: #87d0c5;
  color: #fff; }

.theme-blue .box-product-social-button:before,
.theme-blue .product-social-list-icon:before {
  color: #87d0c5 !important; }

.theme-blue .box-product-social-button:hover:after, .theme-blue .box-product-social-button:focus:after, .theme-blue .box-product-social-button.open:after,
.theme-blue .product-social-list-icon:hover:after,
.theme-blue .product-social-list-icon:focus:after,
.theme-blue .product-social-list-icon.open:after {
  border-color: #87d0c5 !important; }

.theme-blue.content-tabs-title,
.theme-blue.content-tabs-title a {
  color: #87d0c5;
  border-color: #87d0c5; }

.theme-pink h2:not(.title-line-slim),
.theme-pink .title-line {
  color: #b85e82;
  border-color: #b85e82; }

.theme-pink .content-tabs-title-link {
  color: #b85e82; }
  .theme-pink .content-tabs-title-link * {
    color: #b85e82; }

.theme-pink .contact-panel-btn {
  background-color: #b85e82; }

.theme-pink .slick-arrow:before {
  color: #b85e82;
  border-color: #b85e82; }

.theme-pink .slick-arrow:hover:before, .theme-pink .slick-arrow:focus:before {
  background-color: #b85e82;
  color: #fff; }

.theme-pink .box-product-social-button:before,
.theme-pink .product-social-list-icon:before {
  color: #b85e82 !important; }

.theme-pink .box-product-social-button:hover:after, .theme-pink .box-product-social-button:focus:after, .theme-pink .box-product-social-button.open:after,
.theme-pink .product-social-list-icon:hover:after,
.theme-pink .product-social-list-icon:focus:after,
.theme-pink .product-social-list-icon.open:after {
  border-color: #b85e82 !important; }

.theme-pink.content-tabs-title,
.theme-pink.content-tabs-title a {
  color: #b85e82;
  border-color: #b85e82; }

.theme-Trusses h2:not(.title-line-slim),
.theme-Trusses .title-line {
  color: #d6426f;
  border-color: #d6426f; }

.theme-Trusses .content-tabs-title-link {
  color: #d6426f; }
  .theme-Trusses .content-tabs-title-link * {
    color: #d6426f; }

.theme-Trusses .contact-panel-btn {
  background-color: #d6426f; }

.theme-Trusses .slick-arrow:before {
  color: #d6426f;
  border-color: #d6426f; }

.theme-Trusses .slick-arrow:hover:before, .theme-Trusses .slick-arrow:focus:before {
  background-color: #d6426f;
  color: #fff; }

.theme-Trusses .box-product-social-button:before,
.theme-Trusses .product-social-list-icon:before {
  color: #d6426f !important; }

.theme-Trusses .box-product-social-button:hover:after, .theme-Trusses .box-product-social-button:focus:after, .theme-Trusses .box-product-social-button.open:after,
.theme-Trusses .product-social-list-icon:hover:after,
.theme-Trusses .product-social-list-icon:focus:after,
.theme-Trusses .product-social-list-icon.open:after {
  border-color: #d6426f !important; }

.theme-Trusses.content-tabs-title,
.theme-Trusses.content-tabs-title a {
  color: #d6426f;
  border-color: #d6426f; }

.theme-Ballast-systems h2:not(.title-line-slim),
.theme-Ballast-systems .title-line {
  color: #bf9632;
  border-color: #bf9632; }

.theme-Ballast-systems .content-tabs-title-link {
  color: #bf9632; }
  .theme-Ballast-systems .content-tabs-title-link * {
    color: #bf9632; }

.theme-Ballast-systems .contact-panel-btn {
  background-color: #bf9632; }

.theme-Ballast-systems .slick-arrow:before {
  color: #bf9632;
  border-color: #bf9632; }

.theme-Ballast-systems .slick-arrow:hover:before, .theme-Ballast-systems .slick-arrow:focus:before {
  background-color: #bf9632;
  color: #fff; }

.theme-Ballast-systems .box-product-social-button:before,
.theme-Ballast-systems .product-social-list-icon:before {
  color: #bf9632 !important; }

.theme-Ballast-systems .box-product-social-button:hover:after, .theme-Ballast-systems .box-product-social-button:focus:after, .theme-Ballast-systems .box-product-social-button.open:after,
.theme-Ballast-systems .product-social-list-icon:hover:after,
.theme-Ballast-systems .product-social-list-icon:focus:after,
.theme-Ballast-systems .product-social-list-icon.open:after {
  border-color: #bf9632 !important; }

.theme-Ballast-systems.content-tabs-title,
.theme-Ballast-systems.content-tabs-title a {
  color: #bf9632;
  border-color: #bf9632; }

.theme-LED-Screen-support h2:not(.title-line-slim),
.theme-LED-Screen-support .title-line {
  color: #5e88c2;
  border-color: #5e88c2; }

.theme-LED-Screen-support .content-tabs-title-link {
  color: #5e88c2; }
  .theme-LED-Screen-support .content-tabs-title-link * {
    color: #5e88c2; }

.theme-LED-Screen-support .contact-panel-btn {
  background-color: #5e88c2; }

.theme-LED-Screen-support .slick-arrow:before {
  color: #5e88c2;
  border-color: #5e88c2; }

.theme-LED-Screen-support .slick-arrow:hover:before, .theme-LED-Screen-support .slick-arrow:focus:before {
  background-color: #5e88c2;
  color: #fff; }

.theme-LED-Screen-support .box-product-social-button:before,
.theme-LED-Screen-support .product-social-list-icon:before {
  color: #5e88c2 !important; }

.theme-LED-Screen-support .box-product-social-button:hover:after, .theme-LED-Screen-support .box-product-social-button:focus:after, .theme-LED-Screen-support .box-product-social-button.open:after,
.theme-LED-Screen-support .product-social-list-icon:hover:after,
.theme-LED-Screen-support .product-social-list-icon:focus:after,
.theme-LED-Screen-support .product-social-list-icon.open:after {
  border-color: #5e88c2 !important; }

.theme-LED-Screen-support.content-tabs-title,
.theme-LED-Screen-support.content-tabs-title a {
  color: #5e88c2;
  border-color: #5e88c2; }

.theme-Roofs h2:not(.title-line-slim),
.theme-Roofs .title-line {
  color: #90b947;
  border-color: #90b947; }

.theme-Roofs .content-tabs-title-link {
  color: #90b947; }
  .theme-Roofs .content-tabs-title-link * {
    color: #90b947; }

.theme-Roofs .contact-panel-btn {
  background-color: #90b947; }

.theme-Roofs .slick-arrow:before {
  color: #90b947;
  border-color: #90b947; }

.theme-Roofs .slick-arrow:hover:before, .theme-Roofs .slick-arrow:focus:before {
  background-color: #90b947;
  color: #fff; }

.theme-Roofs .box-product-social-button:before,
.theme-Roofs .product-social-list-icon:before {
  color: #90b947 !important; }

.theme-Roofs .box-product-social-button:hover:after, .theme-Roofs .box-product-social-button:focus:after, .theme-Roofs .box-product-social-button.open:after,
.theme-Roofs .product-social-list-icon:hover:after,
.theme-Roofs .product-social-list-icon:focus:after,
.theme-Roofs .product-social-list-icon.open:after {
  border-color: #90b947 !important; }

.theme-Roofs.content-tabs-title,
.theme-Roofs.content-tabs-title a {
  color: #90b947;
  border-color: #90b947; }

.theme-Crowd-Barriers h2:not(.title-line-slim),
.theme-Crowd-Barriers .title-line {
  color: #d64546;
  border-color: #d64546; }

.theme-Crowd-Barriers .content-tabs-title-link {
  color: #d64546; }
  .theme-Crowd-Barriers .content-tabs-title-link * {
    color: #d64546; }

.theme-Crowd-Barriers .contact-panel-btn {
  background-color: #d64546; }

.theme-Crowd-Barriers .slick-arrow:before {
  color: #d64546;
  border-color: #d64546; }

.theme-Crowd-Barriers .slick-arrow:hover:before, .theme-Crowd-Barriers .slick-arrow:focus:before {
  background-color: #d64546;
  color: #fff; }

.theme-Crowd-Barriers .box-product-social-button:before,
.theme-Crowd-Barriers .product-social-list-icon:before {
  color: #d64546 !important; }

.theme-Crowd-Barriers .box-product-social-button:hover:after, .theme-Crowd-Barriers .box-product-social-button:focus:after, .theme-Crowd-Barriers .box-product-social-button.open:after,
.theme-Crowd-Barriers .product-social-list-icon:hover:after,
.theme-Crowd-Barriers .product-social-list-icon:focus:after,
.theme-Crowd-Barriers .product-social-list-icon.open:after {
  border-color: #d64546 !important; }

.theme-Crowd-Barriers.content-tabs-title,
.theme-Crowd-Barriers.content-tabs-title a {
  color: #d64546;
  border-color: #d64546; }

.theme-Circles h2:not(.title-line-slim),
.theme-Circles .title-line {
  color: #51599e;
  border-color: #51599e; }

.theme-Circles .content-tabs-title-link {
  color: #51599e; }
  .theme-Circles .content-tabs-title-link * {
    color: #51599e; }

.theme-Circles .contact-panel-btn {
  background-color: #51599e; }

.theme-Circles .slick-arrow:before {
  color: #51599e;
  border-color: #51599e; }

.theme-Circles .slick-arrow:hover:before, .theme-Circles .slick-arrow:focus:before {
  background-color: #51599e;
  color: #fff; }

.theme-Circles .box-product-social-button:before,
.theme-Circles .product-social-list-icon:before {
  color: #51599e !important; }

.theme-Circles .box-product-social-button:hover:after, .theme-Circles .box-product-social-button:focus:after, .theme-Circles .box-product-social-button.open:after,
.theme-Circles .product-social-list-icon:hover:after,
.theme-Circles .product-social-list-icon:focus:after,
.theme-Circles .product-social-list-icon.open:after {
  border-color: #51599e !important; }

.theme-Circles.content-tabs-title,
.theme-Circles.content-tabs-title a {
  color: #51599e;
  border-color: #51599e; }

.theme-Clamps h2:not(.title-line-slim),
.theme-Clamps .title-line {
  color: #b93b6b;
  border-color: #b93b6b; }

.theme-Clamps .content-tabs-title-link {
  color: #b93b6b; }
  .theme-Clamps .content-tabs-title-link * {
    color: #b93b6b; }

.theme-Clamps .contact-panel-btn {
  background-color: #b93b6b; }

.theme-Clamps .slick-arrow:before {
  color: #b93b6b;
  border-color: #b93b6b; }

.theme-Clamps .slick-arrow:hover:before, .theme-Clamps .slick-arrow:focus:before {
  background-color: #b93b6b;
  color: #fff; }

.theme-Clamps .box-product-social-button:before,
.theme-Clamps .product-social-list-icon:before {
  color: #b93b6b !important; }

.theme-Clamps .box-product-social-button:hover:after, .theme-Clamps .box-product-social-button:focus:after, .theme-Clamps .box-product-social-button.open:after,
.theme-Clamps .product-social-list-icon:hover:after,
.theme-Clamps .product-social-list-icon:focus:after,
.theme-Clamps .product-social-list-icon.open:after {
  border-color: #b93b6b !important; }

.theme-Clamps.content-tabs-title,
.theme-Clamps.content-tabs-title a {
  color: #b93b6b;
  border-color: #b93b6b; }

.theme-Towers h2:not(.title-line-slim),
.theme-Towers .title-line {
  color: #b63264;
  border-color: #b63264; }

.theme-Towers .content-tabs-title-link {
  color: #b63264; }
  .theme-Towers .content-tabs-title-link * {
    color: #b63264; }

.theme-Towers .contact-panel-btn {
  background-color: #b63264; }

.theme-Towers .slick-arrow:before {
  color: #b63264;
  border-color: #b63264; }

.theme-Towers .slick-arrow:hover:before, .theme-Towers .slick-arrow:focus:before {
  background-color: #b63264;
  color: #fff; }

.theme-Towers .box-product-social-button:before,
.theme-Towers .product-social-list-icon:before {
  color: #b63264 !important; }

.theme-Towers .box-product-social-button:hover:after, .theme-Towers .box-product-social-button:focus:after, .theme-Towers .box-product-social-button.open:after,
.theme-Towers .product-social-list-icon:hover:after,
.theme-Towers .product-social-list-icon:focus:after,
.theme-Towers .product-social-list-icon.open:after {
  border-color: #b63264 !important; }

.theme-Towers.content-tabs-title,
.theme-Towers.content-tabs-title a {
  color: #b63264;
  border-color: #b63264; }

.theme-Cablecross h2:not(.title-line-slim),
.theme-Cablecross .title-line {
  color: #8d65a1;
  border-color: #8d65a1; }

.theme-Cablecross .content-tabs-title-link {
  color: #8d65a1; }
  .theme-Cablecross .content-tabs-title-link * {
    color: #8d65a1; }

.theme-Cablecross .contact-panel-btn {
  background-color: #8d65a1; }

.theme-Cablecross .slick-arrow:before {
  color: #8d65a1;
  border-color: #8d65a1; }

.theme-Cablecross .slick-arrow:hover:before, .theme-Cablecross .slick-arrow:focus:before {
  background-color: #8d65a1;
  color: #fff; }

.theme-Cablecross .box-product-social-button:before,
.theme-Cablecross .product-social-list-icon:before {
  color: #8d65a1 !important; }

.theme-Cablecross .box-product-social-button:hover:after, .theme-Cablecross .box-product-social-button:focus:after, .theme-Cablecross .box-product-social-button.open:after,
.theme-Cablecross .product-social-list-icon:hover:after,
.theme-Cablecross .product-social-list-icon:focus:after,
.theme-Cablecross .product-social-list-icon.open:after {
  border-color: #8d65a1 !important; }

.theme-Cablecross.content-tabs-title,
.theme-Cablecross.content-tabs-title a {
  color: #8d65a1;
  border-color: #8d65a1; }

.theme-Rigging-Accessories h2:not(.title-line-slim),
.theme-Rigging-Accessories .title-line {
  color: #de7130;
  border-color: #de7130; }

.theme-Rigging-Accessories .content-tabs-title-link {
  color: #de7130; }
  .theme-Rigging-Accessories .content-tabs-title-link * {
    color: #de7130; }

.theme-Rigging-Accessories .contact-panel-btn {
  background-color: #de7130; }

.theme-Rigging-Accessories .slick-arrow:before {
  color: #de7130;
  border-color: #de7130; }

.theme-Rigging-Accessories .slick-arrow:hover:before, .theme-Rigging-Accessories .slick-arrow:focus:before {
  background-color: #de7130;
  color: #fff; }

.theme-Rigging-Accessories .box-product-social-button:before,
.theme-Rigging-Accessories .product-social-list-icon:before {
  color: #de7130 !important; }

.theme-Rigging-Accessories .box-product-social-button:hover:after, .theme-Rigging-Accessories .box-product-social-button:focus:after, .theme-Rigging-Accessories .box-product-social-button.open:after,
.theme-Rigging-Accessories .product-social-list-icon:hover:after,
.theme-Rigging-Accessories .product-social-list-icon:focus:after,
.theme-Rigging-Accessories .product-social-list-icon.open:after {
  border-color: #de7130 !important; }

.theme-Rigging-Accessories.content-tabs-title,
.theme-Rigging-Accessories.content-tabs-title a {
  color: #de7130;
  border-color: #de7130; }

.theme-LitecCad h2:not(.title-line-slim),
.theme-LitecCad .title-line {
  color: #73beca;
  border-color: #73beca; }

.theme-LitecCad .content-tabs-title-link {
  color: #73beca; }
  .theme-LitecCad .content-tabs-title-link * {
    color: #73beca; }

.theme-LitecCad .contact-panel-btn {
  background-color: #73beca; }

.theme-LitecCad .slick-arrow:before {
  color: #73beca;
  border-color: #73beca; }

.theme-LitecCad .slick-arrow:hover:before, .theme-LitecCad .slick-arrow:focus:before {
  background-color: #73beca;
  color: #fff; }

.theme-LitecCad .box-product-social-button:before,
.theme-LitecCad .product-social-list-icon:before {
  color: #73beca !important; }

.theme-LitecCad .box-product-social-button:hover:after, .theme-LitecCad .box-product-social-button:focus:after, .theme-LitecCad .box-product-social-button.open:after,
.theme-LitecCad .product-social-list-icon:hover:after,
.theme-LitecCad .product-social-list-icon:focus:after,
.theme-LitecCad .product-social-list-icon.open:after {
  border-color: #73beca !important; }

.theme-LitecCad.content-tabs-title,
.theme-LitecCad.content-tabs-title a {
  color: #73beca;
  border-color: #73beca; }

.theme-Ramps h2:not(.title-line-slim),
.theme-Ramps .title-line {
  color: #eeca44;
  border-color: #eeca44; }

.theme-Ramps .content-tabs-title-link {
  color: #eeca44; }
  .theme-Ramps .content-tabs-title-link * {
    color: #eeca44; }

.theme-Ramps .contact-panel-btn {
  background-color: #eeca44; }

.theme-Ramps .slick-arrow:before {
  color: #eeca44;
  border-color: #eeca44; }

.theme-Ramps .slick-arrow:hover:before, .theme-Ramps .slick-arrow:focus:before {
  background-color: #eeca44;
  color: #fff; }

.theme-Ramps .box-product-social-button:before,
.theme-Ramps .product-social-list-icon:before {
  color: #eeca44 !important; }

.theme-Ramps .box-product-social-button:hover:after, .theme-Ramps .box-product-social-button:focus:after, .theme-Ramps .box-product-social-button.open:after,
.theme-Ramps .product-social-list-icon:hover:after,
.theme-Ramps .product-social-list-icon:focus:after,
.theme-Ramps .product-social-list-icon.open:after {
  border-color: #eeca44 !important; }

.theme-Ramps.content-tabs-title,
.theme-Ramps.content-tabs-title a {
  color: #eeca44;
  border-color: #eeca44; }

.theme-Xstage h2:not(.title-line-slim),
.theme-Xstage .title-line {
  color: #dfb3d0;
  border-color: #dfb3d0; }

.theme-Xstage .content-tabs-title-link {
  color: #dfb3d0; }
  .theme-Xstage .content-tabs-title-link * {
    color: #dfb3d0; }

.theme-Xstage .contact-panel-btn {
  background-color: #dfb3d0; }

.theme-Xstage .slick-arrow:before {
  color: #dfb3d0;
  border-color: #dfb3d0; }

.theme-Xstage .slick-arrow:hover:before, .theme-Xstage .slick-arrow:focus:before {
  background-color: #dfb3d0;
  color: #fff; }

.theme-Xstage .box-product-social-button:before,
.theme-Xstage .product-social-list-icon:before {
  color: #dfb3d0 !important; }

.theme-Xstage .box-product-social-button:hover:after, .theme-Xstage .box-product-social-button:focus:after, .theme-Xstage .box-product-social-button.open:after,
.theme-Xstage .product-social-list-icon:hover:after,
.theme-Xstage .product-social-list-icon:focus:after,
.theme-Xstage .product-social-list-icon.open:after {
  border-color: #dfb3d0 !important; }

.theme-Xstage.content-tabs-title,
.theme-Xstage.content-tabs-title a {
  color: #dfb3d0;
  border-color: #dfb3d0; }

.dot-list ul li {
  padding-bottom: 4px; }
  .dot-list ul li:before {
    content: "";
    width: 2px;
    height: 2px;
    background-color: #043a63;
    border-radius: 50%;
    left: 0;
    top: 14px; }

.underline-title {
  font-size: 20px;
  padding: 0 20px;
  display: inline-block;
  border-bottom: 1px solid #fff; }
  @media (min-width: 768px) {
    .underline-title {
      font-size: 26px;
      padding: 0 30px; } }
  @media (min-width: 1200px) {
    .underline-title {
      font-size: 30px; } }

.product-preview {
  padding: 0 0 30px 0; }
  @media (min-width: 480px) {
    .product-preview {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between; } }
  .product-preview-image {
    width: 100%; }
    @media (min-width: 480px) {
      .product-preview-image {
        width: 40%; } }
    @media (min-width: 768px) {
      .product-preview-image {
        width: 50%; } }
    @media (min-width: 992px) {
      .product-preview-image {
        width: 50%; } }
    .product-preview-image .img-cont {
      position: relative;
      height: 0;
      padding-bottom: -webkit-calc(100% - 10px);
      padding-bottom: -moz-calc(100% - 10px);
      padding-bottom: calc(100% - 10px);
      overflow: hidden; }
      .product-preview-image .img-cont .box-product-social {
        position: absolute;
        top: 7px;
        left: 5px; }
        .product-preview-image .img-cont .box-product-social .product-social-list {
          position: absolute;
          top: 40px;
          left: -5px;
          width: 60px;
          height: 200px;
          opacity: 0;
          background-color: transparent;
          overflow: hidden;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          display: none;
          -moz-flex-flow: column wrap;
          -ms-flex-direction: column;
          -ms-flex-wrap: wrap;
          -webkit-flex-flow: column wrap;
          flex-flow: column wrap; }
          @media (min-width: 480px) {
            .product-preview-image .img-cont .box-product-social .product-social-list {
              top: 35px;
              width: 80px;
              height: 140px; } }
          @media (min-width: 768px) {
            .product-preview-image .img-cont .box-product-social .product-social-list {
              width: 80px;
              height: 150px; } }
          @media (min-width: 992px) {
            .product-preview-image .img-cont .box-product-social .product-social-list {
              height: 200px; } }
          @media (min-width: 1400px) {
            .product-preview-image .img-cont .box-product-social .product-social-list {
              height: 300px; } }
          .product-preview-image .img-cont .box-product-social .product-social-list.open {
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            opacity: 1;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; }
          .product-preview-image .img-cont .box-product-social .product-social-list-icon {
            display: block;
            width: 30px;
            height: 30px;
            font-size: 0px;
            font-size: 0rem;
            text-decoration: none;
            position: relative; }
            @media (min-width: 545px) {
              .product-preview-image .img-cont .box-product-social .product-social-list-icon {
                width: 37px;
                height: 37px; } }
            .product-preview-image .img-cont .box-product-social .product-social-list-icon:before {
              font-family: 'icomoon' !important;
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-size: 23px;
              font-size: 1.4375rem;
              position: absolute;
              top: 50%;
              left: 50%;
              -ms-transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              color: #043a63;
              cursor: pointer; }
              @media (min-width: 545px) {
                .product-preview-image .img-cont .box-product-social .product-social-list-icon:before {
                  font-size: 27px;
                  font-size: 1.6875rem; } }
            .product-preview-image .img-cont .box-product-social .product-social-list-icon:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              -ms-transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              color: #043a63;
              -moz-transition: all ease-in-out 200ms;
              -o-transition: all ease-in-out 200ms;
              -webkit-transition: all ease-in-out 200ms;
              transition: all ease-in-out 200ms;
              cursor: pointer;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 1px solid transparent; }
              @media (min-width: 545px) {
                .product-preview-image .img-cont .box-product-social .product-social-list-icon:after {
                  width: 35px;
                  height: 35px; } }
            .product-preview-image .img-cont .box-product-social .product-social-list-icon:focus:after, .product-preview-image .img-cont .box-product-social .product-social-list-icon:hover:after {
              -moz-transition: all ease-in-out 200ms;
              -o-transition: all ease-in-out 200ms;
              -webkit-transition: all ease-in-out 200ms;
              transition: all ease-in-out 200ms;
              border-color: #043a63; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-google:before {
            content: ""; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-tumblr:before {
            content: ""; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-facebook:before {
            content: ""; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-mail:before {
            content: ""; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-twitter:before {
            content: ""; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-linkedin:before {
            content: ""; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-vine:before {
            content: ""; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-youtube:before {
            content: ""; }
          .product-preview-image .img-cont .box-product-social .product-social-list .s-reddit:before {
            content: ""; }
        .product-preview-image .img-cont .box-product-social .box-product-social-button {
          position: relative;
          display: inline-block;
          color: inherit;
          font-size: 0px;
          font-size: 0rem;
          width: 30px;
          height: 30px;
          color: #043a63;
          cursor: pointer; }
          .product-preview-image .img-cont .box-product-social .box-product-social-button:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 22px;
            font-size: 1.375rem;
            -moz-transition: all ease-in-out 100ms;
            -o-transition: all ease-in-out 100ms;
            -webkit-transition: all ease-in-out 100ms;
            transition: all ease-in-out 100ms;
            cursor: pointer;
            content: "\e904"; }
          .product-preview-image .img-cont .box-product-social .box-product-social-button:after {
            content: "";
            position: absolute;
            top: 38%;
            left: 42%;
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #043a63;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid transparent; }
            @media (min-width: 545px) {
              .product-preview-image .img-cont .box-product-social .box-product-social-button:after {
                width: 35px;
                height: 35px; } }
          .product-preview-image .img-cont .box-product-social .box-product-social-button:focus:after, .product-preview-image .img-cont .box-product-social .box-product-social-button:hover:after {
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            border-color: #043a63; }
          .product-preview-image .img-cont .box-product-social .box-product-social-button.open:after {
            border-color: #043a63; }
      .product-preview-image .img-cont .img-wrap:not(:first-of-type) {
        display: none; }
      .product-preview-image .img-cont img {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%; }
  .product-preview-contact {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: column nowrap;
    -ms-flex-direction: column;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap; }
    @media (min-width: 480px) {
      .product-preview-contact {
        width: -webkit-calc(60% - 20px);
        width: -moz-calc(60% - 20px);
        width: calc(60% - 20px);
        margin-top: 0px; } }
    @media (min-width: 480px) {
      .product-preview-contact {
        display: block; } }
    @media (min-width: 768px) {
      .product-preview-contact {
        width: -webkit-calc(50% - 20px);
        width: -moz-calc(50% - 20px);
        width: calc(50% - 20px); } }
    @media (min-width: 992px) {
      .product-preview-contact {
        width: -webkit-calc(50% - 35px);
        width: -moz-calc(50% - 35px);
        width: calc(50% - 35px); } }
    .product-preview-contact-perex {
      order: 2;
      line-height: 18px;
      margin-top: 20px; }
      @media (min-width: 480px) {
        .product-preview-contact-perex {
          margin-top: 0px;
          margin-bottom: 30px; } }
    .product-preview-contact-types {
      order: 3;
      margin-top: 8px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between; }
      @media (min-width: 480px) {
        .product-preview-contact-types {
          border-bottom: 0; } }
      .product-preview-contact-types .product-line {
        width: 100%;
        height: 1px;
        background: #043a63; }
      .product-preview-contact-types-format, .product-preview-contact-types-load {
        width: 45%;
        padding: 10px 0 14px; }
        @media (min-width: 480px) {
          .product-preview-contact-types-format, .product-preview-contact-types-load {
            width: 48%;
            padding: 13px 0 7px; } }
        @media (min-width: 1200px) {
          .product-preview-contact-types-format, .product-preview-contact-types-load {
            width: 53%;
            padding: 16px 0 12px; } }
        .product-preview-contact-types-format-title, .product-preview-contact-types-load-title {
          font-size: 15px;
          font-size: 0.9375rem;
          font-weight: 300; }
          @media (min-width: 545px) and (max-width: 767px) {
            .product-preview-contact-types-format-title, .product-preview-contact-types-load-title {
              font-size: 18px;
              font-size: 1.125rem; } }
          @media (min-width: 992px) {
            .product-preview-contact-types-format-title, .product-preview-contact-types-load-title {
              font-size: 18px;
              font-size: 1.125rem; } }
        .product-preview-contact-types-format a, .product-preview-contact-types-load a {
          display: inline-block;
          color: #000;
          text-decoration: none;
          padding: 2px 0;
          margin-top: 2px;
          -moz-transition: all ease-in-out 100ms;
          -o-transition: all ease-in-out 100ms;
          -webkit-transition: all ease-in-out 100ms;
          transition: all ease-in-out 100ms;
          border-bottom: 1px solid transparent;
          font-size: 13px;
          font-size: 0.8125rem; }
          @media (min-width: 545px) and (max-width: 767px) {
            .product-preview-contact-types-format a, .product-preview-contact-types-load a {
              font-size: 15px;
              font-size: 0.9375rem; } }
          @media (min-width: 992px) {
            .product-preview-contact-types-format a, .product-preview-contact-types-load a {
              font-size: 15px;
              font-size: 0.9375rem; } }
          @media (min-width: 1200px) {
            .product-preview-contact-types-format a, .product-preview-contact-types-load a {
              font-size: 17px;
              font-size: 1.0625rem;
              margin-top: 5px; } }
          .product-preview-contact-types-format a:not(:last-of-type), .product-preview-contact-types-load a:not(:last-of-type) {
            margin-right: 8px; }
            @media (min-width: 992px) {
              .product-preview-contact-types-format a:not(:last-of-type), .product-preview-contact-types-load a:not(:last-of-type) {
                margin-right: 15px; } }
            @media (min-width: 1200px) {
              .product-preview-contact-types-format a:not(:last-of-type), .product-preview-contact-types-load a:not(:last-of-type) {
                margin-right: 18px; } }
          .product-preview-contact-types-format a:hover, .product-preview-contact-types-format a:focus, .product-preview-contact-types-format a.active, .product-preview-contact-types-load a:hover, .product-preview-contact-types-load a:focus, .product-preview-contact-types-load a.active {
            border-bottom: 1px solid #000;
            text-decoration: none !important; }
      @media (min-width: 1200px) {
        .product-preview-contact-types-load {
          width: 47%; } }
    .product-preview-contact-colors {
      order: 0;
      position: relative;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      padding: 0 2px;
      margin: 12px 0;
      margin-top: 20px; }
      @media (min-width: 992px) {
        .product-preview-contact-colors {
          padding: 14px 0; } }
      @media (min-width: 1200px) {
        .product-preview-contact-colors {
          padding: 8px 5px; } }
      .product-preview-contact-colors:before {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #043a63;
        z-index: 1; }
        @media (min-width: 545px) {
          .product-preview-contact-colors:before {
            content: "";
            position: absolute;
            top: -12px;
            left: 0px; } }
        @media (min-width: 992px) {
          .product-preview-contact-colors:before {
            top: -10px; } }
      .product-preview-contact-colors-item {
        width: 31px;
        height: 31px;
        border-radius: 31px;
        cursor: pointer; }
        .product-preview-contact-colors-item:not(:first-of-type) {
          margin-left: 15px; }
        @media (min-width: 1200px) {
          .product-preview-contact-colors-item {
            width: 39px;
            height: 39px; }
            .product-preview-contact-colors-item:not(:first-of-type) {
              margin-left: 22px; } }
    .product-preview-contact .product-contact .contact-box-button {
      width: -webkit-calc(100% - 2px);
      width: -moz-calc(100% - 2px);
      width: calc(100% - 2px);
      position: relative;
      right: 0px;
      top: 0px;
      width: auto;
      font-size: 13px;
      font-size: 0.8125rem; }
      @media (min-width: 480px) {
        .product-preview-contact .product-contact .contact-box-button {
          position: static;
          width: auto;
          min-width: 128px;
          font-size: 14px;
          font-size: 0.875rem; } }
      @media (min-width: 992px) {
        .product-preview-contact .product-contact .contact-box-button {
          min-width: 123px;
          position: absolute;
          top: auto;
          right: 10px; } }
      @media (min-width: 992px) and (max-width: 1024px) {
        .product-preview-contact .product-contact .contact-box-button {
          min-width: 100px;
          width: 110px; } }
      @media (min-width: 1200px) {
        .product-preview-contact .product-contact .contact-box-button {
          min-width: 140px;
          position: absolute; } }

@keyframes loading-animate {
  100% {
    transform: rotate(360deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

.loading-animation:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 35px;
  font-size: 2.1875rem;
  position: absolute;
  content: "\e922";
  color: gray;
  top: 47%;
  left: 48%;
  animation: loading-animate 1s linear infinite;
  z-index: 200; }
  @media (min-width: 320px) and (max-width: 991px) {
    .loading-animation:after {
      top: 43%;
      left: 48%; } }

.product-black {
  background-color: #000; }

.product-grey {
  background-color: #919191; }

.product-card {
  line-height: 18px; }
  .product-card-title {
    margin-bottom: 0px; }
  .product-card ul {
    list-style-type: none;
    padding: 0px; }
    .product-card ul li {
      position: relative;
      padding-left: 7px;
      padding-bottom: 10px;
      line-height: 18px; }
      .product-card ul li:before {
        content: "";
        position: absolute;
        top: 7px;
        left: -1px;
        display: block;
        width: 4px;
        height: 4px;
        background-color: #000;
        border-radius: 4px; }

.product-technical-info {
  padding: 10px 0;
  line-height: 20px; }
  @media (min-width: 545px) {
    .product-technical-info {
      padding: 0; } }

.product-basic-info,
.product-technical-info {
  font-size: 14px;
  font-size: 0.875rem; }
  .product-basic-info h2,
  .product-technical-info h2 {
    margin-bottom: 10px; }
  .product-basic-info h3,
  .product-basic-info h4,
  .product-technical-info h3,
  .product-technical-info h4 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 21px;
    font-weight: 300;
    margin: 0px 0 15px !important; }
    @media (min-width: 545px) {
      .product-basic-info h3,
      .product-basic-info h4,
      .product-technical-info h3,
      .product-technical-info h4 {
        margin: 10px 0 30px !important; } }
  .product-basic-info .col-sm-6:first-of-type,
  .product-technical-info .col-sm-6:first-of-type {
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px; }
    @media (min-width: 545px) {
      .product-basic-info .col-sm-6:first-of-type,
      .product-technical-info .col-sm-6:first-of-type {
        margin-bottom: 0px;
        padding-top: 0px;
        padding-bottom: 0px; } }
  .product-basic-info .download-item h3,
  .product-technical-info .download-item h3 {
    padding: 0 !important;
    margin: 0 !important; }

.product-basic-info {
  margin-top: 20px; }
  @media (min-width: 545px) {
    .product-basic-info {
      margin-top: 30px; } }
  .product-basic-info ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .product-basic-info ul li {
      position: relative;
      padding-left: 20px;
      padding-bottom: 15px; }
      .product-basic-info ul li:before {
        position: absolute;
        content: ">";
        margin-right: 5px;
        left: 5px;
        top: 2px; }

.product-table table {
  width: 100%;
  display: block;
  overflow-x: auto;
  margin-top: 15px;
  padding-right: 1px;
  padding-bottom: 5px; }
  @media (min-width: 545px) {
    .product-table table {
      display: table;
      overflow-x: hidden; } }
  .product-table table tr:first-child {
    background-color: #ffd100;
    font-weight: 300; }
  .product-table table tr:not(:first-of-type) td:first-child:not([colspan]) {
    font-weight: 300;
    border-right: 1px solid #000;
    padding: 2px 3px; }
  .product-table table-title td {
    border-right: none !important; }
  .product-table table td {
    border: none;
    border-right: 1px solid #dedede;
    border-bottom: 1px solid #000; }
  .product-table table tr td {
    padding: 2px 10px;
    border: 1px solid #dedede; }
    @media (min-width: 545px) {
      .product-table table tr td {
        padding: 2px 3px; } }

.basic-table table {
  width: 100%;
  display: block;
  overflow-x: auto;
  margin-top: 15px;
  padding-right: 1px;
  padding-bottom: 5px; }
  @media (min-width: 545px) {
    .basic-table table {
      display: table;
      overflow-x: hidden; } }
  .basic-table table tr:first-of-type {
    font-weight: bold;
    background-color: #ffd100;
    text-align: center; }
  .basic-table table th,
  .basic-table table tr:nth-of-type(2) {
    font-weight: bold; }
  .basic-table table tr td {
    padding: 3px 5px;
    line-height: 18px; }
  .basic-table table tr {
    border-bottom: 1px solid #dedede; }

.info-table table {
  width: 100%;
  display: block;
  overflow-x: auto;
  margin-top: 15px;
  padding-right: 1px;
  padding-bottom: 5px;
  font-size: 11px;
  font-size: 0.6875rem; }
  @media (min-width: 545px) {
    .info-table table {
      display: table;
      overflow-x: hidden; } }
  .info-table table tr {
    border-bottom: 1px solid #000;
    background-color: #f5f4f4;
    vertical-align: middle;
    padding: 3px 1px 3px 2px !important; }
  .info-table table tr td {
    vertical-align: middle;
    padding: 3px 1px 3px 2px !important;
    border: 0 !important; }
  .info-table table th {
    background-color: #ffd100;
    font-weight: 500;
    color: #010101;
    height: 25px;
    font-size: 15px;
    font-size: 0.9375rem;
    text-align: right;
    padding: 2px 5px 0 0;
    vertical-align: middle; }
  .info-table table tr:not(:first-of-type) td:first-child:not([colspan]) {
    font-weight: 400; }
  .info-table table tr:first-of-type {
    border-bottom: 1px solid #fff !important; }
  .info-table table .gray-row td {
    border: none !important;
    border-left: 1px solid #fff !important;
    border-top: 1px solid #fff !important; }

.gray-cell {
  background-color: #e0e0e0 !important;
  color: #010101;
  text-align: center;
  width: 90px; }

.gray-row {
  background-color: #e0e0e0 !important;
  color: #010101;
  text-align: left;
  background-color: #ffd100;
  font-weight: 500;
  color: #010101;
  height: 25px;
  padding: 2px 5px 0 0;
  vertical-align: middle; }
  .gray-row td {
    border: none !important;
    border-left: 1px solid #fff !important; }
    .gray-row td:first-of-type {
      text-align: center;
      width: 90px; }

.text-info-text {
  padding: 0 0 20px 0; }

/*.tech-info{
    padding: 5px 0 25px;
    @include flex-block;
    @include flex-wrap(wrap);
    margin:0px -15px 0 -15px;
    &-item{
        padding:0 15px;
        width:50%;
    }
    dl{
        @include flex-block();
        @include flex-flow();
        margin-bottom: 5px;
        border-bottom: 1px solid $color-black;
        dt,
        dd{
            width: 50%;
            background-color: $color-blue-bg;
            padding: 3px 5px;
            @include font-size(13px);

            @include media(md) {
              padding: 3px 10px;
              @include font-size(14px);
            }
        }
        dd{
            background-color: $color-white;
            text-align: right;
            line-height: 18px;
        }
    }
}*/
.tech-info {
  padding: 5px 0 25px;
  font-size: 0;
  column-count: 1;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-gap: 22px;
  -webkit-column-gap: 22px;
  -moz-column-gap: 22px; }
  .tech-info dl {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 5px;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    border-bottom: 1px solid #000; }
  .tech-info dt {
    background: #7f9bb0; }
  .tech-info dt, .tech-info dd {
    display: table-cell;
    vertical-align: top;
    padding: 4px 10px;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400; }
  .tech-info dd {
    text-align: right;
    padding-right: 0; }
  .no-columns .tech-info {
    column-count: 1;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-gap: 0;
    -webkit-column-gap: 0;
    -moz-column-gap: 0; }
  @media (min-width: 545px) {
    .tech-info {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2; }
      .tech-info dt, .tech-info dd {
        font-size: 13px; } }
  @media (min-width: 768px) {
    .tech-info {
      column-count: 1;
      -webkit-column-count: 1;
      -moz-column-count: 1; }
      .tech-info dt, .tech-info dd {
        font-size: 14px; } }
  @media (min-width: 992px) {
    .tech-info {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2; }
      .tech-info dt, .tech-info dd {
        font-size: 13px; } }
  @media (min-width: 1200px) {
    .tech-info {
      column-gap: 14px;
      -webkit-column-gap: 14px;
      -moz-column-gap: 14px; }
      .tech-info dt, .tech-info dd {
        padding: 4px 5px; }
      .tech-info dt {
        width: 43%; }
      .tech-info dd {
        width: 57%; } }

.row .tech-info-item {
  width: 100%; }
  @media (min-width: 545px) {
    .row .tech-info-item {
      width: 50%; } }
  @media (min-width: 768px) {
    .row .tech-info-item {
      width: 100%; } }
  @media (min-width: 992px) {
    .row .tech-info-item {
      width: 50%; } }

.tech-contact .contact-box .contact-box-button {
  position: static; }
  @media (min-width: 992px) {
    .tech-contact .contact-box .contact-box-button {
      right: 10px;
      position: absolute;
      min-width: 123px; } }
  @media (min-width: 1200px) {
    .tech-contact .contact-box .contact-box-button {
      min-width: 155px; } }

.product-video-holder {
  position: relative;
  margin-bottom: 20px;
  margin-top: 15px; }
  @media (min-width: 768px) {
    .product-video-holder {
      margin-top: 0;
      margin-bottom: 30px; } }
  .product-video-holder:before {
    content: "";
    display: block;
    padding-bottom: 56.3%; }
  .product-video-holder iframe {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.booking-form {
  padding: 10px;
  background-color: #e8edf1; }
  @media (min-width: 545px) {
    .booking-form {
      padding: 15px; } }
  .booking-form h2 {
    padding-top: 0; }
  .booking-form label {
    display: none; }
  .booking-form input[type="text"],
  .booking-form textarea {
    border: 1px solid #043a63 !important;
    color: #043a63 !important;
    background-color: inherit !important;
    border-radius: 0;
    margin: 10px 0;
    resize: none;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 300; }
  .booking-form input[type="submit"] {
    background-color: inherit; }
  .booking-form input::-webkit-input-placeholder {
    text-align: center;
    color: #043a63; }
  .booking-form input:-moz-placeholder {
    text-align: center;
    color: #043a63; }
  .booking-form input::-moz-placeholder {
    text-align: center;
    color: #043a63; }
  .booking-form input:-ms-input-placeholder {
    text-align: left;
    color: #043a63; }
  .booking-form textarea::-webkit-input-placeholder {
    text-align: left;
    color: #043a63; }
  .booking-form textarea:-moz-placeholder {
    text-align: left;
    color: #043a63; }
  .booking-form textarea::-moz-placeholder {
    text-align: left;
    color: #043a63; }
  .booking-form textarea:-ms-input-placeholder {
    text-align: left;
    color: #043a63; }
  .booking-form textarea {
    min-height: 150px;
    line-height: 18px; }
  .booking-form .show-more-wraper {
    margin-top: 10px; }
  .booking-form .ErrorLabel,
  .booking-form .EditingFormErrorLabel {
    font-weight: bolder;
    color: #e20b33; }

.side-newsletter {
  padding: 10px;
  background-color: #e8edf1;
  color: #043a63;
  margin: 30px 0 40px; }
  @media (min-width: 545px) {
    .side-newsletter {
      padding: 15px;
      margin: 50px 0; } }
  .side-newsletter-text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    padding-bottom: 5px; }
  .side-newsletter-small-text {
    font-size: 10px;
    font-size: 0.625rem;
    line-height: 14px; }
    .side-newsletter-small-text a {
      text-decoration: none; }
      .side-newsletter-small-text a:focus, .side-newsletter-small-text a:hover {
        text-decoration: underline; }
  .side-newsletter input[type="text"] {
    border: 1px solid #043a63 !important;
    color: #043a63 !important;
    background-color: inherit !important;
    border-radius: 0;
    margin: 10px 0;
    resize: none;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 300;
    text-align: center; }
  .side-newsletter input[type="submit"] {
    background-color: inherit;
    margin-bottom: 15px;
    width: 100%;
    padding: 7px 0;
    font-size: 15px;
    font-size: 0.9375rem;
    border-color: #7f9bb0;
    font-weight: 300; }
  .side-newsletter .ErrorLabel,
  .side-newsletter .EditingFormErrorLabel {
    font-weight: bolder;
    display: block;
    padding-bottom: 10px;
    color: #e20b33; }

@media (min-width: 768px) {
  .newsletter-mt {
    margin-top: 100px; } }

.contact-tabs {
  margin-top: 50px; }
  @media (min-width: 768px) {
    .contact-tabs {
      margin-top: 70px; } }

.contact-menu-item {
  display: block;
  width: 100%;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 5px;
  cursor: pointer;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease; }
  @media (min-width: 768px) {
    .contact-menu-item {
      font-size: 26px;
      font-size: 1.625rem;
      padding: 9px 10px;
      line-height: 30px; } }
  .contact-menu-item.active {
    background-color: #043a63;
    color: #fff; }

.contact-tab-list {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px; }

.contact-card {
  -webkit-flex: 0 0 100%;
  -moz-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  -webkit-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  font-weight: 300;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .contact-card {
      -webkit-flex: 0 0 50%;
      -moz-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      -webkit-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .contact-card {
      -webkit-flex: 0 0 33.33333%;
      -moz-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
      -webkit-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.333333%; } }
  .contact-card-title {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 10px 25px 10px 10px;
    background-color: #e8edf1;
    color: #043a63;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-size: 1.125rem;
    cursor: pointer;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      .contact-card-title {
        padding: 15px 25px 15px 10px; } }
    .contact-card-title .ico-holder {
      position: absolute;
      right: 0px;
      top: 50%;
      width: 15px;
      height: 25px;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .contact-card-title .ico-holder:after {
        position: absolute;
        right: 0;
        top: 0;
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e91f";
        font-size: 25px;
        font-size: 1.5625rem;
        color: #043a63;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%; }
  .contact-card:hover .contact-card-title, .contact-card:focus .contact-card-title {
    background-color: #043a63;
    color: #fff; }
    .contact-card:hover .contact-card-title .ico-holder:after, .contact-card:focus .contact-card-title .ico-holder:after {
      color: #fff; }
  .contact-card.active .contact-card-title {
    background-color: #043a63;
    color: #fff; }
    .contact-card.active .contact-card-title .ico-holder:after {
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      color: #fff; }
  .contact-card-content {
    display: none;
    padding: 15px 0;
    word-break: break-all; }
  .contact-card-name {
    font-size: 18px;
    font-size: 1.125rem;
    padding-bottom: 20px; }
  .contact-card-space {
    padding-top: 10px; }

.contact-map {
  width: 100%;
  padding-bottom: 60%; }
  @media (min-width: 545px) {
    .contact-map {
      margin-top: 20px;
      padding-bottom: 71%; } }

#Africa {
  margin-top: 30px; }
  @media (min-width: 768px) {
    #Africa {
      margin-top: 0; } }

.contact-text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  padding-bottom: 20px; }
  @media (min-width: 768px) {
    .contact-text {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 26px; } }
  @media (min-width: 992px) {
    .contact-text {
      padding-top: 20px;
      padding-bottom: 40px; } }

.contact-form {
  margin-top: 40px; }
  @media (min-width: 768px) {
    .contact-form {
      margin-top: 20px; } }
  .contact-form .contact-form-therms-cont {
    width: 100%;
    text-align: center;
    padding: 10px 10px 20px 10px; }
    @media (min-width: 768px) {
      .contact-form .contact-form-therms-cont {
        text-align: left; } }

.modal,
.product-registration-box {
  background-color: rgba(0, 0, 0, 0.4);
  color: #043a63;
  padding-right: 0 !important; }
  .modal .modal-dialog,
  .product-registration-box .modal-dialog {
    position: relative;
    min-height: 95%;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 100%; }
    .modal .modal-dialog .modal-content,
    .product-registration-box .modal-dialog .modal-content {
      background-color: #e8edf1;
      padding: 0;
      width: 100%;
      margin: auto;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      text-align: left; }
      @media (min-width: 768px) {
        .modal .modal-dialog .modal-content,
        .product-registration-box .modal-dialog .modal-content {
          width: 95%;
          margin-top: 10%; } }
      @media (min-width: 992px) {
        .modal .modal-dialog .modal-content,
        .product-registration-box .modal-dialog .modal-content {
          width: 920px;
          max-width: 95%; } }
      @media (min-width: 1200px) {
        .modal .modal-dialog .modal-content,
        .product-registration-box .modal-dialog .modal-content {
          width: 1100px; } }
      .modal .modal-dialog .modal-content label,
      .product-registration-box .modal-dialog .modal-content label {
        font-size: 15px;
        font-size: 0.9375rem;
        color: #043a63;
        margin-bottom: 7px;
        display: block;
        letter-spacing: 0;
        line-height: 22px; }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content label,
          .product-registration-box .modal-dialog .modal-content label {
            font-size: 18px;
            font-size: 1.125rem;
            line-height: 26px; } }
      .modal .modal-dialog .modal-content input[type="text"],
      .modal .modal-dialog .modal-content input[type="password"],
      .modal .modal-dialog .modal-content select,
      .modal .modal-dialog .modal-content textarea,
      .product-registration-box .modal-dialog .modal-content input[type="text"],
      .product-registration-box .modal-dialog .modal-content input[type="password"],
      .product-registration-box .modal-dialog .modal-content select,
      .product-registration-box .modal-dialog .modal-content textarea {
        background-color: #e8edf1;
        border: 1px solid #043a63 !important;
        width: 100%;
        padding: 8px 10px;
        height: 38px;
        color: #043a63 !important;
        margin-bottom: 15px;
        border-radius: 0 !important;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-weight: 200;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        .modal .modal-dialog .modal-content input[type="text"]::-webkit-input-placeholder,
        .modal .modal-dialog .modal-content input[type="password"]::-webkit-input-placeholder,
        .modal .modal-dialog .modal-content select::-webkit-input-placeholder,
        .modal .modal-dialog .modal-content textarea::-webkit-input-placeholder,
        .product-registration-box .modal-dialog .modal-content input[type="text"]::-webkit-input-placeholder,
        .product-registration-box .modal-dialog .modal-content input[type="password"]::-webkit-input-placeholder,
        .product-registration-box .modal-dialog .modal-content select::-webkit-input-placeholder,
        .product-registration-box .modal-dialog .modal-content textarea::-webkit-input-placeholder {
          color: #043a63; }
        .modal .modal-dialog .modal-content input[type="text"]:-moz-placeholder,
        .modal .modal-dialog .modal-content input[type="password"]:-moz-placeholder,
        .modal .modal-dialog .modal-content select:-moz-placeholder,
        .modal .modal-dialog .modal-content textarea:-moz-placeholder,
        .product-registration-box .modal-dialog .modal-content input[type="text"]:-moz-placeholder,
        .product-registration-box .modal-dialog .modal-content input[type="password"]:-moz-placeholder,
        .product-registration-box .modal-dialog .modal-content select:-moz-placeholder,
        .product-registration-box .modal-dialog .modal-content textarea:-moz-placeholder {
          color: #043a63; }
        .modal .modal-dialog .modal-content input[type="text"]::-moz-placeholder,
        .modal .modal-dialog .modal-content input[type="password"]::-moz-placeholder,
        .modal .modal-dialog .modal-content select::-moz-placeholder,
        .modal .modal-dialog .modal-content textarea::-moz-placeholder,
        .product-registration-box .modal-dialog .modal-content input[type="text"]::-moz-placeholder,
        .product-registration-box .modal-dialog .modal-content input[type="password"]::-moz-placeholder,
        .product-registration-box .modal-dialog .modal-content select::-moz-placeholder,
        .product-registration-box .modal-dialog .modal-content textarea::-moz-placeholder {
          color: #043a63; }
        .modal .modal-dialog .modal-content input[type="text"]:-ms-input-placeholder,
        .modal .modal-dialog .modal-content input[type="password"]:-ms-input-placeholder,
        .modal .modal-dialog .modal-content select:-ms-input-placeholder,
        .modal .modal-dialog .modal-content textarea:-ms-input-placeholder,
        .product-registration-box .modal-dialog .modal-content input[type="text"]:-ms-input-placeholder,
        .product-registration-box .modal-dialog .modal-content input[type="password"]:-ms-input-placeholder,
        .product-registration-box .modal-dialog .modal-content select:-ms-input-placeholder,
        .product-registration-box .modal-dialog .modal-content textarea:-ms-input-placeholder {
          color: #043a63; }
        .modal .modal-dialog .modal-content input[type="text"]:focus,
        .modal .modal-dialog .modal-content input[type="password"]:focus,
        .modal .modal-dialog .modal-content select:focus,
        .modal .modal-dialog .modal-content textarea:focus,
        .product-registration-box .modal-dialog .modal-content input[type="text"]:focus,
        .product-registration-box .modal-dialog .modal-content input[type="password"]:focus,
        .product-registration-box .modal-dialog .modal-content select:focus,
        .product-registration-box .modal-dialog .modal-content textarea:focus {
          outline: 0; }
      .modal .modal-dialog .modal-content select::-ms-expand,
      .product-registration-box .modal-dialog .modal-content select::-ms-expand {
        display: none; }
      .modal .modal-dialog .modal-content textarea,
      .product-registration-box .modal-dialog .modal-content textarea {
        height: 100px;
        margin-bottom: 0px; }
        .modal .modal-dialog .modal-content textarea:-webkit-resizer,
        .product-registration-box .modal-dialog .modal-content textarea:-webkit-resizer {
          display: none; }
      .modal .modal-dialog .modal-content .modal-header,
      .product-registration-box .modal-dialog .modal-content .modal-header {
        position: relative;
        padding: 10px;
        margin-top: 20px; }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content .modal-header,
          .product-registration-box .modal-dialog .modal-content .modal-header {
            padding: 15px 20px; } }
        @media (min-width: 992px) {
          .modal .modal-dialog .modal-content .modal-header,
          .product-registration-box .modal-dialog .modal-content .modal-header {
            padding: 15px 30px; } }
        @media (min-width: 1400px) {
          .modal .modal-dialog .modal-content .modal-header,
          .product-registration-box .modal-dialog .modal-content .modal-header {
            padding: 15px 50px; } }
        .modal .modal-dialog .modal-content .modal-header .modal-title,
        .product-registration-box .modal-dialog .modal-content .modal-header .modal-title {
          margin-top: 20px;
          margin: 0px;
          padding: 0px;
          border-bottom: 1px solid #043a63 !important; }
        .modal .modal-dialog .modal-content .modal-header h2,
        .product-registration-box .modal-dialog .modal-content .modal-header h2 {
          color: #043a63;
          font-weight: 300;
          display: block;
          width: 100%;
          font-size: 25px;
          font-size: 1.5625rem; }
          @media (min-width: 768px) {
            .modal .modal-dialog .modal-content .modal-header h2,
            .product-registration-box .modal-dialog .modal-content .modal-header h2 {
              font-size: 30px;
              font-size: 1.875rem; } }
          @media (min-width: 1200px) {
            .modal .modal-dialog .modal-content .modal-header h2,
            .product-registration-box .modal-dialog .modal-content .modal-header h2 {
              font-size: 35px;
              font-size: 2.1875rem; } }
          @media (min-width: 1400px) {
            .modal .modal-dialog .modal-content .modal-header h2,
            .product-registration-box .modal-dialog .modal-content .modal-header h2 {
              font-size: 41px;
              font-size: 2.5625rem; } }
      .modal .modal-dialog .modal-content .modal-body,
      .modal .modal-dialog .modal-content .modal-footer,
      .product-registration-box .modal-dialog .modal-content .modal-body,
      .product-registration-box .modal-dialog .modal-content .modal-footer {
        margin-top: 0px;
        padding: 0 10px 20px 10px; }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content .modal-body,
          .modal .modal-dialog .modal-content .modal-footer,
          .product-registration-box .modal-dialog .modal-content .modal-body,
          .product-registration-box .modal-dialog .modal-content .modal-footer {
            padding: 0 20px 30px 20px; } }
        @media (min-width: 992px) {
          .modal .modal-dialog .modal-content .modal-body,
          .modal .modal-dialog .modal-content .modal-footer,
          .product-registration-box .modal-dialog .modal-content .modal-body,
          .product-registration-box .modal-dialog .modal-content .modal-footer {
            padding: 0 30px 30px 30px; } }
        @media (min-width: 1400px) {
          .modal .modal-dialog .modal-content .modal-body,
          .modal .modal-dialog .modal-content .modal-footer,
          .product-registration-box .modal-dialog .modal-content .modal-body,
          .product-registration-box .modal-dialog .modal-content .modal-footer {
            padding: 0 50px 50px 50px; } }
      .modal .modal-dialog .modal-content .modal-body,
      .product-registration-box .modal-dialog .modal-content .modal-body {
        text-align: left; }
        .modal .modal-dialog .modal-content .modal-body .modal-perex,
        .product-registration-box .modal-dialog .modal-content .modal-body .modal-perex {
          margin: 10px 0 30px;
          text-align: left;
          font-size: 14px;
          font-size: 0.875rem;
          line-height: 18px;
          font-weight: 300; }
          @media (min-width: 545px) {
            .modal .modal-dialog .modal-content .modal-body .modal-perex,
            .product-registration-box .modal-dialog .modal-content .modal-body .modal-perex {
              margin-bottom: 30px; } }
      .modal .modal-dialog .modal-content .contact-submit-cont,
      .product-registration-box .modal-dialog .modal-content .contact-submit-cont {
        width: 100%;
        margin: 0; }
      .modal .modal-dialog .modal-content .contact-submit-text,
      .product-registration-box .modal-dialog .modal-content .contact-submit-text {
        font-size: 12px;
        font-size: 0.75rem;
        color: #043a63;
        text-align: left;
        margin-bottom: 5px;
        padding: 10px 0; }
        @media (min-width: 545px) {
          .modal .modal-dialog .modal-content .contact-submit-text,
          .product-registration-box .modal-dialog .modal-content .contact-submit-text {
            margin-bottom: 10px; } }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content .contact-submit-text,
          .product-registration-box .modal-dialog .modal-content .contact-submit-text {
            font-size: 13px;
            font-size: 0.8125rem;
            padding: 0; } }
        @media (min-width: 992px) {
          .modal .modal-dialog .modal-content .contact-submit-text,
          .product-registration-box .modal-dialog .modal-content .contact-submit-text {
            margin-bottom: 18px; } }
      .modal .modal-dialog .modal-content input[type="submit"],
      .product-registration-box .modal-dialog .modal-content input[type="submit"] {
        width: 100%;
        padding: 0 15px;
        min-height: 40px;
        background-color: #043a63;
        border: 1px solid #043a63 !important;
        color: #fff;
        -webkit-transition-property: background-color, color;
        -moz-transition-property: background-color, color;
        -o-transition-property: background-color, color;
        transition-property: background-color, color;
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
        font-size: 16px;
        font-size: 1rem;
        font-weight: 300;
        line-height: 20px;
        text-decoration: none;
        text-align: center;
        border: 0px;
        cursor: pointer;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0; }
        .modal .modal-dialog .modal-content input[type="submit"]:focus, .modal .modal-dialog .modal-content input[type="submit"]:hover,
        .product-registration-box .modal-dialog .modal-content input[type="submit"]:focus,
        .product-registration-box .modal-dialog .modal-content input[type="submit"]:hover {
          color: #043a63 !important;
          background: transparent;
          text-decoration: none !important; }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content input[type="submit"],
          .product-registration-box .modal-dialog .modal-content input[type="submit"] {
            font-size: 18px;
            font-size: 1.125rem; } }
    .modal .modal-dialog .password-strength-text,
    .product-registration-box .modal-dialog .password-strength-text {
      margin-bottom: 10px; }
    .modal .modal-dialog .passw-strength-indicator,
    .product-registration-box .modal-dialog .passw-strength-indicator {
      display: none; }
    @media (min-width: 545px) {
      .modal .modal-dialog .selectric,
      .product-registration-box .modal-dialog .selectric {
        margin-top: 30px; } }
  .modal .ErrorLabel,
  .modal .EditingFormErrorLabel,
  .product-registration-box .ErrorLabel,
  .product-registration-box .EditingFormErrorLabel {
    position: relative;
    display: block;
    width: 100%;
    margin: 20px 0px;
    padding-left: 5px;
    color: #e20b33;
    font-weight: 600; }

.fix-error-message {
  height: 120px; }

#modal-contact .modal-content {
  padding: 0; }
  #modal-contact .modal-content .modal-body {
    padding-bottom: 0px; }
    #modal-contact .modal-content .modal-body textarea {
      height: 88px;
      margin-bottom: 20px; }
      @media (min-width: 545px) {
        #modal-contact .modal-content .modal-body textarea {
          margin-bottom: 7px; } }
      @media (min-width: 768px) {
        #modal-contact .modal-content .modal-body textarea {
          height: 138px; } }
    #modal-contact .modal-content .modal-body .recaptcha-cont {
      display: block;
      width: 100%;
      position: relative;
      text-align: center; }
      @media (min-width: 768px) {
        #modal-contact .modal-content .modal-body .recaptcha-cont {
          text-align: left;
          min-height: 74px; } }
      #modal-contact .modal-content .modal-body .recaptcha-cont div {
        display: inline-block !important;
        padding: 0px !important;
        margin: 0px auto !important; }
      @media (min-width: 320px) and (max-width: 479px) {
        #modal-contact .modal-content .modal-body .recaptcha-cont > div {
          -webkit-transform-origin: "left top";
          -moz-transform-origin: "left top";
          -ms-transform-origin: "left top";
          -o-transform-origin: "left top";
          transform-origin: "left top";
          transform-origin: left top; } }
  #modal-contact .modal-content .modal-footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: column wrap;
    -ms-flex-direction: column;
    -ms-flex-wrap: wrap;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap; }
    @media (min-width: 545px) {
      #modal-contact .modal-content .modal-footer {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: flex-start;
        -moz-box-align: flex-start;
        -ms-flex-align: flex-start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        -moz-flex-flow: row nowrap;
        -ms-flex-direction: row;
        -ms-flex-wrap: nowrap;
        -webkit-flex-flow: row nowrap;
        flex-flow: row nowrap; } }
    #modal-contact .modal-content .modal-footer .contact-upload-cont {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      width: 100%;
      margin: 0px;
      margin: 20px 0; }
      @media (min-width: 545px) {
        #modal-contact .modal-content .modal-footer .contact-upload-cont {
          display: block;
          width: 35%;
          margin: 0;
          padding-right: 13px; } }
      @media (min-width: 768px) {
        #modal-contact .modal-content .modal-footer .contact-upload-cont {
          padding-right: 44px; } }
      @media (min-width: 992px) {
        #modal-contact .modal-content .modal-footer .contact-upload-cont {
          width: 25%;
          padding-right: 20px; } }
      #modal-contact .modal-content .modal-footer .contact-upload-cont input[type="file"] {
        display: none; }
      #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-text {
        font-size: 14px;
        font-size: 0.875rem;
        text-align: left;
        order: 2;
        padding-left: 5px;
        line-height: 19px; }
        @media (min-width: 545px) {
          #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-text {
            font-size: 14px;
            font-size: 0.875rem;
            margin-bottom: 10px;
            padding-left: 0; } }
        @media (min-width: 992px) {
          #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-text {
            font-size: 18px;
            font-size: 1.125rem;
            margin-bottom: 18px; } }
      #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-button {
        min-width: 125px;
        font-size: 16px;
        font-size: 1rem;
        order: 1; }
        @media (min-width: 545px) {
          #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-button {
            width: 100%; } }
    @media (min-width: 545px) {
      #modal-contact .modal-content .modal-footer .contact-submit-cont {
        width: 68%; } }
    @media (min-width: 992px) {
      #modal-contact .modal-content .modal-footer .contact-submit-cont {
        width: 75%; } }
    #modal-contact .modal-content .modal-footer .contact-submit-cont .contact-submit-text {
      line-height: 19px; }
      @media (min-width: 545px) {
        #modal-contact .modal-content .modal-footer .contact-submit-cont .contact-submit-text {
          text-align: right; } }

#modal-sign-up .modal-content {
  padding: 0; }

#modal-sign-up .EditingFormErrorLabel {
  margin-top: 0px;
  margin-top: -15px; }

@media (min-width: 545px) {
  #modal-sign-up .selectric {
    margin-top: 0px; } }

#modal-login .form-control-error {
  padding: 5px 10px;
  color: #000;
  background: #ffd100;
  font-size: 14px;
  font-size: 0.875rem;
  margin: -10px 0 15px 0; }

#modal-login .form-control-error[style*="display: inline"] {
  display: block !important; }

#modal-login .modal-content {
  padding: 0;
  max-width: 300px; }
  #modal-login .modal-content .modal-body {
    padding-bottom: 10px; }
    #modal-login .modal-content .modal-body table {
      width: 100%; }
  #modal-login .modal-content .contact-submit-text {
    margin-top: 7px;
    margin-bottom: 0px; }
    @media (min-width: 545px) {
      #modal-login .modal-content .contact-submit-text {
        margin-top: 10px;
        margin-bottom: 0px; } }
    @media (min-width: 992px) {
      #modal-login .modal-content .contact-submit-text {
        margin-bottom: 0px; } }

.field-required:after {
  content: "*";
  color: #ff5445; }

.close,
.close-yellow,
.close-black {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 0;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  opacity: 1 !important;
  border: 1px solid #043a63 !important;
  outline: 0;
  text-shadow: none !important; }
  @media (min-width: 768px) {
    .close,
    .close-yellow,
    .close-black {
      right: 20px;
      top: 15px; } }
  @media (min-width: 992px) {
    .close,
    .close-yellow,
    .close-black {
      right: 30px; } }
  @media (min-width: 1400px) {
    .close,
    .close-yellow,
    .close-black {
      right: 50px;
      top: 25px; } }
  .close:after,
  .close-yellow:after,
  .close-black:after {
    content: "\e921";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    font-size: 0.8125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #043a63;
    -moz-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    -webkit-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    cursor: pointer; }
  .close:focus, .close:hover,
  .close-yellow:focus,
  .close-yellow:hover,
  .close-black:focus,
  .close-black:hover {
    background-color: #043a63 !important;
    outline: 0; }
    .close:focus:after, .close:hover:after,
    .close-yellow:focus:after,
    .close-yellow:hover:after,
    .close-black:focus:after,
    .close-black:hover:after {
      color: #fff; }

.close-yellow {
  border: 1px solid #000 !important; }
  .close-yellow:after {
    color: #000 !important; }
  .close-yellow:focus, .close-yellow:hover {
    background-color: #000 !important; }
    .close-yellow:focus:after, .close-yellow:hover:after {
      color: #043a63 !important; }

.padding-footer {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.modal.pux-modal {
  background-color: transparent !important; }
  .modal.pux-modal .modal-dialog {
    min-height: 1px;
    margin: 10px auto;
    text-align: center;
    width: auto;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    right: 10px; }
    .modal.pux-modal .modal-dialog .close {
      -webkit-align-self: flex-end;
      -moz-align-self: flex-end;
      -ms-align-self: flex-end;
      -webkit-align-self: flex-end;
      align-self: flex-end;
      border: none !important;
      color: #000 !important;
      top: 0 !important;
      left: 0 !important;
      position: static !important; }
      .modal.pux-modal .modal-dialog .close:after {
        color: #000 !important; }
      .modal.pux-modal .modal-dialog .close:focus, .modal.pux-modal .modal-dialog .close:hover {
        background-color: transparent !important; }
    .modal.pux-modal .modal-dialog .modal-content {
      background-color: #dadada;
      width: auto;
      max-width: 100%; }

.modal-backdrop {
  background-color: transparent !important; }

.modal-open.pux-modal-open {
  overflow: auto; }
  .modal-open.pux-modal-open header {
    margin-left: -11px; }

.search-form {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .search-form {
      margin-top: 0; } }
  .search-form-input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    padding-right: 45px;
    outline: 0;
    background: #e8edf1;
    border: 0px;
    -webkit-transition-property: background-color, color;
    -moz-transition-property: background-color, color;
    -o-transition-property: background-color, color;
    transition-property: background-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    font-size: 18px;
    font-size: 1.125rem;
    color: #043a63; }
    @media (min-width: 992px) {
      .search-form-input {
        height: 50px;
        padding-right: 75px;
        font-size: 22px;
        font-size: 1.375rem; } }
    .search-form-input:focus {
      outline: 0;
      background: #a9bbca; }
  .search-form-button {
    position: absolute;
    top: 50%;
    right: 10px;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    display: inline-block;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #043a63;
    cursor: pointer;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 992px) {
      .search-form-button {
        right: 20px;
        width: 36px;
        height: 36px; } }
    .search-form-button:focus, .search-form-button:hover {
      background-color: #043a63; }
      .search-form-button:focus:after, .search-form-button:hover:after {
        color: #fff; }
    .search-form-button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e909";
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-size: 1.125rem;
      color: #043a63; }
      @media (min-width: 992px) {
        .search-form-button:after {
          font-size: 22px;
          font-size: 1.375rem; } }
    .search-form-button.cancel:after {
      content: "\e921";
      font-size: 15px;
      font-size: 0.9375rem; }
      @media (min-width: 992px) {
        .search-form-button.cancel:after {
          font-size: 18px;
          font-size: 1.125rem; } }

.search-list {
  border-top: 1px solid #043a63; }
  .search-list .big-button-bw-small {
    color: #043a63; }

.search-list .full-width-item {
  text-decoration: none !important;
  color: #000 !important; }

.hp-category .EditorWidgets {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .hp-category .EditorWidgets .WebPart {
    display: block; }

@media print {
  header,
  footer,
  .box-header-content,
  .content-mobile-menu,
  .products-menu,
  .products-menu-generated,
  .newsletter-box,
  .square-button,
  .square-button-bw,
  .big-button-bw,
  .social-list,
  .big-button-bw-small,
  .content-button-level-up,
  .slick-arrow {
    display: none !important; }
  .main-content {
    width: 100%; }
  .slick-list {
    overflow: visible !important; }
  .slick-track {
    width: 100% !important;
    transform: none !important; }
  .slick-slide {
    display: inline-block !important;
    float: none !important; }
  .content-gallery-list {
    width: 100% !important;
    max-height: none !important; }
  .content-tabs-list,
  .contact-card-content {
    display: block !important; }
  body {
    margin: 0;
    padding: 0;
    font-family: "Times New Roman", Times, serif;
    color: #423f3f !important; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Arial, Helvetica, sans-serif; }
  h1 {
    font-size: 16pt; }
  h2 {
    font-size: 14pt; }
  h3,
  h4,
  h5,
  h6 {
    font-size: 12pt; }
  p {
    line-height: 1.4;
    font-size: 12pt; }
  a:link,
  a:visited {
    text-decoration: underline;
    font-weight: bold;
    color: #000; }
  img {
    border: 1px solid #000; }
  ul * {
    display: block; } }
