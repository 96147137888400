@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.search-form  {
  position: relative;
	margin-top: 30px;
  margin-bottom: 30px;
	@include media(md) {
			margin-top: 0;
	}
  &-input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    padding-right: 45px;
    outline: 0;
    background: $color-blue-light-bg;
    border: 0px;
    @include animate("background-color, color");
    @include font-size(18px);
		color: $color-primary;

    @include media(lg) {
      height: 50px;
      padding-right: 75px;
      @include font-size(22px);
    }

    &:focus {
      outline: 0;
      background: darken($color-blue-light-bg, 20%);
    }
  }

  &-button {
    position: absolute;
    top: 50%;
    right: 10px;
    @include translate(0, -50%);
    display: inline-block;
    width: 30px;
    height: 30px;
    @include border-radius(50%);
    border: 1px solid $color-primary;
    cursor: pointer;
    @include transition();

    @include media(lg) {
      right: 20px;
      width: 36px;
      height: 36px;
    }

    &:focus,
    &:hover {
      background-color: $color-primary;

      &:after {
        color: $color-white;
      }
    }

    &:after {
      @include icomoon();
      content: "\e909";
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, -50%);
      @include font-size(18px);
      color: $color-primary;

      @include media(lg) {
        @include font-size(22px);
      }
    }

    &.cancel {
      &:after {
        content: "\e921";
        @include font-size(15px);

        @include media(lg) {
          @include font-size(18px);
        }
      }
    }
  }
}


.search-list {
	  border-top: 1px solid $color-primary;
		.big-button-bw-small {
				color: $color-primary;
		}
}

.search-list{
    .full-width-item{
        text-decoration:none !important;
        color:#000 !important;
    }
}
