$padding-values: ("10", "20", "30", "40", "50", "60", "70", "80", "90", "100");

@each $value in $padding-values {
  .pt-#{$value} {
    padding-top: #{$value}px;
  }
}

@each $value in $padding-values {
  .pb-#{$value} {
    padding-bottom: #{$value}px;
  }
}

@each $value in $padding-values {
  .mt-#{$value} {
    margin-top: #{$value}px;
  }
}

@each $value in $padding-values {
  .mb-#{$value} {
    margin-bottom: #{$value}px;
  }
}

.line{
    width:100%;
    margin:20px 0;
    height:2px;
    background:#000;
}
