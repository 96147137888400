
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@include font-face("Urban-Grotesk", "Urban-Grotesk-Light", 300);


body,
input,
select,
textarea {
    font-family: 'Urban-Grotesk', $font-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include font-size(14px);
}

.text-right{
    text-align:right;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight:300;
}

.h1, .h2, .h3, .h4, .h5, .h6{
    display: block;
}

h1,.h1 {
    @include font-size-with-line(25px);
		font-weight: 300;
		display: inline-block;
		padding-top: 20px 0 2px;
		margin-bottom: 20px;

    @include media(sm){
        @include font-size-with-line(30px);
    }
    @include media(md){
        @include font-size-with-line(35px);
				margin-bottom: 50px;
    }
    @include media(lg){
        @include font-size-with-line(40px);
    }
    @include media(xl){
        @include font-size-with-line(46px);
    }
}

h2,.h2 {
    @include font-size-with-line(18px);
}

h3,.h3 {
    @include font-size-with-line(16px);
    margin:15px 0 10px;

    @include media(xl){
        @include font-size-with-line(18px);
        margin:20px 0 10px;
    }
}



strong,
.bold,
b{
    font-weight:600;
}

.semibold{
    font-weight:600;
}

.no-href,
a {
    text-decoration: underline;
    color: $color-primary;
    cursor: pointer;
    outline: none;

    //@include animate(color);

    &:hover {
        text-decoration: none;
        color: $color-primary;
    }
}

.logon-password-retrieval-button,
.btn{
    @include border-radius(0);
    cursor:pointer;
    color: $color-white;
		background-color: $color-primary;
    border:1px solid $color-primary;
    height:30px;
    @include box-shadow(none);
    padding:0 15px;
    outline:0 !important;
}

.logon-password-retrieval-button,
.btn-primary{
    &:focus,
    &:hover{
        background-color: $color-white;
        color: $color-primary;
        border:1px solid #000;
    }
}

.title-line {
  padding-bottom: 5px;
	width: 100%;
}

.title-line-slim {
  font-weight: 300;
  padding-bottom: 3px;
  border-bottom: 1px solid $color-primary;
  width: 100%;
}

.square-button,
.square-button-bw {
  display: inline-block;
  padding: 5px;
  margin: 0 2px;
  margin-top: 15px;
  position: relative;
  border: 1px solid $color-primary;
  @include animate("background-color, color");
  @include font-size(15px);
  font-weight: 300;
  line-height: 20px;
  text-decoration: none;
  color: $color-primary;
  text-align: center;


  &:focus,
  &:hover {
    color: $color-white!important;
    background: $color-primary !important;
    text-decoration: none !important;
  }
}

.square-button-bw {
  &:focus,
  &:hover {
    color: $color-white !important;
    background: $color-primary;
  }
}

.big-button-bw,
.big-button-bw-small,
.arrow-down-link {
	width: auto;
	padding: 0 15px;
	min-height: $btn-height;
	line-height: $btn-height - 2;
	vertical-align: middle;
	display: inline-block;

	font-size: 18px;
	border: 1px solid $color-primary;
	color: $color-white;
	background: transparent;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	text-align: center;
	@include animate("background, color");
	font-weight: 300;

	&:hover,
  &:focus {
		color: $color-white !important;
		background: $color-primary;
    text-decoration: none;
	}
}

.big-button-bw-small,
.arrow-down-link {
	font-size: 15px;
  padding: 0 10px;

  @include media (md) {
    @include font-size(18px);
    padding: 0 20px;
  }
}

.btn-close {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid $color-white !important;
  color: $color-white !important;
  text-shadow: 0;
  opacity: 1;
  @include font-size(15px);
  @include transition(100ms);

  &:hover,
  &:focus {
    color: $color-primary !important;
    background-color: $color-white;
  }
}

.show-more-wraper {
		width: 100%;
		margin: 40px 0 20px 0;
		text-align: center;
		@include font-size(14px);
		@include media(md) {
				margin: 75px 0 20px 0;
				@include font-size(18px);
		}
}

.vertical-line {
		background-color: $color-primary;
		height: 1px;
		width: 100%;
}

.FormButton,
.btn-show-more {
	display: inline-block;
	border: 1px solid $color-primary;
	color: $color-primary;
	text-decoration: none;
	background-color: $color-white;
	@include animate("background, color");
	padding: 8px 13px;
	height: auto !important;
	&:focus,
	&:hover {
			color: $color-white !important;
			background-color: $color-primary !important;
			text-decoration: none !important;
	}
	@include media(md) {
			padding: 10px 20px;
	}
}

@each $name, $color in $password-strength{
		.password-strength-#{$name} {
				color: $color;
		}
}

table {
		max-width: 100%;
		width: 100%;
		overflow-x: auto;
		td {
				padding: 5px 10px 5px 0px;
				@include media(md) {
					  padding: 2px 15px 2px 0px;
				}
		}
		thead {
				background-color: $color-pink;
				color: #fff;
				font-weight: bolder;
				td {
						background-color: $color-pink;
						color: #fff;
						font-weight: bolder;
				}
		}
		img {
				min-width: 21px;
		}
}
